/**
 * ApparitionHistory 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, { Component } from 'react';
import Header from './header';
import ApparitionHistorySlider from './apparitionHistorySliders';
import Footer from './footer';
import firstApparition1 from '../img/DSCF6373_PRIMERA_APARICION_319px.jpg';
import firstApparition2 from '../img/DSCF6373_PRIMERA_APARICION.jpg';
import firstApparition3 from '../img/DSCF6374_JUANS.jpg';
import secondAppartion1 from '../img/DSCF6380_segundaaparicion_319.jpg';
import secondAppartion2 from '../img/DSCF6380_segundaaparicion.jpg';
import juanSanchez1 from '../img/DSCF6381_ENTREGAJUANSANCHEZ_290.jpg';
import juanSanchez2 from '../img/DSCF6381_ENTREGAJUANSANCHEZ.jpg';
import huidaCacique1 from '../img/HUIDA_MUERTE_CACIQUE_290.jpg';
import huidaCacique2 from '../img/HUIDA_MUERTE_CACIQUE.jpg';
import ubicacionCruz1 from '../img/ubicacion-cruz-290.jpg';
import ubicacionCruz2 from '../img/encuentro-sitio-aparicion-500.jpg';
import ubicacionCruz3 from '../img/encuentro-sitio-aparicion-700.jpg';
import virgenCoromoto1 from '../img/virgen-coromoto-300.jpg';
import virgenCoromoto2 from '../img/VirgenCoromotoAltar400.jpg';
import virgenCoromoto3 from '../img/VirgenCoromotoAltar768.jpg';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';

export default class ApparitionHistory extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("history-our-lady-of-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }


    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Historia de la Aparici&oacute;n Virgen de Coromoto</title>
                <meta name="description" content="Historia Virgen de Coromoto" />
                <meta name="keywords" content="Coromoto, Virgen, María, Historia" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>History of the Apparition of Our Lady of Coromoto</title>
                <meta name="description" content="History of the Apparition of Our Lady of Coromoto" />
                <meta name="keywords" content="Coromoto, History, Mary" />
            </Helmet>;
        }

        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <ApparitionHistorySlider />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>FirstApparitionLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 d-md-none d-xl-none d-lg-none d-sm-none d-block" align="center">
                                        <img alt="" src={firstApparition1} />
                                    </div>
                                    <div className="col-sm-12 d-md-none d-xl-none d-lg-none d-none d-sm-block d-md-block" align="center">
                                        <img alt="" src={firstApparition2} />
                                    </div>
                                    <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                        <img alt="" src={firstApparition2} />
                                    </div>
                                    <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                        <img alt="" src={firstApparition3} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <Trans>FirstApparitionBodyP1</Trans> <br/><br/>
                                            <Trans>FirstApparitionBodyP2</Trans> <br/><br/>
                                            <Trans>FirstApparitionBodyP3</Trans> <br/><br/>
                                            <Trans>FirstApparitionBodyP4</Trans> <br/><br/>
                                            <Trans>FirstApparitionBodyP5</Trans> <br/><br/>
                                            <Trans>FirstApparitionBodyP6</Trans> 
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>SecondApparitionLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 d-md-none d-xl-none d-lg-none d-sm-none d-block" align="center">
                                        <img alt="" src={secondAppartion1} />
                                    </div>                                    
                                    <div className="col-md-12 d-none d-sm-block d-md-block d-xl-block d-lg-block" align="center">
                                        <img alt="" src={secondAppartion2} />
                                    </div>                                    
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <Trans>SecondApparitionBodyP1</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP2</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP3</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP4</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP5</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP6</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP7</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP8</Trans> <br/><br/>
                                            <Trans>SecondApparitionBodyP9</Trans> 
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>ThirdApparitionLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 d-md-none d-xl-none d-lg-none d-sm-none d-block" align="center">
                                        <img alt="" src={juanSanchez1} />
                                    </div>
                                    <div className="col-md-12 d-none d-sm-block d-md-block d-xl-block d-lg-block" align="center">
                                        <img alt="" src={juanSanchez2} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <Trans>ThridApparitionBodyP1</Trans> <br/><br/>
                                            <Trans>ThridApparitionBodyP2</Trans> <br/><br/>
                                            <Trans>ThridApparitionBodyP3</Trans> <br/><br/>
                                            <Trans>ThridApparitionBodyP4</Trans> <br/><br/>
                                            <Trans>ThridApparitionBodyP5</Trans>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>FourthApparitionLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 d-md-none d-xl-none d-lg-none d-sm-none d-block" align="center">
                                        <img alt="" src={huidaCacique1} />
                                    </div>
                                    <div className="col-md-12 d-none d-sm-block d-md-block d-xl-block d-lg-block" align="center">
                                        <img alt="" src={huidaCacique2} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <Trans>FourthApparitionBodyP1</Trans> <br/><br/>
                                            <Trans>FourthApparitionBodyP2</Trans> <br/><br/>
                                            <Trans>FourthApparitionBodyP3</Trans> 
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>FifthApparitionLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 d-md-none d-xl-none d-lg-none d-sm-none d-block" align="center">
                                        <img alt="" src={ubicacionCruz1} />
                                    </div>
                                    <div className="col-sm-12 d-md-none d-xl-none d-lg-none d-none d-sm-block d-md-block" align="center">
                                        <img alt="" src={ubicacionCruz2} />
                                    </div>
                                    <div className="col-md-12 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                        <img alt="" src={ubicacionCruz3} />
                                    </div>                                   
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <Trans>FifththApparitionBodyP1</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP2</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP3</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP4</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP5</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP6</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP7</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP8</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP9</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP10</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP11</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP12</Trans> <br/><br/>
                                            <Trans>FifththApparitionBodyP13</Trans> 
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>SixthApparitionLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 d-md-none d-xl-none d-lg-none d-sm-none d-block" align="center">
                                        <img alt="" src={virgenCoromoto1} />
                                    </div>
                                    <div className="col-sm-12 d-md-none d-xl-none d-lg-none d-none d-sm-block d-md-block" align="center">
                                        <img alt="" src={virgenCoromoto2} />
                                    </div>
                                    <div className="col-md-12 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                        <img alt="" src={virgenCoromoto3} />
                                    </div>                                   
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <Trans>SixthApparitionBodyP1</Trans> <br/><br/>
                                            <Trans>SixthApparitionBodyP2</Trans> <br/><br/>
                                            <Trans>SixthApparitionBodyP3</Trans> <br/><br/>
                                            <Trans>SixthApparitionBodyP4</Trans> <br/><br/>
                                            <Trans>SixthApparitionBodyP5</Trans> <br/><br/>
                                            <Trans>SixthApparitionBodyP6</Trans> <br/><br/>
                                            <Trans>SixthApparitionBodyP7</Trans> <br/><br/>
                                            <Trans>SixthApparitionBodyP8</Trans> 
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <article>
                            <p><b>BIBLIOGRAF&Iacute;A:</b></p>
                            <p>_TEXTOS BASADOS EN LAS INVESTIGACIONES Y ESCRITOS DEL HERMANO NECTARIO MAR&Iacute;A FSC. </p>
                            PUBLICADOS EN SU LIBRO "LA MARAVILLOSA HISTORIA DE NUESTRA SE&Ntilde;ORA DE COROMOTO"
                            <p>_"VIRGEN DE COROMOTO HISTORIA DE SU APARICI&Oacute;N" PUBLICACI&Oacute;N ELABORADA POR LAS HERMANAS DEL
                                    MONASTERIO TRAPENSE NUESTRA SE&Ntilde;ORA DE COROMOTO.</p>
                        </article>
                    </div>
                </div>


            </div>
            <Footer />
        </div>);
    }

}