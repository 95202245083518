/**
 * TimtableSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 21/04/2024
 */

import React, { Component } from 'react';
import Header from './header';
import TimetableSanctuarySliders from './timestableSanctuarySliders';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import holyMass from '../img/misaSantuario1.jpg';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';

export default class TimtableSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("bishop-diocese-of-guanare.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Horarios Santa Misa Basílica Menor Virgen de Coromoto</title>
                <meta name="description" content="Horarios Santa Misa Basílica Menor Virgen de Coromoto" />
                <meta name="keywords" content="horarios, santa misa, santuario nacional, virgen de coromoto" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Holy Mass Schedule Minor Basilica of the Virgin of Coromoto</title>
                <meta name="description" content="Holy Mass Schedule Minor Basilica of the Virgin of Coromoto" />
                <meta name="keywords" content="Schedule, Holy, Mass, Virgin, Coromoto" />
            </Helmet>;
        }
        return (<div>
             {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <TimetableSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>MassSchedulesLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-none d-xl-block d-lg-block"  align="center">
                                        <img src={holyMass} className="img-thumbnail"/>
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-block d-xl-none d-lg-none"  align="center">
                                        <img src={logo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>                                            
                                            <p><span style={{"font-size":"18px"}}><Trans>MassSchedulesP1</Trans></span></p>                                            
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}