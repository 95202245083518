/**
 * Celebration369ApparitionComponent 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 06/10/2021
 */

 import React, { Component } from 'react';
 import Header from './header';
 import NewsSliderComponent from './NewsSliderComponent';
 import Footer from './footer';
 import {Helmet} from "react-helmet";
 import { Trans } from '@lingui/macro';
 import homily from '../img/news/santaMisa.jpg';


 export default class Celebration369ApparitionComponent extends Component {
 
     constructor(props) {
         super(props);
         let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("celebration-369-apparition-coromoto.html") ? "en" : "es");
         let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
         if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
             this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
         }
         else {
             this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
         }
         this.refreshLanguage = this.refreshLanguage.bind(this);    
         this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
     }
 
     componentDidMount() {
         if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
             if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
             }
         }        
     }
 
     invokeChangeLanguage(language) {
         if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
             this.setState({ lang: language });         
             sessionStorage.setItem('language', language);        
             this.state.eventChangeLanguage(language);            
         }
     }
 
     refreshLanguage(value) {            
         if (value !== null && value !== '') {
             this.setState({ lang: value });                    
         }
     }
 
     render() {
 
         let helmetComp;
         if (this.state.lang === 'es') {
             //seo 
             helmetComp = <Helmet>
                 <title>Celebraci&oacute;n 369 a&ntilde;os de la Aparici&oacute;n de Nuestra Se&ntilde;ora de Coromoto</title>
                 <meta name="description" content="Celebraci&oacute;n de los 369 a&ntilde;os de la Aparici&oacute;n de Nuestra Se&ntilde;ora de Coromoto Patrona de Venezuela y 69 a&ntilde;os de la Coronaci&oacute;n Can&oacute;nica" />
                 <meta name="keywords" content="celebracion, 369, coromoto, virgen, venezuela" />
             </Helmet>;
         }
         else {
             //seo 
             helmetComp = <Helmet>
                 <title>Celebration 369 years of the apparition of Our Lady of Coromoto</title>
                 <meta name="description" content="Celebration of the 369 years of the apparition of Our Lady of Coromoto Patroness of Venezuela and 69 years of the Canonical Coronation" />
                 <meta name="keywords" content="celebration, 369, coromoto, virgen, venezuela" />
             </Helmet>;
         }
 
 
         return (<div>
             {helmetComp}
             <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
             <NewsSliderComponent />
             <div className="container">
                 <div className="row">
                     <div className="col-12">&nbsp;</div>
                 </div>
 
                 <div className="row">
                     <div className="col-12">
                         <div className="card">
                             <div className="card-header"><strong><Trans>Celebration369Sep2021Lb</Trans></strong></div>
                             <div className="card-body">
                                 <div class="row">
                                     <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                         <img src={homily} className="img-thumbnail" />
                                     </div>
                                     <div className="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                         <img src={homily} className="img-thumbnail" />
                                     </div>
                                 </div>
                                 <div className="row">
                                     <div class="col-12">&nbsp;</div>
                                 </div>
                                 <div className="row">
                                     <div className="col-12">
                                         <article>
                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P1</Trans></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P2</Trans></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P3</Trans></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P4</Trans></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P5</Trans></p>

                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P6</Trans></p>

                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P7</Trans></p>

                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P8</Trans></p>

                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P9</Trans></p>

                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P10</Trans></p>

                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P11</Trans></p>

                                             <p style={{ "text-align": "justify" }}><Trans>Celebration369Sep2021P12</Trans></p>
                                          
                                         </article>
                                     </div>
                                 </div>
                                
 
                                 <div className="row">
                                     <div class="col-12">&nbsp;</div>
                                 </div>
 
                               
                             </div>
                         </div>                        
                     </div>
                 </div>
 
                 <div className="row">
                     <div className="col-12">&nbsp;</div>
                 </div>
 
                 
 
 
             </div>
             <Footer />
         </div>);
     }
 
 }