/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, { Component } from 'react';
import logoBasilica from '../img/logo_basilica3.png';
import { Trans } from '@lingui/macro';


export default class MainTitle extends Component {


    render() {
        return (
        <div className="row" >
            <div className="col-md-2 d-lg-none d-md-none d-sm-none d-none d-xl-block"><img src={logoBasilica} className="img-rounded" /></div>
            <div className="col-md-1 d-lg-none d-md-none d-sm-none d-none d-xl-block">&nbsp;</div>
            <div className="col-md-9 d-lg-none d-md-none d-sm-none d-none d-xl-block">
                <div style={{ "fontSize": "22px", "color": "#593D00" }}><Trans>MainTitleLb</Trans></div>
            </div>

            <div className="col-md-2 d-xl-none d-none d-lg-block d-md-block"><img src={logoBasilica} className="img-rounded" /></div>
            <div className="col-md-1 d-xl-none d-none d-lg-block d-md-block">&nbsp;</div>
            <div className="col-md-9 d-xl-none d-none d-lg-block d-md-block">
                <div style={{ "fontSize": "16px", "color": "#593D00" }}><Trans>MainTitleLb</Trans></div>
            </div>

            <div className="col-12 d-md-none d-sm-none d-lg-none d-block" >
                <div style={{ "fontSize": "24px", "color": "#927f26", "fontWeight" : "bold" }} align="center"><Trans>MainTitleExtraSmall</Trans></div>
            </div>

            <div className="col-12 d-md-none d-sm-none d-lg-none d-block" >
                &nbsp;
            </div>
        </div>
        );
    }

}