/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';

import Footer from './footer';
import logo from '../img/beata-maria-san-jose.jpg';
import logoSmall from '../img/beata-maria-san-jose-small.jpg';
import { Helmet } from "react-helmet";


export default class BlessedMariaSanJose extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Beata Mar&iacute;a de San Jos&eacute;</title>
                <meta name="description" content="Beata Mar&iacute;a de San Jos&eacute;" />
                <meta name="keywords" content="beata, maria, jose, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
           
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Beata Mar&iacute;a de San Jos&eacute;</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center">
                                        <img src={logoSmall} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>                                          

                                            <p><span style={{"font-size":"18px"}}><strong>La Santidad</strong></span></p>

                                            <p>Ordinariamente manejamos el t&eacute;rmino &quot;santidad&quot; sin poseer de &eacute;l una noci&oacute;n clara; elementalmente sabemos que &quot;santo&quot; es todo aquello que de alguna forma tiene relaci&oacute;n directa con Dios, pureza y bondad infinitas; el &quot;solo Santo&quot;.<br />
                                                La Biblia nos transmite aquella visi&oacute;n en la que esp&iacute;ritus celestes adoran a Dios cant&aacute;ndole: &quot;Santo, Santo, Santo es el Se&ntilde;or Dios del universo...&quot;, alabanza que la liturgia cat&oacute;lica ha trasladado a la &quot;Plegaria Eucar&iacute;stica&quot; de la misa. Y la misma liturgia contin&uacute;a: &quot;Santo eres en verdad, Se&ntilde;or, FUENTE de toda santidad&quot;. Si; Dios es el &uacute;nico Santo; pero como &Eacute;l es AMOR y el amor es donaci&oacute;n, apertura, comunicaci&oacute;n, Dios nos hace hijos en su Hijo Jesucristo, &quot;de cuya plenitud todos recibimos&quot; (Is 1,16). En su Hijo, Dios nos hace part&iacute;cipes de su santidad mediante la gracia sobrenatural. Nos transmite su propia vida. Por eso somos hijos.<br />
                                                Como hijos de Dios en Cristo, todos estamos llamados a ser santos. En esta vocaci&oacute;n concurren dos factores: la iniciativa operante de Dios y la respuesta humana en pleno ejercicio de su libertad.<br />
                                                El grado de santidad corresponde al grado de crecimiento en la comuni&oacute;n con Dios.<br />
                                                La madre Iglesia, pastoralmente sol&iacute;cita del bien de sus hijos, acogiendo sus aspiraciones y con la autoridad otorgada por Cristo, declara oficialmente &quot;santos&quot; a aquellos candidatos que habiendo fallecido en concepto de santidad, y mediante exhaustivo estudio de su vida y virtudes, alcanzan por parte de Dios la prueba de un milagro, es decir, de un hecho sobrenatural s&oacute;lo factible a la omnipotencia divina. Todo queda instrumentalizado en la &quot;Causa de canonizaci&oacute;n&quot;.<br />
                                                Para ser m&aacute;s precisos, la causa de canonizaci&oacute;n, es el asunto en cuesti&oacute;n (la santidad de X persona) y se introduce o se inicia: se requieren pruebas testificales (de testigos) y documentales (escritas).<br />
                                                El origen de las causas de canonizaci&oacute;n se remonta a los primeros siglos del cristianismo en relaci&oacute;n al culto de los m&aacute;rtires, y posteriormente a los confesores (eximios defensores de la fe) y, finalmente, a aquellos candidatos cuyas virtudes cristianas en grado heroico, sean comprobadas.<br />
                                                El objetivo de estas causas es proponer nuevos modelos de fe e intercesores ante Dios, a la vez que son una se&ntilde;al de la vitalidad de la Iglesia.<br />
                                                Antiguamente, las beatificaciones se efectuaban a nivel de di&oacute;cesis y no se exig&iacute;a el l&iacute;mite de tiempo: pod&iacute;a iniciarse inmediatamente despu&eacute;s de la muerte. Hoy se exige un l&iacute;mite de 5 a&ntilde;os a partir del deceso.<br />
                                                En cuanto a las personas, se llama &quot;actor&quot; a quien solicita la causa ante la autoridad competente. &quot;Postulador&quot; es la persona nombrada por el &quot;actor&quot; como representante legal: &Eacute;l defiende los intereses de la causa y, en la pr&aacute;ctica, es &quot;el alma del proceso&quot;.<br />
                                                Lo que en lenguaje vulgar se conoce como el &quot;abogado del diablo&quot; es el &quot;promotor de justicia&quot;, a quien corresponde velar para que se observe fielmente la ley.<br />
                                                A trav&eacute;s de las sucesivas fases de la causa se denomina al candidato:<br />
                                                1. Siervo de Dios: cuando la Santa Sede mediante el &quot;nihil obstat&quot; comunica que &quot;no hay obst&aacute;culo&quot; alguno para introducir la causa.<br />
                                                2. Venerable: Sigue a la declaraci&oacute;n de las virtudes heroicas del candidato.<br />
                                                3. Beato: Significa bienaventurado y se declara tal, despu&eacute;s de la comprobaci&oacute;n de un milagro sometido a proceso.<br />
                                                4. Santo: En la actual legislaci&oacute;n eclesi&aacute;stica se exige un nuevo milagro antes de esta declaraci&oacute;n final.<br />
                                                El nuevo santo pasa a formar parte del &quot;santoral&quot; de la Iglesia, es decir, la Iglesia autoriza oficialmente el culto p&uacute;blico a estos h&eacute;roes de la vida cristiana, part&iacute;cipe, de la santidad divina en grado eminente.<br />
                                                En cuanto a la exhumaci&oacute;n, basta decir que, pr&oacute;xima la beatificaci&oacute;n del Siervo de Dios, se procede al reconocimiento del cad&aacute;ver, se extraen algunas reliquias y, si se estima oportuno, se trasladan los restos mortales a un lugar de f&aacute;cil acceso para los fieles.<br />
                                                No es necesario trasladar los restos al sitio de beatificaci&oacute;n, ya sea en Roma o en cualquier otro lugar donde la ceremonia se realice.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>1. Laura Evangelista</strong></span></p>

                                            <p>A aquel 25 de abril de 1875, fecha del nacimiento de la Madre Mar&iacute;a de San Jos&eacute;, han precedido vicisitudes y acontecimientos que con diversos caracteres, marcan la historia y la fisonom&iacute;a de Venezuela: A varias d&eacute;cadas de la independencia nacional, se da la abolici&oacute;n de la esclavitud en 1845, a la que seguir&aacute; la Revoluci&oacute;n Federal durante cinco a&ntilde;os, de 1859 a 1863. El pa&iacute;s se enfrenta con una econom&iacute;a destruida por la guerra y la anarqu&iacute;a. Durante el gobierno del civilizador y aut&oacute;crata Guzm&aacute;n Blanco, entre otras obras de progreso se realiza en 1873 un censo de la poblaci&oacute;n venezolana, la cual alcanz&oacute; a 1.784.194 habitantes.&nbsp;<br />
                                                El 13 de Octubre de 1875, a s&oacute;lo 6 meses de su nacimiento, una ni&ntilde;a es bautizada en la Iglesia Parrroquial de Choron&iacute; con el nombre de LAURA EVANGELISTA, hija primog&eacute;nita de Clemente Alvarado y Margarita Cardozo, modestos habitantes de aquel pueblo. La Bautiza el p&aacute;rroco Jos&eacute; Mar&iacute;a Y&eacute;pez y son sus padrinos Manuel Gonz&aacute;lez y Dolores Sof&iacute;a Bravo Cardozo, prima de la ni&ntilde;a.&nbsp;<br />
                                                Como obsequio de Ana F&eacute;lix, la abuela paterna, recibe un par de zarcillos de oro macizo con tres esmeraldas cada uno. Acto seguido, aquellos pendientes fueron incrustados en las tiernas orejitas de la reci&eacute;n bautizada.&nbsp;<br />
                                                Laura es una hija esperada con amor y rodeada del cari&ntilde;o familiar, factor decisivo en el desarrollo arm&oacute;nico de la personalidad. Le seguir&aacute;n otros hermanos: Octaviano, Panchita y Clemencia.&nbsp;<br />
                                                En 1877, Laura recibe la confirmaci&oacute;n administrada por el Arzobispo de Caracas, Mons. Jos&eacute; Antonio Ponte, de visita pastoral en Choron&iacute;. Es su madrina Mercedes M&aacute;rquez de Padr&oacute;n.&nbsp;<br />
                                                Al referirnos a Choron&iacute;, estamos hablando del pueblo natal de nuestra Beata Mar&iacute;a de San Jos&eacute;. De estilo colonial, pintoresco y acogedor, es patrimonio hist&oacute;rico de la naci&oacute;n, centro tur&iacute;stico del Municipio Girardot en el Estado Aragua, de hermosas playas y habitantes. Guarda en su seno hist&oacute;ricas memorias entre ellas la r&uacute;stica pila bautismal de 1757.&nbsp;<br />
                                                Choron&iacute; es valle rodeado de altas monta&ntilde;as que, junto al rio que lo atraviesa, le prodigan un aire de belleza y frescura. A pocos kil&oacute;metros, el Puerto de Colombia, con su paisaje marino, su folklore y su actividad bulliciosa y alegre.&nbsp;<br />
                                                La profusa y rica virtualidad de los accidentes geogr&aacute;ficos, ejercer&aacute;n especial influencia en la personalidad de aquella ni&ntilde;a, excepcional fruto de su tierra.</p>

                                            <p><span style={{"font-size":"18px"}}><strong>2. Infancia</strong></span></p>

                                            <p>A medida que Laura crece, va revel&aacute;ndose en ella una gama de cualidades: clara inteligencia, fina sensibilidad, firmeza y tan extraordinaria memoria, que jam&aacute;s olvidar&aacute; una funci&oacute;n de t&iacute;teres que presenci&oacute; a los seis meses de edad. En &eacute;pocas posteriores afirmar&aacute; que desde los dos a&ntilde;os recuerda toda su vida.&nbsp;<br />
                                                Cuando Laura cumple los tres a&ntilde;os, don Clemente decide trasladarse con su familia a Turmero, poblaci&oacute;n vecina domicilio de sus padres, Dolores Alvarado y Ana F&eacute;lix Salas, quienes colman de cari&ntilde;o a su nieta. Una de sus t&iacute;as le ense&ntilde;&oacute; las primeras letras y a los 4 a&ntilde;os de edad, ya sabe leer.&nbsp;<br />
                                                La estancia en Turmero es breve. Al poco tiempo se establecen en Maracay donde cursa todos sus estudios, desde los 5 a&ntilde;os hasta los 17. Era una de las preocupaciones de sus padre: su educaci&oacute;n, quer&iacute;an para ella lo mejor. En el ambiente social de la &eacute;poca, cuando imperaba el analfabetismo, su educaci&oacute;n resultaba &oacute;ptima.&nbsp;<br />
                                                Seg&uacute;n su propia afirmaci&oacute;n, en la escuela la llamaban &quot;la palomita&quot;, lo que ella atribu&iacute;a a su natural seriedad. Declara igualmente que durante toda su etapa escolar guardar&aacute; su alma de pecado; inocencia que conservar&aacute; hasta el final de sus d&iacute;as.&nbsp;<br />
                                                Re&ntilde;ida con todo lo que es mentira o enga&ntilde;o, est&aacute; siempre dispuesta a decir la verdad. Muestra especial inclinaci&oacute;n a la piedad religiosa y al socorro de las personas necesitadas, actitudes que ha aprendido junto a su madre, a quien el pueblo mucho aprecia por su servicialidad y caridad, sin distinci&oacute;n de ninguna clase. Nueve a&ntilde;os cuenta cuando, acompa&ntilde;a a su madre a visitar un enfermo renuente a recibir los sacramentos aduciendo ante la ni&ntilde;a su larga barba. Laura sol&iacute;cita, se aprest&oacute; a rasurarlo, como en efecto lo hizo, logrando que aquel hombre se reconciliara con Dios.&nbsp;<br />
                                                Ya era una estampa familiar ver a la ni&ntilde;a Laurita al lado de su madre en las visitas de caridad, en la pr&aacute;ctica de las obras de misericordia. Anhela el d&iacute;a de su primera comuni&oacute;n, para la que ha sido preparada desde los 7 a&ntilde;os por &quot;una santa viejecita&quot;; sin embargo deber&aacute; esperar hasta los 13 a&ntilde;os, seg&uacute;n las normas can&oacute;nicas de entonces.</p>

                                            <p><span style={{"font-size":"18px"}}><strong>3. Algunas An&eacute;cdotas</strong></span></p>

                                            <p>Andar&iacute;a Laura en los 5 a&ntilde;os de edad cuando en Maracay una curiosa dama ventanera le pregunta:&nbsp;<br />
                                                _Muchachita, &iquest;de qui&eacute;n eres t&uacute;?&nbsp;<br />
                                                _De mi pap&aacute; y mi mam&aacute;, le respondi&oacute; vivazmente la ni&ntilde;a.&nbsp;<br />
                                                Cuando me peinaban de crespos -relata la Madre Mar&iacute;a- &iexcl;ay, ay, ay! iba al espejo a mirarme de un lado y de otro. &iexcl;Se sent&iacute;a tan linda! En una ocasi&oacute;n, la abuela paterna propone tomar una fotograf&iacute;a a su nieta, as&iacute; con sus hermosos crespos. A la t&iacute;a Mercedes se le ocurre colocarle una flor en la cabeza. A Laura le desagrada la ocurrencia y se resiste, pero su madre la obliga a obedecer y la fotograf&iacute;a revel&oacute; la imagen de una ni&ntilde;a malcriada cubri&eacute;ndose el rostro.&nbsp;<br />
                                                Una noche que su madre deja a los ni&ntilde;os dormidos y va a casa de la abuela a visitar un pariente enfermo, Laurita al darse cuenta de la situaci&oacute;n, se provee de una vela y una caja de f&oacute;sforos (no hab&iacute;a luz el&eacute;ctrica), abriga bien a sus dos hermanitos menores y llega con ellos a casa de la abuela.&nbsp;<br />
                                                _&iexcl;Margara! dice a su nuera do&ntilde;a Ana F&eacute;lix. Es la ni&ntilde;a.&nbsp;<br />
                                                _&iexcl;C&oacute;mo te atreviste...? le interroga su madre.&nbsp;<br />
                                                _ Antes yo mir&eacute; a ver si estaba la &quot;sayona&quot;, y como no la vi, sal&iacute;, respondi&oacute; valientemente la ni&ntilde;a.&nbsp;<br />
                                                &iexcl;C&oacute;mo le fascinaba entretenerse fabricando altares con los recortes sobrantes en la carpinter&iacute;a de su padre! Para evitar reprensiones, a veces se retiraba al solar de la casa lejos de la vista de don Clemente.&nbsp;<br />
                                                Un d&iacute;a, ya como de 7 a&ntilde;os lavaba en la acequia que atravesaba el patio de su casa, cuando de repente, su padre acerc&aacute;ndose muy serio, le pregunta: &quot;&iexcl;qu&eacute; tiene Ud., en las manos?&quot; pensando que era un cigarrillo, pero &iexcl;no! Era su sortijita de diamantes que con la luz del sol resplandec&iacute;a.&nbsp;<br />
                                                Desde muy peque&ntilde;a, ante alguna perturbaci&oacute;n de &aacute;nimo, se niega a alimentarse. As&iacute;, cuando ve a Panchita, su hermana menor, inerte en brazos de su madre, le advierte:&nbsp;<br />
                                                &quot;Ya sabes mam&aacute;, no voy a comer nada, ni hoy ni ma&ntilde;ana. Era profundamente sensible.&quot;&nbsp;<br />
                                                _Octaviano, -le suplica una ma&ntilde;ana a su hermano-, acomp&aacute;&ntilde;ame a misa de aguinaldos.&nbsp;<br />
                                                _Si me traes una taza de caf&eacute; bien caliente.&nbsp;<br />
                                                Laura lo complace sol&iacute;cita, y el brib&oacute;n, luego de tomarlo, se envolvi&oacute; en su s&aacute;bana y la dej&oacute; plantada y triste. &quot;Yo, incapaz de acusarlo ni de vengarme -recordar&aacute; ella-, lo soportaba todo en silencio&quot;.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>4. La Ni&ntilde;a del Cristo</strong></span></p>

                                            <p>Varias circunstancias concurrentes en la vida de Laura a sus 13 a&ntilde;os de edad, marcar&aacute;n el rumbo de sus ideales.&nbsp;<br />
                                                Pl&aacute;cida, su prima, hija de la t&iacute;a Mercedes, es a su vez, su gran amiga, casi como una hermana. Pl&aacute;cida es coqueta y le habla de modas, de fiestas y de galanter&iacute;as, lo que a Laura desagrada. Por otra parte, los planes de sus padres est&aacute; orientados hacia un futuro matrimonio de su hija.&nbsp;<br />
                                                Ella quiere ser toda de Dios, pero &iquest;c&oacute;mo? en Venezuela no se conocen religiosas. Los conventos de clausura han sido eliminados por el presidente Guzm&aacute;n Blanco, al igual que los seminarios. Su gobierno ha sido para la Iglesia de Venezuela una verdadera purificaci&oacute;n.&nbsp;<br />
                                                Un d&iacute;a, mientras oraba en el templo de Maracay, &quot;Me inspir&oacute; el dulce Jes&uacute;s del tabern&aacute;culo preguntarle: &iquest;Y no puedo unirme a ti (en matrimonio), como las dem&aacute;s mujeres a los hombres?&quot; Y sinti&oacute; en lo profundo de su alma un clar&iacute;simo SI. Era el 16 de Julio de 1888, festividad de la Virgen del Carmen. Y, aunque ella reconoce que entonces no tuvo pleno conocimiento de lo que hizo en este gran d&iacute;a, ser&aacute; una experiencia inolvidable a lo largo de su existencia: Se consider&oacute; desde aquel momento extraordinario, la prometida de Dios.&nbsp;<br />
                                                A los pocos meses, el 8 de diciembre de 1888, efect&uacute;a felizmente su tan ansiada primera comuni&oacute;n; y ya instruida por el P&aacute;rroco Antonio Ferrer, &quot;con alegr&iacute;a indecible&quot; pronuncia un voto privado de virginidad al &uacute;nico amado de su coraz&oacute;n: Jes&uacute;s Sacramentado. Por eso afirma: &quot;En la Eucarist&iacute;a est&aacute; mi tesoro y all&iacute; est&aacute; mi coraz&oacute;n&quot;. Se desprende de sus peque&ntilde;as vanidades: sus amados crespos, sus joyas. Desde este memorable d&iacute;a comenzar&aacute; a observar los tres votos de obediencia, pobreza y castidad, y promete vivir con la mirada discretamente baja, caracter&iacute;stica particularmente suya. En lugar de sus prendas, llevar&aacute; en adelante un crucifijo sobre el pecho, circunstancia que le merecer&aacute; de parte del pueblo el nombre de &quot;La ni&ntilde;a del Cristo&quot;.&nbsp;<br />
                                                A esa edad, convierte su hogar en una escuela para los ni&ntilde;os pobres, a quienes tambi&eacute;n prepara para la primera comuni&oacute;n. Al encuentro de los gastos que esta iniciativa le ocasiona, confecciona dulces criollos para la venta, en la cual colaboran sus padres y sus maestras, la familia Blanco.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>5.El nuevo p&aacute;rroco de Maracay</strong></span></p>

                                            <p>Los estudios en el colegio de Maracay culminan en 1892, cuando Laura cuenta 17 a&ntilde;os. Es el mes de Septiembre. En las j&oacute;venes alumnas reina la emoci&oacute;n y la alegr&iacute;a. Laura es una de las mejores y a ella se asigna el discurso que, en nombre de sus compa&ntilde;eras, deber&aacute; pronunciar p&uacute;blicamente en la plaza del pueblo como parte de los actos programados con motivo del fin de curso. Para este d&iacute;a, su madre le ha preparado un hermoso vestido de color azul celeste, bordado en seda blanca. Laura obediente lo estrena; pero luego lo regalar&aacute; a su amiga Rosarito, no sin antes obtener el permiso de su madre.&nbsp;<br />
                                                Al finalizar sus estudios, Laura extiende su labor catequ&iacute;stica a los j&oacute;venes de la parroquia. Ya anciana aludir&aacute; a esta experiencia, acotando que &quot;jam&aacute;s a alguien se le ocurri&oacute; faltarle el respeto&quot;.&nbsp;<br />
                                                Don Clemente, orgullosos de su hija, aspiraba a enviarla a Caracas a fin de que prosiguiera all&iacute; su formaci&oacute;n, pero las circunstancias del momento no resultaron favorables. Dios ten&iacute;a reservados para ella otros caminos.&nbsp;<br />
                                                Cierto d&iacute;a que Laura ayudaba a sus maestras en la preparaci&oacute;n de un ajuar matrimonial, se presenta el nuevo p&aacute;rroco Vicente L&oacute;pez Aveledo y all&iacute; se conocen. &Eacute;l es un joven sacerdote caraque&ntilde;o, de grandes ideales e incansable esp&iacute;ritu pastoral. Alterna sus actividades parroquiales con obras sociales, educativas y culturales. Por designio divino una nueva tarea le espera: encauzar la vida de esta jovencita, &aacute;vida de Dios y de bien. La invita a colaborar en las labores parroquiales, las que inicia, previo permiso de sus padres, mediante el honroso oficio de lavar y arreglar los purificadores para el sacrificio eucar&iacute;stico. Con la delicadeza que siempre la caracteriz&oacute; en este menester, se apresura a adquirir utensilios nuevos, dedicados exclusivamente a ello.&nbsp;<br />
                                                El 8 de diciembre de 1893, el Padre L&oacute;pez funda la Sociedad de Hijas de Mar&iacute;a, a cuyo ingreso, Laura se prepara con 8 d&iacute;as de retiro espiritual en absoluto silencio, al final de los cuales renueva su voto de virginidad, esta vez en forma perpetua. Recuerda con emoci&oacute;n la hermosa pl&aacute;tica del Padre L&oacute;pez. &quot;&iexcl;Qu&eacute; bien habla nuestro padre!&quot;, escribe.&nbsp;<br />
                                                Aquella es una parroquia viva, fervorosa y alegre; muy eucar&iacute;stica. Es el humus donde se desarrolla la vocaci&oacute;n de Laura, futura Madre Mar&iacute;a de San Jos&eacute;.</p>

                                            <p>&nbsp;</p>

                                            <p><strong><span style={{"font-size":"18px"}}>6. Una circunstancia inesperada</span></strong></p>

                                            <p>Laura ans&iacute;a consagrarse a Dios en un convento de clausura: es su gran ilusi&oacute;n, y as&iacute; lo ha manifestado al p&aacute;rroco, quien le recomienda esperar. Los conventos de monjas en Venezuela han sido suprimidos pos el &quot;Ilustre Americano&quot;. Tendr&iacute;a que ausentarse a Espa&ntilde;a o a la Isla de Trinidad. El panorama se presenta incierto: pero el Padre L&oacute;pez promete ayudarla en este discernimiento vocacional.&nbsp;<br />
                                                Mientras tanto vive de oraci&oacute;n y de servicio al pr&oacute;jimo. Clemencia, su hermana, trata de disuadirla de aquellas actitudes que a ella le resultan un tanto extra&ntilde;as en una joven com&uacute;n; pero nada consigue. Laura se siente inclinada a la vida de silencio y de oraci&oacute;n, de contemplaci&oacute;n y penitencia. Cada d&iacute;a asiste a misa, pese al disgusto de su padre, y dedica largos ratos de oraci&oacute;n ante el Sant&iacute;simo Sacramento, o en el patio de su casa, bajo una planta de catig&uuml;ire, testigo de sus inquietudes.&nbsp;<br />
                                                De pronto, una circunstancia especial conmueve a la poblaci&oacute;n venezolana: La epidemia de viruela se desata implacable produciendo angustia, zozobra y muerte, particularmente en la clase despose&iacute;da: infecci&oacute;n, contagio, cuerpos humanos cubiertos de f&eacute;tidas p&uacute;stulas, a veces bajo los aleros de las casas. La situaci&oacute;n sanitaria es p&eacute;sima; no existe centro de salud.&nbsp;<br />
                                                Ser&aacute; una circunstancia que pondr&aacute; a prueba el temple y la heroica virtud del padre L&oacute;pez Aveledo, quien a su condici&oacute;n de pastor, trata de hacer frente a aquella dram&aacute;tica situaci&oacute;n. Su inicial experiencia y sus relaciones con el personal del Hospital &quot;Vargas&quot; de Caracas, del que fue capell&aacute;n, le son favorables. Personalmente los atiende, los traslada en hombros hasta donde puedan ser atendidos dignamente en sus &uacute;ltimos momentos, y cuando nada puede hacer por su alivio corporal, con l&aacute;grimas en los ojos, de rodillas ante ellos en plena v&iacute;a, les administra los sacramentos y les dirige unciosas palabras y oraciones.&nbsp;<br />
                                                El abnegado p&aacute;rroco se siente en la imperiosa obligaci&oacute;n de instalar por propia iniciativa un puesto de emergencia, que dar&aacute; origen al primer hospital de Maracay.&nbsp;<br />
                                                El padre hace un urgente llamado a su feligres&iacute;a. Unos critican, otros, en su mayor&iacute;a est&aacute;n dispuestos a colaborar generosamente. Se alquila entonces la casa de las hermanas Y&eacute;pez, en la calle Miranda cruce con Sucre.&nbsp;<br />
                                                Es el 3 noviembre de 1893.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>7. Cambio de ruta: Un hospital</strong></span></p>

                                            <p>Laura quiere retiro, clausura, soledad, y Dios le est&aacute; pidiendo servicio activo, ineludible. Ella, como espiga madura, se doblega ante el designio divino y acepta su voluntad.&nbsp;<br />
                                                El hospital queda fundado con el nombre de &quot;San Jos&eacute;&quot;, patrono de la parroquia. Un notable grupo de personas secunda la iniciativa del padre L&oacute;pez Aveledo; m&eacute;dicos, farmac&eacute;uticos, se&ntilde;oras, j&oacute;venes y hasta ni&ntilde;os, colaboran en el arduo trabajo.&nbsp;<br />
                                                La Junta Directiva est&aacute; constituida por las Blanco, las maestras de Laura; los m&eacute;dicos se turnan mensualmente, y la atenci&oacute;n directa de los enfermos se encomienda a un grupo de j&oacute;venes voluntarias, entre las cuales est&aacute; Laura. Representa la mayor dosis de sacrificio: Enfermeras, cocineras, camareras, lo son todo: en medio de aquella pobreza y con instrumentos de trabajo los m&aacute;s rudimentarios, la higiene y el aseo resplandecen, pero sobre todo, la caridad.&nbsp;<br />
                                                Junto a Laura est&aacute;n: Ulpiana Gil Qui&ntilde;ones, Josefina Rojas, Dolores Olmo, Margarita Dorta, Socorro salmer&oacute;n, y la se&ntilde;ora Carmen Garbozo de Ayala, Eulogia Burgos colabora con la recolecci&oacute;n de fondos econ&oacute;micos.&nbsp;<br />
                                                Tit&aacute;nica tuvo que ser la fe y la voluntad de aquel grupo de mujeres para mantener en pie esta obra de caridad com&uacute;n en tiempos tan crudos. La miseria se hace ley. A la baja mundial de precios de los principales productos nacionales de exportaci&oacute;n, como el caf&eacute; y el cacao, tendr&aacute;n que enfrentar el azote de enfermedades como el paludismo y la tuberculosis, y las invasoras nubes de langostas que arrasaron las siembras, base de la econom&iacute;a del pa&iacute;s.&nbsp;<br />
                                                Laura reside en la calle P&aacute;ez, muy cerca del hospital. Trabaja todo el d&iacute;a con los enfermos y a las 8 de la noche se retira a su casa, seg&uacute;n exigencia de sus padres.&nbsp;<br />
                                                &iquest;D&oacute;nde se han ido los sue&ntilde;os de Laura Alvarado de retirarse a la tranquila soledad de un convento para mejor servir a su Dios? Su pueblo, la Iglesia, los pobres, la necesitan aqu&iacute; y ahora. Y ella se entrega con gran abnegaci&oacute;n. Ocho a&ntilde;os de servicio en aquel hospital la prepararon para mayores ascensos en la pr&aacute;ctica de la virtud cristiana, especialmente de la caridad.&nbsp;<br />
                                                Y tambi&eacute;n de la humildad.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>8. Con nombre y apellido</strong></span></p>

                                            <p>De 1894 a 1895 las j&oacute;venes del Hospital trabajan bajo la direcci&oacute;n de la se&ntilde;ora Juana de Motamayor, la cual fue sustituida por la se&ntilde;ora Antonia del Castillo, ex-religiosa procedente de las Islas Canarias, a quien probablemente el padre L&oacute;pez hab&iacute;a conocido en el hospital Vargas de Caracas. &quot;Misia Antonia&quot; comienzan a llamar en el hospital a aquel nuevo personaje que entra en escena con el rol de directora y administradora.&nbsp;<br />
                                                &iquest;Qui&eacute;n iba a imaginar que aquella nueva ec&oacute;noma ser&iacute;a un instrumento de purificaci&oacute;n para Laura? Seg&uacute;n afirmaci&oacute;n personal de la protagonista, aquella feliz ni&ntilde;a no conoc&iacute;a el sufrimiento, que desde ahora se hace presente con nombre y apellido.&nbsp;<br />
                                                En este importante momento de su vida, Laura abre su coraz&oacute;n virginal a la fecundidad de la cruz y sufre en silencio incontables pruebas por parte de &quot;Misia Antonia&quot;, a quien ella, Laura, &quot;quiere con toda su alma&quot;. &quot;Era tremenda, me hac&iacute;a sufrir, pero yo la quer&iacute;a mucho. &iexcl;pobrecita!&quot;. As&iacute; son los santos. En la oscuridad de la prueba, resplandece la luz, y en el crisol se purifica el oro.&nbsp;<br />
                                                Cuando Do&ntilde;a Antonia se tornaba muy fastidiosa, las otras j&oacute;venes desobedec&iacute;an, no as&iacute; Laura: ella lo ofrec&iacute;a a Dios, por amor a sus pobres. El tiempo transcurr&iacute;a y la pobre v&iacute;ctima callaba. Nada confiaba a sus padres ni al sacerdote por temor de no poder continuar en aquella obra, &quot;lugar de sus delicias&quot; apost&oacute;licas.&nbsp;<br />
                                                De temperamento sensible y muy reservada, Laura enferma. Agudas crisis de asfixia llevan a temer por su vida. El Dr. Tabosqui ha dicho:&nbsp;<br />
                                                &quot;Se nos muere la ni&ntilde;a del Cristo&quot;&nbsp;<br />
                                                Ante esta situaci&oacute;n, do&ntilde;a Antonia muy preocupada, habla con el padre L&oacute;pez Aveledo: Ella quiere prometer a Dios retirarse del hospital e irse a prestar sus servicios a un apartado lazareto, como en efecto lo hace.&nbsp;<br />
                                                Laura se recupera.&nbsp;<br />
                                                Posteriormente, cuando Laura toma el h&aacute;bito religioso, le escribe una carta a do&ntilde;a Antonia, quien le responde y, entre otras frases, le expresa: &quot;Yo comprend&iacute;a que usted ten&iacute;a vocaci&oacute;n&quot;.&nbsp;<br />
                                                Por ello la Madre Mar&iacute;a, agregar&aacute;: &quot;Ella fue mi maestra, mi gran maestra&quot;.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>9. Don Clemente arrancado del abismo</strong></span></p>

                                            <p>El 5 de abril de 1899, el padre L&oacute;pez Aveledo entrega a Laura la direcci&oacute;n y administraci&oacute;n del hospital, del que hace su residencia. Est&aacute; pr&oacute;xima a cumplir 24 a&ntilde;os de edad.&nbsp;<br />
                                                Todas las noches, don Clemente se acerca al hospital a despedirse de su hija, quien no atrevi&eacute;ndose a recibirlo, le da las buenas noches desde la puerta. Piensa que su sacrificio como &quot;consagrada a Dios&quot; debe ser completo. Le preocupa mucho su padre y ora incesantemente por &eacute;l. &iquest;Qu&eacute; no har&iacute;a ella por la salvaci&oacute;n de su alma?&nbsp;<br />
                                                El 17 de diciembre de 1899 a las dos de la madrugada sostiene una fuerte lucha espiritual, en la que invoca a todos los santos de su devoci&oacute;n, hasta que, finalmente una voz interior la tranquiliz&oacute;: - Te basta mi gracia. Su lac&oacute;nica nota de ese d&iacute;a nada explica. Concluye escribiendo: &quot;!Ah, Se&ntilde;or, hab&eacute;is aceptado mi sacrificio! Bendito seas&quot;.&nbsp;<br />
                                                Transcurridos apenas 12 d&iacute;as, Laura recibe la noticia de que su padre ha sufrido una &quot;congesti&oacute;n cerebral&quot; severa. Parece estar muerto. Atribulada, corre a postrarse a los pies de la imagen de nuestra Se&ntilde;ora de las Mercedes, a quien encomienda la salvaci&oacute;n eterna de su padre. &quot;No me levantar&eacute; de aqu&iacute; -le dice- hasta que me concedas esta gracia&quot;. Ofrece a Dios el sacrificio de ayuno total y perpetuo por manos de Mar&iacute;a, refugio de pecadores. Su maternal intercesi&oacute;n no se hace esperar. Don Clemente reacciona y con plena lucidez mental recibe todos los sacramentos, incluso el del matrimonio. Ten&iacute;a 55 a&ntilde;os de edad.&nbsp;<br />
                                                Laura, pensando &quot;por ignorancia que al estar ya consagrada al servicio de Dios, ya no pod&iacute;a ir a casa de su adorado viejo, llorando, llorando, lavaba las &uacute;lceras de mis pobres... hasta que empez&oacute; de nuevo la agon&iacute;a, y el padre L&oacute;pez me mand&oacute;; fui y estuve desde las 12 del d&iacute;a hasta las 3 de la tarde, cuando expir&oacute;. Enseguida, al hospital de nuevo&quot;.&nbsp;<br />
                                                &iquest;Se relaciona esta promesa de ayuno absoluto con aquella experiencia del 17 de diciembre? Posiblemente.&nbsp;<br />
                                                Lo cierto es que Laura inicia esta nueva aventura de fe, aliment&aacute;ndose s&oacute;lo con la comuni&oacute;n diaria. Es la Eucarist&iacute;a quien milagrosamente la conforta en medio de las m&uacute;ltiples actividades y responsabilidades de su cargo. As&iacute; transcurren 10 a&ntilde;os hasta que por obediencia el padre L&oacute;pez Aveledo, a ra&iacute;z de una enfermedad, mitiga su ayuno: su dieta fue m&iacute;nima durante el resto de su vida.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>10. Agustinas al servicio de los pobres</strong></span></p>

                                            <p>El a&ntilde;o 1900 en Venezuela es &eacute;poca de guerrillas. En Octubre de ese a&ntilde;o un terremoto conmueve a todo el pa&iacute;s. Poblaciones casi desaparecidas, familias desamparadas, templos derrumbados, actividades interrumpidas con los consiguientes trastornos p&uacute;blicos.&nbsp;<br />
                                                A nivel eclesial, al Arzobispo de Caracas, Monse&ntilde;or Cr&iacute;spulo Uzc&aacute;tegui, por motivos de salud es sustituido en sus funciones por el Vicario Provisor, Monse&ntilde;or Juan Bautista Castro.&nbsp;<br />
                                                Contra el r&eacute;gimen del presidente Cipriano Castro, se suscitan sucesivas rebeliones hasta 1901, cuando estalla la sonada revoluci&oacute;n &quot;Libertadora&quot;, cuyo protagonista es el acaudalado hombre de negocios Manuel Antonio Matos, concu&ntilde;ado de Guzm&aacute;n Blanco.&nbsp;<br />
                                                Junto a Laura y, animada por el mismo ideal de consagrarse a Dios, labora su fiel amiga Ulpiana Gil. Durante 8 a&ntilde;os han trabajado dura y abnegadamente en el hospital y, previa oraci&oacute;n y conversaci&oacute;n con las dos j&oacute;venes, el 22 de enero de 1901, el Padre L&oacute;pez Aveledo funda con ellas la Congregaci&oacute;n de las Hermanas Agustinas. A los pocos d&iacute;as se les agregan otras dos j&oacute;venes: Francisca Rojas y Mar&iacute;a F&eacute;lix Rodr&iacute;guez.&nbsp;<br />
                                                Dada la convulsionada situaci&oacute;n pol&iacute;tica del pa&iacute;s, la comunicaci&oacute;n con el arzobispado de Caracas, no era f&aacute;cil. Necesitan la licencia para la vestici&oacute;n del h&aacute;bito religioso y ellas, las j&oacute;venes, quer&iacute;an vestirlo el pr&oacute;ximo 11 de febrero, festividad de la Virgen de Lourdes. Por tal premura encomiendan el caso a la &quot;abogada de lo imposible&quot;, Santa Rita de Casia, prometi&eacute;ndole ser&iacute;an como ella, Agustinas.&nbsp;<br />
                                                La respuesta de Monse&ntilde;or Juan Bautista Castro va dirigida al Padre L&oacute;pez Aveledo: &quot;Se me pide por una se&ntilde;orita llamada Laura Mar&iacute;a en uni&oacute;n de otras que la acompa&ntilde;an en el servicio el hospital de esa parroquia, permiso para vestir un h&aacute;bito religioso. Si esto no presenta ning&uacute;n inconveniente, lo concedo con gusto a esas buenas servidoras de los pobres&quot;.&nbsp;<br />
                                                Es tanta la estrechez econ&oacute;mica que no alcanza la tela adquirida y Laura elige para ella el h&aacute;bito usado de una piadosa se&ntilde;ora, pariente suya.&nbsp;<br />
                                                Los nuevos nombres de las Hermanas son: Mar&iacute;a, Catalina, Francisca y M&aacute;xima, todas &quot;de San Jos&eacute;&quot;, patr&oacute;n de la parroquia de Maracay.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>11. Presencia viva de la caridad</strong></span></p>

                                            <p>Las abnegadas servidoras del hospital &quot;San Jos&eacute;&quot; se han constituido en Congregaci&oacute;n religiosa para los pobres. Oportunamente, el padre L&oacute;pez Aveledo se presenta con su grupo de Hermanas ante el Vicario Provisor del arzobispado de Caracas, quien designa a Laura superiora. Ella obediente, acepta, y desde ese momento regir&aacute; los destinos de la Congregaci&oacute;n hasta 1960, siete a&ntilde;os antes de su muerte, siempre por obediencia.&nbsp;<br />
                                                El 13 de Septiembre de 1903, la Madre Mar&iacute;a emite sus votos perpetuos y, en acci&oacute;n de gracias, permanece hasta media noche de rodillas sosteniendo en su mano un cirio encendido. Tanta es su felicidad y su fervor.&nbsp;<br />
                                                Ese mismo a&ntilde;o, el Padre L&oacute;pez Aveledo presenta los primeros estatutos de la Congregaci&oacute;n ante Monse&ntilde;or Castro, explicitando la naturaleza y el fin de la misma, e informando de las obras atendidas hasta ese momento.&nbsp;<br />
                                                El origen de la nueva Congregaci&oacute;n de Agustinas se remite a &quot;varias se&ntilde;oritas de esta ciudad (Maracay), quienes en su ardiente celo por la gloria de Dios y el bien de las almas, y siguiendo el impulso interior de la gracia y un deseo afectuoso de corresponder a ella, se sintieron incitadas a poner los medios para conseguir la perfecci&oacute;n en una vida retirada de oraci&oacute;n, en el servicio de los desgraciados pobres de nuestro Se&ntilde;or Jesucristo, declar&aacute;ndose esclavas de sus hermanos indigentes&quot;. Y con &quot;el contentamiento de los venerables p&aacute;rrocos atienden los hospitales de Maracay, La Victoria y Villa de Cura&quot;.&nbsp;<br />
                                                La revoluci&oacute;n &quot;Libertadora&quot; ha agotado cuantiosos recursos humanos y econ&oacute;micos. Sangre y fuego, miseria y dolor, conforman el panorama nacional. All&iacute; va la Madre Mar&iacute;a con sus Hermanas, al cuartel general de Cagua, a atender dos hospitales de campa&ntilde;a con centenares de heridos cada uno. Son apenas diez Hermanas que deben multiplicar sus esfuerzos para todos, los partidarios del gobiernos y los del bando contrario. Pasan luego al cuartel de Maracay y en el hospital &quot;San Jos&eacute;&quot; atienden 150 hospitalizados. Un hermoso testimonio de caridad cristiana.&nbsp;<br />
                                                No hay que olvidar que para estos a&ntilde;os Laura est&aacute; observando su ayuno absoluto, por lo cual no es extra&ntilde;o que en una ocasi&oacute;n, mientras se trasladaba a pie por el campo en compa&ntilde;&iacute;a de la Hermana Francisca para ir a atender los heridos, hubieran de solicitar ayuda en una humilde casa.&nbsp;<br />
                                                Como si fuera poco, en 1904 cunde la epidemia de viruela, enfermedad infecto-contagiosa que exige el aislamiento de los pacientes. A esos &quot;degredos&quot; se traslada la Madre Mar&iacute;a con sus Hermanas. &quot;El 15 de Agosto ya est&aacute;bamos instaladas con nuestros pobres enfermos... Recuerdo con alegr&iacute;a esos hermosos d&iacute;as llenos de penas y amarguras&quot;.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>12. La llamaron &quot;Mama&iacute;ta&quot;</strong></span></p>

                                            <p>&iquest;No es l&oacute;gico que de estos flagelos sociales surgieran dolorosas consecuencias? Ah&iacute; tenemos en las clases populares, serias situaciones de orfandad, ni&ntilde;os sin hogar. Ella, la Madre Mar&iacute;a, que desde ni&ntilde;a so&ntilde;aba ser la &quot;mama&iacute;ta&quot; de un centenar de ni&ntilde;os pobres, comienza a trabajar en este sentido. Los adultos necesitan hospitales, pero los ni&ntilde;os una familia, un hogar lleno de afecto. Ser&aacute; &eacute;sta su obra predilecta.&nbsp;<br />
                                                Quiere fundar el primer asilo para hu&eacute;rfanos. El padre L&oacute;pez duda de que pueda sostenerse. Ella lo anima a confiar en la divina Providencia. En el mismo hospital &quot;San Jos&eacute;&quot; destina para ellos un espacio. Re&uacute;ne un grupo peque&ntilde;o de los m&aacute;s necesitados y el 24 de Mayo, bajo la protecci&oacute;n de la Madre de Dios, funda el &quot;Asilo Inmaculada Concepci&oacute;n&quot; en la ciudad de Maracay. Es el a&ntilde;o 1905. Desde entonces, la Madre Mar&iacute;a se traslada con sus ni&ntilde;os a una casa alquilada por Bs. 40, cancelando semanalmente &quot;lo que buenamente pod&iacute;a&quot;.&nbsp;<br />
                                                Aquella obra era un reto. El padre L&oacute;pez la hab&iacute;a autorizado diciendo: &quot;Si dura un mes y se sostiene, le aseguro su duraci&oacute;n&quot;.&nbsp;<br />
                                                La Madre Mar&iacute;a con satisfacci&oacute;n informa que, a pesar de todo, ni un s&oacute;lo d&iacute;a sus hu&eacute;rfanos se han acostado sin alimentarse: la dieta b&aacute;sica era el topocho (cambur), cocido, asado o frito; con dos bol&iacute;vares se preparaba un hervido o carne molida, y en temporada de mangos, el delicioso fruto serv&iacute;a de postre.&nbsp;<br />
                                                Inicialmente, y por la necesidad imperante, los &quot;hogares&quot; de la Madre Mar&iacute;a, fueron mixtos, hasta que la autoridad eclesi&aacute;stica intervino y ella obedeci&oacute; dejando s&oacute;lo las ni&ntilde;as, aunque como ella expresa: con gran dolor.&nbsp;<br />
                                                Los hu&eacute;rfanos van en aumento; la casa alquilada en la calle llamada de la estaci&oacute;n, hoy Soublette, es ya insuficiente. El Se&ntilde;or Francisco (Pancho) G&oacute;mez, antiguo benefactor del hospital, le cede su vieja casa en la calle Santos Michelena y a ella se traslada en 1906. Esta casa se convierte en Casa Madre, noviciado y Casa de ejercicios espirituales para las Hermanas de la Congregaci&oacute;n.&nbsp;<br />
                                                Los ni&ntilde;os se acostumbraban a llamarla &quot;Mama&iacute;ta&quot;. Los educa, los ense&ntilde;a a re&iacute;r, a jugar, a comer a la mesa con cubiertos. Es feliz en medio de ellos. Adem&aacute;s de las Hermanas, junto a ella est&aacute; su madre, do&ntilde;a Margarita, a quien los ni&ntilde;os llaman &quot;abuelita&quot;. Al padre L&oacute;pez le dicen &quot;pap&aacute; m&iacute;o&quot;. Es un hogar.&nbsp;<br />
                                                &iquest;Qu&eacute; pretende la Madre Mar&iacute;a con estos hogares para hu&eacute;rfanos? Ella lo determina en sus estatutos: Librarlos de los peligros a los que se hallan expuestos, educarlos cristianamente y ense&ntilde;arles alg&uacute;n oficio concerniente a su sexo.</p>

                                            <p>&nbsp;</p>

                                            <p><span style={{"font-size":"18px"}}><strong>13. Por los caminos de Dios</strong></span></p>

                                            <p>Caminos que se cruzan, largos y mon&oacute;tonos, polvorientos o agrestes; carros de mula, jornadas a pie; traves&iacute;a de r&iacute;os; sencillas goletas recorriendo mares; pobr&iacute;simas viviendas, &quot;casuchas destartaladas&quot; en m&aacute;s de una ocasi&oacute;n; comienzos inauditos. Son estos, avatares que se suman silenciosos al haber fundacional de la incansable Madre Mar&iacute;a de San Jos&eacute;.&nbsp;<br />
                                                En la medida de las necesidades y de sus posibilidades, apremiada por esa gran virtud de la caridad cristiana, y fundamentada en la confianza filial al Padre Dios, va trenzando una red de obras apost&oacute;licas y sociales en favor de los despose&iacute;dos: Ac&aacute;, asilos para mendigos que deambulan por las calles; all&aacute; escuelas nocturnas para empleadas dom&eacute;sticas; hoy, hospitales y antituberculosos; ma&ntilde;ana, casas maternas, orfanatos, escuelas populares; evangelizaci&oacute;n permanente, impartida en los pueblos, en las c&aacute;rceles y en los campos; catequesis en las parroquias y en las escuelas, y en algunos sitios, catequesis nocturnas.&nbsp;<br />
                                                Por todos los sitios donde le es posible llegar, va distribuyendo sus comunidades de caridad, y junto con ellas, la presencia eucar&iacute;stica. Insiste una y otra vez hasta que la autoridad eclesi&aacute;stica le concede las requeridas licencias para instalas la divina Majestad en sus casas.&nbsp;<br />
                                                &quot;&iexcl;Un sagrario m&aacute;s! Ya las penas y pobrezas ser&aacute;n aliviadas con la dulce presencia del Dios de nuestros altares, la por siempre amada, la adorable Eucarist&iacute;a&quot;. &iexcl;Cu&aacute;nto se lamentaba cuando, por circunstancias que no depend&iacute;an de ella, deb&iacute;a cerrar una de las obras ben&eacute;ficas!: era cerrar la puerta de caridad y &iexcl;un sagrario menos!&nbsp;<br />
                                                Hasta 1917 goz&oacute; del asesoramiento y apoyo del padre L&oacute;pez Aveledo: En abnegado ejercicio de su ministerio pastoral, contrajo la tan temida tuberculosis, para entonces enfermedad mortal. Por orden del General G&oacute;mez quien resid&iacute;a y gobernada al pa&iacute;s desde Maracay, fue trasladado con sus hermanas a la ciudad de Los Teques, donde despu&eacute;s de un verdadero calvario sufrido con heroica virtud, falleci&oacute; el 30 de Enero de 1917, dejando una luminosa estela de santidad.&nbsp;<br />
                                                En su lamentada ausencia, la Madre Mar&iacute;a recibir&aacute; la fiel y afectuosa orientaci&oacute;n de uno de sus m&aacute;s ilustres hijos espirituales: Monse&ntilde;or Hilario Cabrera D&iacute;az, vocaci&oacute;n sacerdotal cultivada por el padre L&oacute;pez Aveledo.&nbsp;<br />
                                                A la hora de su muerte en 1967 el balance de sus fundaciones era: catorce hospitales de caridad; dos antituberculosos, un leprocomio, dos albergues para mendigos, once centro socio-educativos (orfanatos-escuelas) dos casas maternas y una escuela nocturna para dom&eacute;sticas.&nbsp;<br />
                                                No le falt&oacute; visi&oacute;n para extender su obra a otros pa&iacute;ses; pero sus intentos no se consolidaron.</p>

                                            <p>Fuente: http://www.agustinasrecoletas.net/mm_biografia.html</p>

                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>




            </div>
            <Footer />
        </div>);
    }

}