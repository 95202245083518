/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import LocationSanctuarySliders from './locationSanctuarySliders';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import {Helmet} from "react-helmet";
import instagram_logo from '../img/Instagram_icon.png';
import whatsapp_logo from '../img/logo_whatsapp.png';

export default class ContactUs extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Contactos Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Contactos Santuario Nacional Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="contactos, santuario, virgen, coromoto, patrona, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <LocationSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Cont&aacute;ctenos</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12" align="center">
                                        <img src={logo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p><strong>Para mayor informaci&oacute;n puede comunicarse con nosotros por los tel&eacute;fonos: 0426-1731521</strong></p>
                                            <p><strong><img src={whatsapp_logo} /> WhatsApp: <a href=" https://wa.me/584125980742">0412-5980742</a></strong></p>
                                            <p><strong><img src={instagram_logo} /> Instagram: <a href="https://www.instagram.com/basilicamenorcoromoto/">https://www.instagram.com/basilicamenorcoromoto/</a></strong></p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}