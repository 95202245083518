/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from './header';
import MainSlider from './mainSliders';
import MainTitle from './mainTitle';
import MainRowBodyContentXL from './mainRowBodyContentXL';
import MainRowBodyContentLG from './mainRowBodyContentLG';
import MainRowBodyContentSM from './mainRowBodyContentSM';
import MainRowBodyContentEXSM from './mainRowBodyContentEXSM';
import NewsResumeComponent from './NewsResumeComponent';
import Footer from './footer';
import row1col1Img from '../img/historia_aparicion_icon2.jpg';
import row1col2Img from '../img/SantaReliquia_Shortcut2.png';
import row1col3Img from '../img/historySantuary_small.jpg';
import row1col4Img from '../img/SantoRostroVirgenCoromoto_231.jpg';
import row2col1Img from '../img/DescargasMultimedias.jpg';
import row2col2Img from '../img/galeria-virgen-coromoto.jpg';
import row2col3Img from '../img/Ubicacion.jpg';
import row2col4Img from '../img/santuario-small.jpg';
import SantuaryLink from './santuaryLink';
import row3col1Img from '../img/TourVirtual.png';
import row3col2Img from '../img/Videos.PNG';
import row3col3Img from '../img/beatos_venezolanos.jpg';
import row3col4Img from '../img/prayers_231x202.jpg';
import historiaAparicionMovil from '../img/historia_aparicion_movil.jpg';
import santoRostroMovil from '../img/SantoRostroVirgenCoromoto_small.jpg';
import descargasMultimediaMovil from '../img/descargas_multimedia_319px-2.jpg';
import donacionesMovil from '../img/donaciones-movil.jpg';
import tourVirtualMovil from '../img/tourVirtual319.png';
import galeriaMovil from '../img/galeria-virgen-coromoto-movil.jpg';
import schedulerMovil from '../img/scheduler-santuary-319.jpg';
import pastoralLetterMovil from '../img/cartaPastoral.jpg';
import blessedMovil from '../img/beatos_venezolanos-319.jpg';
import prayerIcon from '../img/prayers.jpg';
import pastoralLetter231 from '../img/cartaPastoral_231x202.jpg';
import prayerIcon231 from '../img/prayers_231x202.jpg';
import iconVideos from '../img/icono-videos.jpg';

export default class Index extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.setState({ lang: sessionStorage.getItem('language') });
                sessionStorage.setItem('language', sessionStorage.getItem('language'));
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));
            }
        }
    }

    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {
        let historyApparitionLb;
        let historyApparitionURL;
        let knowingHolyRelicLb, knowingHolyRelicURL;
        let sanctuaryHistoryLb, sanctuaryHistoryURL;
        let findingsHolyRelicLb, findingsHolyRelicURL;
        let downloadMultimediaLb, downloadMultimediaURL;
        let videosMultimediaLb, videosMultimediaURL;
        let locationLb, locationURL;
        let massScheduleLb, massScheduleURL;
        let tourVirtualSanctuaryLb, tourVirtualSanctuaryURL;
        let videosLb, videosURL;
        let prayersLb, prayersURL;
        let imagesLb, imagesURL;
        let blessedLb, blessedURL;
        let donationsLb, donationsURL;
        let pastoralLetterLb, pastoralLetterURL;
        let holyRosaryLb, holyRosaryURL;
        let helmetComp;
        let newsBasilicaLb, newsBasilicaURL;

        if (this.state.lang === 'es') {
            historyApparitionLb = "Historia Aparici&oacute;n";
            historyApparitionURL = "historia-virgen-coromoto.html";
            knowingHolyRelicLb = "Conociendo la Santa Reliquia";
            knowingHolyRelicURL = "santa-reliquia-virgen-coromoto.html";
            sanctuaryHistoryLb = "Historia Santuario";
            sanctuaryHistoryURL = "historia-santuario-nacional-virgen-coromoto.html";
            findingsHolyRelicLb = "Hallazgos Santa Reliquia Marzo 2009";
            findingsHolyRelicURL = "hallazgos-santa-reliquia-virgen-coromoto.html";
            downloadMultimediaLb = "Descargas Multimedia";
            downloadMultimediaURL = "descargas-multimedia-santuario-nacional-virgen-coromoto.html";
            locationLb = "Ubicaci&oacute;n";
            locationURL = "ubicacion-santuario-virgen-coromoto.html";
            massScheduleLb = "Horarios de Misas";
            massScheduleURL = "horario-misa-santuario-nacional-virgen-coromoto.html";
            tourVirtualSanctuaryLb = "Tour Virtual 360°";
            tourVirtualSanctuaryURL = "tour-virtual-santuario-nacional-virgen-coromoto.html";
            videosLb = "Videos";
            videosURL = "videos-santuario-nacional-virgen-coromoto.html";
            prayersLb = "Oraciones";
            prayersURL = "oraciones-virgen-de-coromoto.html";
            imagesLb = "Galer&iacute;a Im&aacute;genes Virgen de Coromoto";
            imagesURL = "imagenes-hallazgos-reliquia-virgen-coromoto.html";
            blessedLb = "Beatos de Venezuela";
            blessedURL = "beatos-venezuela.html";
            donationsLb = "Donaciones";
            donationsURL = "donaciones-santuario-nacional-virgen-coromoto.html";
            pastoralLetterLb = "Carta Pastoral Hallazgos Santa Reliquia";
            pastoralLetterURL = "carta-pastoral-santa-reliquia-virgen-coromoto.html";
            holyRosaryLb = "Oremos el Santo Rosario";
            holyRosaryURL = "santo-rosario.html";
            newsBasilicaLb = "Noticias Santuario";
            newsBasilicaURL = "noticias-santuario-virgen-coromoto.html";
            videosMultimediaLb = "Videos de la Santísima Virgen María de Coromoto";
            videosMultimediaURL = "videos-santuario-nacional-virgen-coromoto.html";

            //seo 
            helmetComp = <Helmet>
                <title>Bas&iacute;lica Menor Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Bas&iacute;lica Menor Santuario Nacional Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="Coromoto, Virgen, Maria, Santuario, Basilica" />
            </Helmet>;
        }
        else {
            historyApparitionLb = "Apparition History";
            historyApparitionURL = "history-our-lady-of-coromoto.html";
            knowingHolyRelicLb = "Knowing the Holy Relic";
            knowingHolyRelicURL = "knowing-holy-relic-our-lady-of-coromoto.html";
            sanctuaryHistoryLb = "Sanctuary History";
            sanctuaryHistoryURL = "history-sanctuary-our-lady-of-coromoto.html";
            findingsHolyRelicLb = "Findings Holy Relic March 2009";
            findingsHolyRelicURL = "findings-holy-relic-our-lady-of-coromoto.html";
            downloadMultimediaLb = "Download Multimedia";
            downloadMultimediaURL = "download-multimedia-our-lady-of-coromoto.html";
            locationLb = "Location";
            locationURL = "location-our-lady-of-coromoto.html";
            massScheduleLb = "Mass Schedule";
            massScheduleURL = "mass-schedule-minor-basilica-our-lady-coromoto.html";
            tourVirtualSanctuaryLb = "Tour Virtual 360°";
            tourVirtualSanctuaryURL = "tour-virtual-santuario-nacional-virgen-coromoto.html";
            videosLb = "Videos";
            videosURL = "videos-our-lady-of-coromoto.html";
            prayersLb = "Prayers";
            prayersURL = "prayers-to-our-lady-of-coromoto.html";
            imagesLb = "Images Gallery";
            imagesURL = "images-findings-our-lady-of-coromoto.html";
            blessedLb = "Blessed";
            blessedURL = "blessed.html";
            donationsLb = "Donations";
            donationsURL = "donations-sanctuary-our-lady-of-coromoto.html";
            pastoralLetterLb = "Pastoral Letter Findings Holy Relic March 2009";
            pastoralLetterURL = "pastoral-letter-holy-relic-coromoto.html";
            holyRosaryLb = "Pray the Holy Rosary";
            holyRosaryURL = "holy-rosary.html";
            newsBasilicaLb = "Sanctuary News";
            newsBasilicaURL = "news-our-lady-coromoto.html";
            videosMultimediaLb = "Videos Virgin Mary of Coromoto";
            videosMultimediaURL = "videos-santuario-nacional-virgen-coromoto.html";

            //seo 
            helmetComp = <Helmet>
                <title>Minor Basilica of Our Lady Of Coromoto Official Website</title>
                <meta name="description" content="Minor Basilica of Our Lady Of Coromoto Official Website" />
                <meta name="keywords" content="Coromoto, Mary, Lady, Basilica, Sanctuary" />
            </Helmet>;

        }


        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <MainSlider />
            <div className="container">
                <div className="row"><div className="col-12">&nbsp;</div></div>
                <MainTitle />
                <MainRowBodyContentXL
                    img1={row1col1Img}
                    img2={row1col2Img}
                    img3={row1col3Img}
                    img4={row1col4Img}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                    link2={new SantuaryLink(knowingHolyRelicURL, knowingHolyRelicLb)}
                    link3={new SantuaryLink(sanctuaryHistoryURL, sanctuaryHistoryLb)}
                    link4={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)} />

                <MainRowBodyContentXL
                    img1={row2col1Img}
                    img2={row2col2Img}
                    img3={row2col3Img}
                    img4={row2col4Img}
                    link1={new SantuaryLink(downloadMultimediaURL, downloadMultimediaLb)}
                    link2={new SantuaryLink(imagesURL, imagesLb)}
                    link3={new SantuaryLink(locationURL, locationLb)}
                    link4={new SantuaryLink(massScheduleURL, massScheduleLb)}
                />

                <MainRowBodyContentXL
                    img1={row3col1Img}
                    img2={row3col2Img}
                    img3={row3col3Img}
                    img4={row3col4Img}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                    link2={new SantuaryLink(videosURL, videosLb)}
                    link3={new SantuaryLink(blessedURL, blessedLb)}
                    link4={new SantuaryLink(prayersURL, prayersLb)}
                />


                <MainRowBodyContentLG
                    img1={row1col1Img}
                    img2={row1col3Img}
                    img3={row1col4Img}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                    link2={new SantuaryLink(sanctuaryHistoryURL, sanctuaryHistoryLb)}
                    link3={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)}
                />

                <MainRowBodyContentLG
                    img1={pastoralLetter231}
                    img2={row1col2Img}
                    img3={prayerIcon231}
                    link1={new SantuaryLink(pastoralLetterURL, pastoralLetterLb)}
                    link2={new SantuaryLink(knowingHolyRelicURL, knowingHolyRelicLb)}
                    link3={new SantuaryLink(prayersURL, prayersLb)}
                />

                <MainRowBodyContentLG
                    img1={row2col1Img}
                    img2={row2col3Img}
                    img3={row2col4Img}
                    link1={new SantuaryLink(downloadMultimediaURL, downloadMultimediaLb)}
                    link2={new SantuaryLink(locationURL, locationLb)}
                    link3={new SantuaryLink(massScheduleURL, massScheduleLb)}
                />

                <MainRowBodyContentLG
                    img1={row3col1Img}
                    img2={row3col2Img}
                    img3={row3col3Img}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                    link2={new SantuaryLink(videosURL, videosLb)}
                    link3={new SantuaryLink(blessedURL, blessedLb)}
                />


                <MainRowBodyContentSM
                    img1={row1col1Img}
                    img2={row1col4Img}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                    link2={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)}
                />

                <MainRowBodyContentSM
                    img1={row1col3Img}
                    img2={pastoralLetter231}
                    link1={new SantuaryLink(sanctuaryHistoryURL, sanctuaryHistoryLb)}
                    link2={new SantuaryLink(pastoralLetterURL, pastoralLetterLb)}
                />

                <MainRowBodyContentSM
                    img1={row1col2Img}
                    img2={prayerIcon231}
                    link1={new SantuaryLink(knowingHolyRelicURL, knowingHolyRelicLb)}
                    link2={new SantuaryLink(prayersURL, prayersLb)}
                />


                <MainRowBodyContentSM
                    img1={row3col1Img}
                    img2={row3col2Img}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                    link2={new SantuaryLink(videosURL, videosLb)}
                />

                <MainRowBodyContentEXSM
                    img1={historiaAparicionMovil}
                    link1={new SantuaryLink(historyApparitionURL, historyApparitionLb)}
                />

                <MainRowBodyContentEXSM
                    img1={santoRostroMovil}
                    link1={new SantuaryLink(findingsHolyRelicURL, findingsHolyRelicLb)}
                />

                <MainRowBodyContentEXSM
                    img1={pastoralLetterMovil}
                    link1={new SantuaryLink(pastoralLetterURL, pastoralLetterLb)}
                />

                <MainRowBodyContentEXSM
                    img1={prayerIcon}
                    link1={new SantuaryLink(prayersURL, prayersLb)}
                />


                <MainRowBodyContentEXSM
                    img1={schedulerMovil}
                    link1={new SantuaryLink(massScheduleURL, massScheduleLb)}
                />


                <MainRowBodyContentEXSM
                    img1={iconVideos}
                    link1={new SantuaryLink(videosMultimediaURL, videosMultimediaLb)}
                />


                <MainRowBodyContentEXSM
                    img1={descargasMultimediaMovil}
                    link1={new SantuaryLink(downloadMultimediaURL, downloadMultimediaLb)}
                />

                <MainRowBodyContentEXSM
                    img1={galeriaMovil}
                    link1={new SantuaryLink(imagesURL, imagesLb)}
                />


                <MainRowBodyContentEXSM
                    img1={tourVirtualMovil}
                    link1={new SantuaryLink(tourVirtualSanctuaryURL, tourVirtualSanctuaryLb)}
                />

                <MainRowBodyContentEXSM
                    img1={blessedMovil}
                    link1={new SantuaryLink(blessedURL, blessedLb)}
                />

                <MainRowBodyContentEXSM
                    img1={donacionesMovil}
                    link1={new SantuaryLink(donationsURL, donationsLb)}
                />


                <div className="row">
                    <div className="col-12 d-none">&nbsp;</div>
                </div>

                <NewsResumeComponent />

                <div className="row">
                    <div className="col-12 d-none d-md-block d-xl-block d-lg-block">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12 d-none d-md-block d-xl-block d-lg-block">&nbsp;</div>
                </div>

            </div>
            <Footer />
        </div>);
    }

}