/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component, useState } from 'react';
import Header from './header';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import { Helmet } from "react-helmet";
import RosaryElement from './RosaryElement';
import AudioPlayer from "react-h5-audio-player";
import 'react-h5-audio-player/lib/styles.css';
import HolyRosaryMistery from './HolyRosaryMistery';
import Parser from 'html-react-parser';
import propES from "../holyRosaryText/es.json";
import propEN from "../holyRosaryText/en.json";
import { Trans } from '@lingui/macro';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


export default class HolyRosary extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);       
        //let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("holy-rosary.html") ? "en" : "es");
        let language = "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;     
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { 
                 lang: "es", 
                 eventChangeLanguage: onLanguageChange, 
                 rosary: this.loadRosary("es"), 
                 rosaryMisteryCount: 0, 
                 rosaryElementsCounts: 0, 
                 misteryRead: false, 
                 rosaryFinished: false, 
                 beginRosary: true, 
                 beginRosaryList: this.loadBeginRosaryElement("es"), 
                 countBeginRosary: 0, 
                 endRosaryExecuted: false, 
                 endRosaryList: this.loadRosaryEnd("es"), 
                 endRosaryCounter: 0, 
                 reload: false,
                 redirect: false,
                 position: -1, 
                 changeMistery: false,
                 openModal : true
            };
        }
        else {
            this.state = { 
                lang: language, 
                eventChangeLanguage: onLanguageChange, 
                rosary: this.loadRosary(language), 
                rosaryMisteryCount: 0, 
                rosaryElementsCounts: 0, 
                misteryRead: false, 
                rosaryFinished: false, 
                beginRosary: true, 
                beginRosaryList: this.loadBeginRosaryElement(language), 
                countBeginRosary: 0, 
                endRosaryExecuted: false, 
                endRosaryList: this.loadRosaryEnd(language), 
                endRosaryCounter: 0, 
                reload: false,
                redirect: false,
                position: -1,
                changeMistery: false,
                openModal : true
            };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
        this.loadNextRosaryElement = this.loadNextRosaryElement.bind(this);
        this.loadPreviusRosaryElement = this.loadPreviusRosaryElement.bind(this);
        this.getHolyRosaryElement = this.getHolyRosaryElement.bind(this);
        this.loadCountsRosaryElement = this.loadCountsRosaryElement.bind(this);
        this.loadBeginRosaryElement = this.loadBeginRosaryElement.bind(this);
        this.loadNextBeginRosaryElement = this.loadNextBeginRosaryElement.bind(this);
        this.loadRosaryEnd = this.loadRosaryEnd.bind(this);
        this.loadNextEndRosaryElement = this.loadNextEndRosaryElement.bind(this);
        this.getLetaniasLauretanas = this.getLetaniasLauretanas.bind(this);
        this.reloadRosary = this.reloadRosary.bind(this);
        this.getNodePlayer = this.getNodePlayer.bind(this);       
        this.player = React.createRef();
        this.loadRosary = this.loadRosary.bind(this);      
        this.getMisteryName = this.getMisteryName.bind(this); 
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        console.log('close..');
        this.setState({ openModal: false });
    }

    componentDidMount() {
        this._isMounted = true;

        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    componentWillUnmount() {      
        this._isMounted = false;
    }


    componentDidUpdate(prevProps, prevState, snapshot) {      
    
        if(this.state.changeMistery) {                       
            let newPosition = this.state.position;
            this.setState({ changeMistery: false, redirect: false, rosaryMisteryCount: newPosition, rosaryElementsCounts: 0, misteryRead: false });
        }
        
    }


    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    getNodePlayer() {      
        const node = this.player.current;        
    }

    reloadRosary() {
        console.log("error->reloadRosary()");
        if(this._isMounted) {         
            this.setState({ reload: true});
        }        
    }

    /**
     * Load th begin of holy rosary
     * @param {*} lang 
     * @returns 
     */
    loadBeginRosaryElement(lang) {
        let properties;
        let beginRosary = [];

        if (lang!==null && lang === 'es') {
            properties = propES;
            let count0 = new RosaryElement("holy_rosary/begin/rosary.png", "holy_rosary/audio/Senal_Cruz.opus", properties.CROSS_SIGN_TITLE, properties.CROSS_SIGN);
            let count1 = new RosaryElement("holy_rosary/begin/acto_contriccion.png", "holy_rosary/audio/Acto_Contriccion.opus", properties.ACT_OF_CONTRITION_TITLE, properties.ACT_OF_CONTRITION);
            let count2 = new RosaryElement("holy_rosary/begin/acto_contriccion.png", "holy_rosary/audio/credo.opus", properties.APOSTLES_CREED_TITLE, properties.APOSTLES_CREED);
            beginRosary = [count0, count1, count2];    
        }
        else {
            properties = propEN;
            let count0 = new RosaryElement("holy_rosary/begin/rosary.png", "holy_rosary/audio/Senal_Cruz.opus", properties.CROSS_SIGN_TITLE, properties.CROSS_SIGN);
            let count1 = new RosaryElement("holy_rosary/begin/rosary.png", "holy_rosary/audio/Acto_Contriccion.opus", properties.ACT_OF_CONTRITION_TITLE, properties.ACT_OF_CONTRITION);
            let count2 = new RosaryElement("holy_rosary/begin/rosary.png", "holy_rosary/audio/credo.opus", properties.APOSTLES_CREED_TITLE, properties.APOSTLES_CREED);
            let count3 = new RosaryElement("holy_rosary/begin/acto_contriccion.png", "holy_rosary/audio/Padre_Nuestro.opus", properties.OUR_FATHER_TITLE, properties.OUR_FATHER);
            let count4 = new RosaryElement("holy_rosary/begin/en/hail_mary1.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, properties.HAIL_MARY);            
            let count5 = new RosaryElement("holy_rosary/begin/en/hail_mary2.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, properties.HAIL_MARY);            
            let count6 = new RosaryElement("holy_rosary/begin/en/hail_mary3.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, properties.HAIL_MARY); 
            let count7 = new RosaryElement("holy_rosary/begin/en/hail_mary3.png", "holy_rosary/audio/gloria_prayer.opus", properties.GLORY_TITLE, properties.GLORY);            
            beginRosary = [count0, count1, count2, count3, count4, count5, count6, count7];   
        }
              
        return beginRosary;
    }


    /**
     * Get litany of the virgin mary
     * @param {*} lang 
     * @returns 
     */
    getLetaniasLauretanas(lang) {
        let properties;
        if (lang!==null && lang === 'es') {
            properties = propES;
        }
        else {
            properties = propEN;
        }

        let letanias = properties.LITANY_BLESSED_VIRGIN_MARY_1;
        let letanias2 = properties.LITANY_BLESSED_VIRGIN_MARY_2;
        let letanias3 = properties.LITANY_BLESSED_VIRGIN_MARY_3;
        let letanias4 = properties.LITANY_BLESSED_VIRGIN_MARY_4;
        let letanias5 = properties.LITANY_BLESSED_VIRGIN_MARY_5;

        return letanias + letanias2 +letanias3 + letanias4 + letanias5;
     }

     /**
      * load the final part of the holy rosary
      * @param {*} lang 
      * @returns 
      */
    loadRosaryEnd(lang) {
        let lastPartList;
        let properties;
        if (lang!==null && lang === 'es') {
            properties = propES;
        }
        else {
            properties = propEN;
        }
        
        let avemaria = properties.HAIL_MARY;
        let salve = properties.HAIL_HOLY_QUEEN;
        let letanias = this.getLetaniasLauretanas(lang);      
        let corderoPrayer = properties.LAMB_OF_GOD_PRAYER;        

        if (lang!==null && lang === 'es') {
            let count0 = new RosaryElement("holy_rosary/end/avemaria_1.png", "holy_rosary/audio/before.opus", "", properties.HAIL_MARY_BEFORE);
            let count1 = new RosaryElement("holy_rosary/end/avemaria_1.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
            let count2 = new RosaryElement("holy_rosary/end/avemaria_2.png", "holy_rosary/audio/during.opus", "", properties.HAIL_MARY_DURING);
            let count3 = new RosaryElement("holy_rosary/end/avemaria_2.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
            let count4 = new RosaryElement("holy_rosary/end/avemaria_3.png", "holy_rosary/audio/after.opus", "", properties.HAIL_MARY_AFTER);
            let count5 = new RosaryElement("holy_rosary/end/avemaria_3.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
            let count6 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/salve_virgen.opus", properties.HAIL_HOLY_QUEEN_TITLE, salve);
            let count7 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/letanias_lauretanas.opus", properties.LITANY_BLESSED_VIRGIN_TITLE, letanias);
            let count8 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/cordero_prayer.opus", properties.LAMB_OF_GOD_TITLE, corderoPrayer);
            let count9 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/bajoamparo_prayer.opus", properties.UNDER_YOUR_PROTECTION_TITLE, properties.UNDER_YOUR_PROTECTION_PRAYER);
            let count10 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/oracion_final.opus", properties.LAST_PRAYER_TITLE, properties.PRAY_FOR_US_PRAYER);
    
            lastPartList = [count0, count1, count2, count3, count4, count5, count6, count7, count8, count9, count10];
        }
        else {
            let count0 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/salve_virgen.opus", properties.HAIL_HOLY_QUEEN_TITLE, salve);
            let count1 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/letanias_lauretanas.opus", properties.LITANY_BLESSED_VIRGIN_TITLE, letanias);
            let count2 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/cordero_prayer.opus", properties.LAMB_OF_GOD_TITLE, corderoPrayer);
            let count3 = new RosaryElement("holy_rosary/mystery_images/asuncion-santisima-virgen_small.png", "holy_rosary/audio/oracion_final.opus", properties.LAST_PRAYER_TITLE, properties.LAST_PRAYER);

            lastPartList = [count0, count1, count2, count3];
        }

       
        return lastPartList;
    }

    /**
     * Load the next pray of the holy rosary
     */
    loadNextEndRosaryElement(language) {       
            let nextRosaryElement = this.state.endRosaryCounter + 1;

            if (language!==null && language === 'es') {
                if (nextRosaryElement < 11) {
                    this.setState({ endRosaryCounter: nextRosaryElement });
                }
                else {
                    this.setState({endRosaryExecuted: true});       
                }
            }
            else {
                if (nextRosaryElement < 4) {
                    this.setState({ endRosaryCounter: nextRosaryElement });
                }
                else {
                    this.setState({endRosaryExecuted: true});       
                }
            }
            
    }

    loadCountsRosaryElement() {
        this.setState({ misteryRead: true, rosaryElementsCounts: 0 });
    }

  

    /**
     * Load the next bead in the begin of the holy rosary
     * @param {*} language 
     */
    loadNextBeginRosaryElement(language) {
        let nextRosaryElement = this.state.countBeginRosary + 1;

        if (language!==null && language === 'es') {           
            if (nextRosaryElement < 3) {
                this.setState({ countBeginRosary: nextRosaryElement });
            }
            else {
                this.setState({beginRosary: false});       
            }
        }
        else {
            if (nextRosaryElement < 8) {
                this.setState({ countBeginRosary: nextRosaryElement });
            }
            else {
                this.setState({beginRosary: false});       
            }
        }        
    }

    getHolyRosaryElement(lang, count) {      
        let properties;
        if (lang!==null && lang === 'es') {
            properties = propES;
        }
        else {
            properties = propEN;
        }

        let padrenuestro = properties.OUR_FATHER;
        let avemaria = properties.HAIL_MARY;
        let gloria = properties.GLORY;
        let fatimaPrayer = properties.FATIMA;
        let count0, count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12;       
        let gloria_audio = "holy_rosary/audio/gloria_prayer.opus";
        let fatima_audio = "holy_rosary/audio/fatima_prayer.opus";

        if (count === 0) {
            count0 = new RosaryElement("holy_rosary/begin/rosary_primer_misterios_padre_nuestro.png", "holy_rosary/audio/Padre_Nuestro.opus", properties.OUR_FATHER_TITLE, padrenuestro);
            count1 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_1.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
            count2 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_2.png", "holy_rosary/audio/ave_maria2.opus", properties.HAIL_MARY_TITLE, avemaria);
            count3 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_3.png", "holy_rosary/audio/ave_maria3.opus", properties.HAIL_MARY_TITLE, avemaria);
            count4 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_4.png", "holy_rosary/audio/ave_maria4.opus", properties.HAIL_MARY_TITLE, avemaria);
            count5 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_5.png", "holy_rosary/audio/ave_maria5.opus", properties.HAIL_MARY_TITLE, avemaria);
            count6 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_6.png", "holy_rosary/audio/ave_maria6.opus", properties.HAIL_MARY_TITLE, avemaria);
            count7 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_7.png", "holy_rosary/audio/ave_maria7.opus", properties.HAIL_MARY_TITLE, avemaria);
            count8 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_8.png", "holy_rosary/audio/ave_maria8.opus", properties.HAIL_MARY_TITLE, avemaria);
            count9 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_9.png", "holy_rosary/audio/ave_maria9.opus", properties.HAIL_MARY_TITLE, avemaria);
            count10 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_10.png", "holy_rosary/audio/ave_maria10.opus", properties.HAIL_MARY_TITLE, avemaria);
            count11 = new RosaryElement("holy_rosary/first_mystery/rosary_primer_misterio_ave_maria_10.png", gloria_audio, properties.GLORY_TITLE, gloria);
            count12 = new RosaryElement("holy_rosary/mystery_images/Virgen_Fatima.jpg", fatima_audio, properties.FATIMA_TITLE, fatimaPrayer);
        }
        else {
            if (count === 1) {
                count0 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_padre_nuestro.png", "holy_rosary/audio/Padre_Nuestro.opus", "Padre Nuestro", padrenuestro);
                count1 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_1.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
                count2 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_2.png", "holy_rosary/audio/ave_maria2.opus", properties.HAIL_MARY_TITLE, avemaria);
                count3 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_3.png", "holy_rosary/audio/ave_maria3.opus", properties.HAIL_MARY_TITLE, avemaria);
                count4 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_4.png", "holy_rosary/audio/ave_maria4.opus", properties.HAIL_MARY_TITLE, avemaria);
                count5 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_5.png", "holy_rosary/audio/ave_maria5.opus", properties.HAIL_MARY_TITLE, avemaria);
                count6 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_6.png", "holy_rosary/audio/ave_maria6.opus", properties.HAIL_MARY_TITLE, avemaria);
                count7 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_7.png", "holy_rosary/audio/ave_maria7.opus", properties.HAIL_MARY_TITLE, avemaria);
                count8 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_8.png", "holy_rosary/audio/ave_maria8.opus", properties.HAIL_MARY_TITLE, avemaria);
                count9 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_9.png", "holy_rosary/audio/ave_maria9.opus", properties.HAIL_MARY_TITLE, avemaria);
                count10 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_10.png", "holy_rosary/audio/ave_maria10.opus", properties.HAIL_MARY_TITLE, avemaria);
                count11 = new RosaryElement("holy_rosary/second_mystery/rosary_segundo_misterio_avemaria_10.png", gloria_audio, properties.GLORY_TITLE, gloria);
                count12 = new RosaryElement("holy_rosary/mystery_images/Virgen_Fatima.jpg", fatima_audio, properties.FATIMA_TITLE, fatimaPrayer);
            } 
            else {
                if (count === 2) {
                    count0 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_padre_nuestro.png", "holy_rosary/audio/Padre_Nuestro.opus", "Padre Nuestro", padrenuestro);
                    count1 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_1.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count2 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_2.png", "holy_rosary/audio/ave_maria2.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count3 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_3.png", "holy_rosary/audio/ave_maria3.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count4 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_4.png", "holy_rosary/audio/ave_maria4.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count5 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_5.png", "holy_rosary/audio/ave_maria5.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count6 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_6.png", "holy_rosary/audio/ave_maria6.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count7 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_7.png", "holy_rosary/audio/ave_maria7.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count8 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_8.png", "holy_rosary/audio/ave_maria8.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count9 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_9.png", "holy_rosary/audio/ave_maria9.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count10 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_10.png", "holy_rosary/audio/ave_maria10.opus", properties.HAIL_MARY_TITLE, avemaria);
                    count11 = new RosaryElement("holy_rosary/thrid_mystery/rosary_tercer_misterio_ave_maria_10.png", gloria_audio, properties.GLORY_TITLE, gloria);
                    count12 = new RosaryElement("holy_rosary/mystery_images/Virgen_Fatima.jpg", fatima_audio, properties.FATIMA_TITLE, fatimaPrayer);
                }  
                else {
                    if (count === 3) {
                        count0 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_padre_nuestro.png", "holy_rosary/audio/Padre_Nuestro.opus", "Padre Nuestro", padrenuestro);
                        count1 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_1.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count2 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_2.png", "holy_rosary/audio/ave_maria2.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count3 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_3.png", "holy_rosary/audio/ave_maria3.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count4 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_4.png", "holy_rosary/audio/ave_maria4.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count5 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_5.png", "holy_rosary/audio/ave_maria5.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count6 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_6.png", "holy_rosary/audio/ave_maria6.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count7 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_7.png", "holy_rosary/audio/ave_maria7.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count8 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_8.png", "holy_rosary/audio/ave_maria8.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count9 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_9.png", "holy_rosary/audio/ave_maria9.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count10 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_10.png", "holy_rosary/audio/ave_maria10.opus", properties.HAIL_MARY_TITLE, avemaria);
                        count11 = new RosaryElement("holy_rosary/fourth_mystery/rosary_cuarto_misterio_ave_maria_10.png", gloria_audio, properties.GLORY_TITLE, gloria);
                        count12 = new RosaryElement("holy_rosary/mystery_images/Virgen_Fatima.jpg", fatima_audio, properties.FATIMA_TITLE, fatimaPrayer);
                    }  
                    else {
                        if (count === 4) {
                            count0 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_padre_nuestro.png", "holy_rosary/audio/Padre_Nuestro.opus", "Padre Nuestro", padrenuestro);
                            count1 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_1.png", "holy_rosary/audio/ave_maria.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count2 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_2.png", "holy_rosary/audio/ave_maria2.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count3 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_3.png", "holy_rosary/audio/ave_maria3.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count4 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_4.png", "holy_rosary/audio/ave_maria4.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count5 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_5.png", "holy_rosary/audio/ave_maria5.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count6 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_6.png", "holy_rosary/audio/ave_maria6.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count7 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_7.png", "holy_rosary/audio/ave_maria7.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count8 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_8.png", "holy_rosary/audio/ave_maria8.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count9 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_9.png", "holy_rosary/audio/ave_maria9.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count10 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_10.png", "holy_rosary/audio/ave_maria10.opus", properties.HAIL_MARY_TITLE, avemaria);
                            count11 = new RosaryElement("holy_rosary/fifth_mystery/rosary_quinto_misterio_ave_maria_10.png", gloria_audio, properties.GLORY_TITLE, gloria);
                            count12 = new RosaryElement("holy_rosary/mystery_images/Virgen_Fatima.jpg", fatima_audio, properties.FATIMA_TITLE, fatimaPrayer);
                        }  
                    }
                }
            }
        }

        let rosaryCounts = [count0, count1, count2, count3, count4, count5, count6, count7, count8, count9, count10, count11, count12];

        return rosaryCounts;
    }

    loadRosary(language) {

        let properties;
        if (language!==null && language === 'es') {
            properties = propES;
        }
        else {
            properties = propEN;
        }
        let current_date = new Date();
        let cday = current_date.getDay();
        let holyRosaryMistery1, holyRosaryMistery2, holyRosaryMistery3, holyRosaryMistery4, holyRosaryMistery5;

        if (cday === 1 || cday === 6) {
            //misterios gosozos                  
            holyRosaryMistery1 = new HolyRosaryMistery(this.getHolyRosaryElement(language, 0), "holy_rosary/audio/Gozoso1.opus", properties.FIRST_JOYFUL_MISTERY_TITLE, "holy_rosary/mystery_images/anunciacion.jpg", properties.FIRST_JOYFUL_MISTERY);
            
            holyRosaryMistery2 = new HolyRosaryMistery(this.getHolyRosaryElement(language,1), "holy_rosary/audio/Gozoso2.opus", properties.SECOND_JOYFUL_MISTERY_TITLE, "holy_rosary/mystery_images/visitacion-maria-santisima.jpg", properties.SECOND_JOYFUL_MISTERY);

            holyRosaryMistery3 = new HolyRosaryMistery(this.getHolyRosaryElement(language,2), "holy_rosary/audio/Gozoso3.opus", properties.THIRD_JOYFUL_MISTERY_TITLE, "holy_rosary/mystery_images/nacimiento-nino-jesus.jpg", properties.THIRD_JOYFUL_MISTERY);

            holyRosaryMistery4 = new HolyRosaryMistery(this.getHolyRosaryElement(language,3), "holy_rosary/audio/Gozoso4.opus", properties.FOURTH_JOYFUL_MISTERY_TITLE, "holy_rosary/mystery_images/la-pesentacion-jesus.jpg", properties.FOURTH_JOYFUL_MISTERY);

            holyRosaryMistery5 = new HolyRosaryMistery(this.getHolyRosaryElement(language,4), "holy_rosary/audio/Gozoso5.opus", properties.FIFTH_JOYFUL_MISTERY_TITLE, "holy_rosary/mystery_images/nino-jesus-perdido-y-hallado-templo.jpg", properties.FIFTH_JOYFUL_MISTERY);

        }
        else {
            if (cday === 0 || cday === 3) {
                //misterios gloriosos           
                holyRosaryMistery1 = new HolyRosaryMistery(this.getHolyRosaryElement(language,0), "holy_rosary/audio/Glorioso1.opus", properties.FIRST_GLORIOUS_MISTERY_TITLE, "holy_rosary/mystery_images/resurreccion.jpg", properties.FIRST_GLORIOUS_MISTERY);

                holyRosaryMistery2 = new HolyRosaryMistery(this.getHolyRosaryElement(language,1), "holy_rosary/audio/Glorioso2.opus", properties.SECOND_GLORIOUS_MISTERY_TITLE, "holy_rosary/mystery_images/ascencion-senor-jesuscristo.jpg", properties.SECOND_GLORIOUS_MISTERY);

                holyRosaryMistery3 = new HolyRosaryMistery(this.getHolyRosaryElement(language,2), "holy_rosary/audio/Glorioso3.opus", properties.THIRD_GLORIOUS_MISTERY_TITLE, "holy_rosary/mystery_images/venida-espiritu-santo.jpg", properties.THIRD_GLORIOUS_MISTERY);

                holyRosaryMistery4 = new HolyRosaryMistery(this.getHolyRosaryElement(language,3), "holy_rosary/audio/Glorioso4.opus", properties.FOURTH_GLORIOUS_MISTERY_TITLE, "holy_rosary/mystery_images/asuncion-santisima-virgen.jpg", properties.FOURTH_GLORIOUS_MISTERY);

                holyRosaryMistery5 = new HolyRosaryMistery(this.getHolyRosaryElement(language,4), "holy_rosary/audio/Glorioso5.opus", properties.FIFTH_GLORIOUS_MISTERY_TITLE, "holy_rosary/mystery_images/coronacion-santisima-virgen-maria.jpg", properties.FIFTH_GLORIOUS_MISTERY);

            }
            else {
                if (cday === 2 || cday === 5) {
                    //misterios dolorosos               
                    holyRosaryMistery1 = new HolyRosaryMistery(this.getHolyRosaryElement(language,0), "holy_rosary/audio/Doloroso1.opus", properties.FIRST_SORROWFUL_MISTERY_TITLE, "holy_rosary/mystery_images/oracion-senor-huerto-getsamani.jpg", properties.FIRST_SORROWFUL_MISTERY);
                   
                    holyRosaryMistery2 = new HolyRosaryMistery(this.getHolyRosaryElement(language,1), "holy_rosary/audio/Doloroso2.opus", properties.SECOND_SORROWFUL_MISTERY_TITLE, "holy_rosary/mystery_images/flajelacion-senor-jesuscristo.jpg", properties.SECOND_SORROWFUL_MISTERY);
                   
                    holyRosaryMistery3 = new HolyRosaryMistery(this.getHolyRosaryElement(language,2), "holy_rosary/audio/Doloroso3.opus", properties.THIRD_SORROWFUL_MISTERY_TITLE, "holy_rosary/mystery_images/coronacion-espinas.jpg", properties.THIRD_SORROWFUL_MISTERY);
                  
                    holyRosaryMistery4 = new HolyRosaryMistery(this.getHolyRosaryElement(language,3), "holy_rosary/audio/Doloroso4.opus", properties.FOURTH_SORROWFUL_MISTERY_TITLE, "holy_rosary/mystery_images/camino-al-calvario.jpg", properties.FOURTH_SORROWFUL_MISTERY);
                 
                    holyRosaryMistery5 = new HolyRosaryMistery(this.getHolyRosaryElement(language,4), "holy_rosary/audio/Doloroso5.opus", properties.FIFTH_SORROWFUL_MISTERY_TITLE, "holy_rosary/mystery_images/cruxificion-muerte-senor-jesus.jpg", properties.FIFTH_SORROWFUL_MISTERY);

                }
                else {
                    if (cday === 4) {
                        //misterios luminosos                       
                        holyRosaryMistery1 = new HolyRosaryMistery(this.getHolyRosaryElement(language,0), "holy_rosary/audio/Luminoso1.opus", properties.FIRST_LIGHT_MISTERY_TITLE, "holy_rosary/mystery_images/bautismo-senor-jesuscristo.jpg", properties.FIRST_LIGHT_MISTERY);
                      
                        holyRosaryMistery2 = new HolyRosaryMistery(this.getHolyRosaryElement(language,1), "holy_rosary/audio/Luminoso2.opus", properties.SECOND_LIGHT_MISTERY_TITLE, "holy_rosary/mystery_images/milagro-bodas-cana-galilea.jpg", properties.SECOND_LIGHT_MISTERY);
                     
                        holyRosaryMistery3 = new HolyRosaryMistery(this.getHolyRosaryElement(language,2), "holy_rosary/audio/Luminoso3.opus", properties.THIRD_LIGHT_MISTERY_TITLE, "holy_rosary/mystery_images/anuncio-reino-dios.jpg", properties.THIRD_LIGHT_MISTERY);
                      
                        holyRosaryMistery4 = new HolyRosaryMistery(this.getHolyRosaryElement(language,3), "holy_rosary/audio/Luminoso4.opus", properties.FOURTH_LIGHT_MISTERY_TITLE, "holy_rosary/mystery_images/transfiguracion-jesus-monte-tabor.jpg", properties.FOURTH_LIGHT_MISTERY);
                     
                        holyRosaryMistery5 = new HolyRosaryMistery(this.getHolyRosaryElement(language,4), "holy_rosary/audio/Luminoso5.opus", properties.FIFTH_LIGHT_MISTERY_TITLE, "holy_rosary/mystery_images/institucion-eucaristia.jpg", properties.FIFTH_LIGHT_MISTERY);

                    }
                }
            }
        }


        let holyRosary = [holyRosaryMistery1, holyRosaryMistery2, holyRosaryMistery3, holyRosaryMistery4, holyRosaryMistery5];

        return holyRosary;
    }

    loadPreviusRosaryElement() {
        if (this._isMounted) {
            let nextRosaryElement = this.state.rosaryElementsCounts - 1;
            this.setState({ rosaryElementsCounts: nextRosaryElement });
        }        
    }

    loadNextRosaryElement() {
        if (this._isMounted) {
            let nextRosaryElement = this.state.rosaryElementsCounts + 1;
            if (nextRosaryElement <= 12) {
                this.setState({ rosaryElementsCounts: nextRosaryElement });
            }
            else {
                if (this.state.rosaryMisteryCount < 4) {
                    let nextHolyMistery = this.state.rosaryMisteryCount + 1;
                    this.setState({ rosaryElementsCounts: nextRosaryElement, misteryRead: false, rosaryMisteryCount: nextHolyMistery });
                }
                else {
                    this.setState({ rosaryFinished: true });
                }
            }
        }
    }

 
    setRedirectFirstMistery = () => {       
        this.setState({ redirect: true, position: 0, changeMistery: true });
    }

    setRedirectSecondMistery = () => {       
        this.setState({ redirect: true, position: 1, changeMistery: true });
    }

    setRedirectThirdMistery = () => {       
        this.setState({ redirect: true, position: 2, changeMistery: true });
    }

    setRedirectFourthMistery = () => {       
        this.setState({ redirect: true, position: 3, changeMistery: true });
    }

    setRedirectFifthMistery = () => {       
        this.setState({ redirect: true, position: 4, changeMistery: true });
    }



    getMisteryName() {
        let current_date = new Date();
        let cday = current_date.getDay();
        let misteryType = "";
        if (cday === 1 || cday === 6) {
            if(this.state.lang === 'es') {
                misteryType="Misterios Gozosos";
            }
            else {
                misteryType="Joyful Mistery";
            }
        }
        else {
            if (cday === 0 || cday === 3) {
                if(this.state.lang === 'es') {
                    misteryType="Misterios Gloriosos";
                }
                else {
                    misteryType="Glorious Mistery";
                }
            }
            else {
                if (cday === 2 || cday === 5) {
                    if(this.state.lang === 'es') {
                        misteryType="Misterios Dolorosos";
                    }
                    else {
                        misteryType="Sorroful Mistery";
                    }
                }
                else {
                    if (cday === 4) {
                        if(this.state.lang === 'es') {
                            misteryType="Misterios Luminosos";
                        }
                        else {
                            misteryType="Light Mistery";
                        }
                    }
                }
            }
        }
        return misteryType;
    }

    
   
    render() {             
                 
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>El Santo Rosario Gu&iacute;a</title>
                <meta name="description" content="El Santo Rosario Gu&iacute;a Multimedia con Audio" />
                <meta name="keywords" content="rezar, santo, rosario, virgen, maria" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Pray The Holy Rosary</title>
                <meta name="description" content="The Holy Rosary" />
                <meta name="keywords" content="how, pray, holy, rosary, virgin, mary, blessed" />
            </Helmet>;
        }

        let misteryHolyRosary;        
       
        if (this.state.redirect) {      
            if (this.state.lang === 'es') {       
              return <Redirect  to={{ pathname: "/santo-rosario.html"  }} />
            }
            else {
                return <Redirect  to={{ pathname: "/holy-rosary.html"  }} />
            }
        }
        else {
           
            if(this.state.beginRosary) {
                let beginCountsList = this.state.beginRosaryList;
                let currentElementRosary = beginCountsList[this.state.countBeginRosary];           
    
                return (<div>      
                           
                    {helmetComp}
                    <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                        </div>
                        <div className="row">
                            <div className="col-12">                           
                                <div className="card">
                                    <div className="card-header"><h3><Trans>HolyRosaryLb</Trans> - {currentElementRosary.getElementText()}</h3>
                                    </div>
                                    <div className="card-body">
                                    <Popup open={this.state.openModal} closeOnDocumentClick position="top center" modal>
                                        <div className="modal2">
                                            <Link to="#" className="close" onClick={this.closeModal}>
                                                &times;
                                            </Link>
                                            Para iniciar el rezo del Santo Rosario haga click en en botón de Play, también puede descargar el PDF del Santo Rosario para imprimir.
                                        </div>
                                    </Popup>   
                                        <div className="row">
                                            <div className="col-12" align="center">
                                                <img src={currentElementRosary.getURL_Image()} className="img-thumbnail" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">&nbsp;</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">{Parser(currentElementRosary.getPrayer())}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">&nbsp;</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                            <AudioPlayer autoPlay={false} src={currentElementRosary.getURL_Sound()} layout={'stacked'}
                                                    onEnded={e => this.loadNextBeginRosaryElement(this.state.lang)}
                                                    onError={e =>  this.reloadRosary()}    
                                                    showJumpControls={false} 
                                                    showSkipControls={false}
                                                    ref={this.player}
                                                    onCanPlayThrough={this.getNodePlayer()}  
                                                    showDownloadProgress={true} />                                         
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" align="center">&nbsp;</div>
                        </div>
                        <div className="row">
                            <div className="col-12" align="center"><Link to="prayersFiles/elsantorosario.pdf" target="_blank" onClick={this.trackPDF}>Como rezar el Santo Rosario (descargar PDF)</Link></div>
                        </div>
                        <div className="row">
                            <div className="col-12" align="center">&nbsp;</div>
                        </div>
                        <div className="row">
                            <div class="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                        </div>
                        <div className="row">
                            <div className="col-12">&nbsp;</div>
                        </div>
                    </div>
                    <Footer />
                </div>);
            }
            else {
                if (this.state.rosary !== null && typeof this.state.rosary !== "undefined") {
                   
                    misteryHolyRosary = this.state.rosary[this.state.rosaryMisteryCount];
                                 
                    if(!this.state.rosaryFinished) {
                        if (misteryHolyRosary !== null && typeof misteryHolyRosary !== "undefined") {
        
                            if (!this.state.misteryRead) {                         
    
                                let misteryType = this.getMisteryName();
    
                                return (<div>
                                    {helmetComp}
                                    <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                                            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                                        </div>
            
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header"><h3><Trans>HolyRosaryLb</Trans> - {misteryHolyRosary.getTitle()}</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12">&nbsp;</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12" align="center"><h2>{misteryType}</h2></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12" align="center">
                                                                <img src={misteryHolyRosary.getURL_Image()} className="img-thumbnail"/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">&nbsp;</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">{misteryHolyRosary.getPrayer()}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">&nbsp;</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <AudioPlayer autoPlay={true} src={misteryHolyRosary.getURL_Sound()} layout={'stacked'}
                                                                    onEnded={e => this.loadCountsRosaryElement()}                                                               
                                                                    onError={e =>  this.reloadRosary()}    
                                                                    showJumpControls={false} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">&nbsp;</div>
                                        </div>
                                    </div>
                                    <Footer />
                                </div>);
                            }
                            else {
    
                                let misteryType = this.getMisteryName();
                               
                                if(this.state.rosaryElementsCounts<=12) {
                                    let rosaryCountsList = misteryHolyRosary.getRosaryCounts();
                                    let currentElementRosary = rosaryCountsList[this.state.rosaryElementsCounts];        
                
                                    return (<div>
                                        {helmetComp}
                                        <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                                                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                                            </div>
                
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <div className="card-header"><h3><Trans>HolyRosaryLb</Trans> - {misteryHolyRosary.getTitle()} - {currentElementRosary.getElementText()}</h3>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12" align="center">
                                                                    <img src={currentElementRosary.getURL_Image()} className="img-thumbnail"/>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">&nbsp;</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">{currentElementRosary.getPrayer()}</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">&nbsp;</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <AudioPlayer autoPlay={true} src={currentElementRosary.getURL_Sound()} layout={'stacked'}
                                                                        onEnded={e => this.loadNextRosaryElement()}
                                                                        onError={e =>  this.reloadRosary()}    
                                                                        onClickPrevious={e => this.loadPreviusRosaryElement()}
                                                                        onClickNext={e => this.loadNextRosaryElement()}
                                                                        showSkipControls={false}
                                                                        showJumpControls={false} 
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">&nbsp;</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12" align="center">
                                                                    <button className="btn btn-outline-primary btn-sm" onClick={this.setRedirectFirstMistery}>
                                                                    <Trans>FirstLb</Trans> {misteryType}
                                                                    </button>    
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">&nbsp;</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12" align="center">
                                                                    <button className="btn btn-outline-primary btn-sm" onClick={this.setRedirectSecondMistery}>
                                                                    <Trans>SecondLb</Trans> {misteryType}
                                                                    </button>    
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">&nbsp;</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12" align="center">
                                                                    <button className="btn btn-outline-primary btn-sm" onClick={this.setRedirectThirdMistery}>
                                                                    <Trans>ThirdLb</Trans> {misteryType}
                                                                    </button>    
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">&nbsp;</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12" align="center">
                                                                    <button className="btn btn-outline-primary btn-sm" onClick={this.setRedirectFourthMistery}>
                                                                    <Trans>FourthLb</Trans> {misteryType}
                                                                    </button>    
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">&nbsp;</div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12" align="center">
                                                                    <button className="btn btn-outline-primary btn-sm" onClick={this.setRedirectFifthMistery}>
                                                                    <Trans>FifthLb</Trans> {misteryType}
                                                                    </button>    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">&nbsp;</div>
                                            </div>                                        
                                        </div>
                                        <Footer />
                                    </div>);
                                }                   
                            }
                        }
                        else {
                            return (<div>
                                {helmetComp}
                                <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                                <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                                            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                                        </div>
            
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header"><h3><Trans>HolyRosaryLb</Trans></h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12" align="center">
                                                            <img src={logo} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">&nbsp;</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">Misterio no cargado, Por favor intente más tarde...</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">&nbsp;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">&nbsp;</div>
                                    </div>
                                </div>
                                <Footer />
                            </div>);
                        }
                    }
                    else {
                        if(!this.state.endRosaryExecuted) {
                            let endRosaryList = this.state.endRosaryList;
                            let currentElementRosary = endRosaryList[this.state.endRosaryCounter];
    
                            return (<div>
                                {helmetComp}
                                <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                                <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                                            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                                        </div>
                
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header"><h3><Trans>HolyRosaryLb</Trans> - {currentElementRosary.getElementText()}</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12" align="center">
                                                            <img src={currentElementRosary.getURL_Image()} className="img-thumbnail" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">&nbsp;</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12" align="center">{Parser(currentElementRosary.getPrayer())}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">&nbsp;</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <AudioPlayer autoPlay={true} src={currentElementRosary.getURL_Sound()} layout={'stacked'}
                                                                onEnded={e => this.loadNextEndRosaryElement(this.state.lang)}
                                                                onError={e =>  this.reloadRosary()}    
                                                                showJumpControls={false}                                                
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">&nbsp;</div>
                                    </div>
                                </div>
                                <Footer />
                            </div>);
                        }
                        else {
                            return (<div>
                                {helmetComp}
                                <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                                <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                                            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                                        </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header"><h3><Trans>HolyRosaryLb</Trans> - Bibliografía</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12" align="center">
                                                            <img src={logo} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">&nbsp;</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">Biblia de Jerusalén, Edición Latinoamericana, Editorial Desclée De Brouwer, S.A., 2007 <br/> http://www.vatican.va/special/rosary/documents/misteri_dolorosi_sp.html </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">&nbsp;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">&nbsp;</div>
                                    </div>
                                </div>
                                <Footer />
                            </div>); 
                        }                      
                    }
                  
        
                }
                else {
                    return (<div>
                        {helmetComp}
                        <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                        <div className="container">
                                 <div className="row">
                                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                                </div>
        
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header"><h3><Trans>HolyRosaryLb</Trans></h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12" align="center">
                                                    <img src={logo} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">&nbsp;</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">Santa Rosario no cargado, Por favor intente más tarde...</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">&nbsp;</div>
                            </div>
                        </div>
                        <Footer />
                    </div>);
                }
            }
        }

   

       


    }

}