/**
 * DeclareOurLadyCoromoto 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 06/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import reliquia from '../img/SANTA_RELIQUIA.png';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';


export default class ConsecrateVenezuelaOurLadyCoromoto extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Oraci&oacute;n de Consagraci&oacute;n de Venezuela a Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Oraci&oacute;n de Consagraci&oacute;n de Venezuela a Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="venezuela, consagrar, coromoto, virgen, maria, junio, 2011" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Oraci&oacute;n de Consagraci&oacute;n de Venezuela a Nuestra Se&ntilde;ora de Coromoto</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12" align="center">
                                        <img src={reliquia} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p>Con esta oraci&oacute;n fue Consagrada Venezuela a Nuestra Santa Patrona Nacional, pueden hacerla para consagrar su familia y el pa&iacute;s:</p>
                                            <b><i><p>&iexcl;Oh, Madre querida de Coromoto!</p>
                                            <p>T&uacute; que has acompa&ntilde;ado el nacimiento y el desarrollo de nuestra historia patria, venimos a tus plantas a consagrarnos como pueblo, como naci&oacute;n que te reconoce como Madre&nbsp;y a decirte que somos tuyos.</p>
                                            <p>Queremos colocar muy cerca de tu coraz&oacute;n nuestras necesidades, deseos, luchas y logros.</p>
                                            <p>En este momento de nuestra historia, te pedimos que mires a estos tus hijos que caminan en valle de l&aacute;grimas y consu&eacute;lalos mostr&aacute;ndonos siempre a tu Hijo.</p>
                                            <p>Te consagramos nuestra &nbsp;Patria Venezuela, con todos sus hijos, con sus familias, con los que sufren y son olvidados.</p>
                                            <p>Ens&eacute;&ntilde;anos, Virgen llanera a llevar dentro de nosotros a tu Hijo con el mismo amor y adoraci&oacute;n con que t&uacute; le llevaste.</p>
                                            <p>Que esta especial consagraci&oacute;n nos haga hijos m&aacute;s fieles a la Iglesia, a sus pastores y ministros.</p>
                                            <p>Mu&eacute;strate como Madre, como la bella Se&ntilde;ora del r&iacute;o Tucupido, a todos cuantos est&aacute;n alejados.</p>
                                            <p>Recibe, Virgen de Coromoto, nuestra consagraci&oacute;n y sost&eacute;n nuestros prop&oacute;sitos de vivir como disc&iacute;pulos y misioneros de Hijo a fin que podamos llevar a plenitud nuestra vocaci&oacute;n bautismal dando as&iacute; gloria a la Sant&iacute;sima Trinidad.</p></i></b>
                                            <p>Nota: Venezuela fue Consagrada a Nuestra Se&ntilde;ora De Coromoto Patrona de Venezuela por el Cardenal Jorge Urosa Savino el 26 de Junio del 2011.</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><Link to="prayersFiles/Oración-Consagración-Venezuela-Nuestra-Señora-Coromoto.pdf" target="_blank" onClick={this.trackPDF}>Oración de Consagración de Venezuela a Nuestra Señora Coromoto (descargar PDF)</Link></div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}