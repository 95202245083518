/**
 * SacramentsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import SacramentsSanctuarySliders from './sacramentsSanctuarySliders';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import {Helmet} from "react-helmet";


export default class SacramentsSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Sacramentos Basílica Menor de Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Sacramentos Basílica Menor de Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="sacramentos, santuario, basilica, coromoto" />
            </Helmet>
            <Header />
            <SacramentsSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Sacramentos</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={logo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header"><h3>Sacramento del Bautismo</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div class="row">
                                                            <div className="col-12">
                                                                <article>
                                                                    <p>El santo Bautismo es el fundamento de&nbsp;toda la vida cristiana, el p&oacute;rtico de la vida en el&nbsp;esp&iacute;ritu (&quot;vitae spiritualis ianua&quot;) y la puerta que abre&nbsp;el acceso a los otros sacramentos. Por el Bautismo somos&nbsp;liberados del pecado y regenerados como hijos de Dios,&nbsp;llegamos a ser miembros de Cristo y somos incorporados a&nbsp;la Iglesia y hechos part&iacute;cipes de su misi&oacute;n.</p>
                                                                    <p>Este sacramento recibe el nombre de&nbsp; Bautismo en raz&oacute;n del car&aacute;cter del rito central mediante&nbsp; el que se celebra: bautizar (baptizein en griego)&nbsp;significa &quot;sumergir&quot;, &quot;introducir dentro del agua&quot;; la&nbsp;&quot;inmersi&oacute;n&quot; en el agua simboliza el acto de sepultar al&nbsp;catec&uacute;meno en la muerte de Cristo, de donde sale por la&nbsp;resurrecci&oacute;n con &Eacute;l (cf Rm 6,3-4; Col 2,12) como &quot;nueva&nbsp;criatura&quot; (2 Co 5,17; Ga 6,15).</p>
                                                                    <p><strong>Requisitos para el Sacramento del Ba</strong><strong>utismo</strong></p>
                                                                    <p>&nbsp; &nbsp; &nbsp;Los Bautizos se realizan todos los s&aacute;bados del mes a las 08:30 am. (Con previa inscripci&oacute;n en las oficinas correspondientes. El tr&aacute;mite de documentos se realizar&aacute; de lunes a jueves de 08:00 am a 12:00 m. y de 01:00 pm. A 4:30 pm).</p>
                                                                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fotocopia de la Partida de Nacimiento o certificado del Registro Civil.</p>
                                                                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fotocopia de la C&eacute;dula de identidad de los Padres y Padrinos.</p>
                                                                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Constancia de charla pre-bautismal.</p>
                                                                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Colaboraci&oacute;n: Esta queda a criterio de los padres y padrinos, los cuales con generosidad hacen su aporte para el sostenimiento de la Iglesia.</p>
                                                                    <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Los ni&ntilde;os mayores de 7 a&ntilde;os deben traer constancia del curso de catequesis con m&iacute;nimo un a&ntilde;o de preparaci&oacute;n.</p>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header"><h3>Sacramento del Matrimonio</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div class="row">
                                                            <div className="col-12">
                                                                <article>
                                                                    <p>&quot;La alianza matrimonial, por la que el&nbsp;var&oacute;n y la mujer constituyen entre s&iacute; un consorcio de&nbsp;toda la vida, ordenado por su misma &iacute;ndole natural al&nbsp;bien de los c&oacute;nyuges y a la generaci&oacute;n y educaci&oacute;n de la&nbsp; prole, fue elevada por Cristo Nuestro Se&ntilde;or a la dignidad&nbsp;de sacramento entre bautizados&quot;.</p>
                                                                    <p><strong>Requisitos para el Sacramento del Matrimonio&nbsp; </strong></p>
                                                                    <p>&nbsp; &nbsp;El Sacramento del Matrimonio se realiza previa apertura del expediente matrimonial (m&iacute;nimo con tres meses de anticipaci&oacute;n). Con los requisitos correspondientes (ambos contrayentes).</p>
                                                                    <ul>
                                                                        <li>Copia de c&eacute;dula de identidad.</li>
                                                                        <li>Fe de bautismo.</li>
                                                                        <li>Constancia de confirmaci&oacute;n.</li>
                                                                        <li>Partida de nacimiento original.</li>
                                                                        <li>Una fotograf&iacute;a tipo carnet.</li>
                                                                        <li>Copia de C&eacute;dula de identidad de los testigos.</li>
                                                                        <li>Constancia de curso pre-matrimonial.</li>
                                                                        <li>Acta de matrimonio civil.</li>
                                                                        <li>Si los contrayentes pertenecen a otros Estados del Pa&iacute;s, estos deben abrir el expediente matrimonial en sus respectivas Di&oacute;cesis y remitirlo con la autorizaci&oacute;n correspondiente.&nbsp;</li>
                                                                    </ul>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                     
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}