/**
 * DiocesanSeminary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 14/04/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';

export default class DiocesanSeminary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
            <title>Seminario Diocesano Nuestra Se&ntilde;ora de Coromoto</title>
            <meta name="description" content="Seminario Diocesano Nuestra Se&ntilde;ora de Coromoto" />
            <meta name="keywords" content="seminario, diocesano, coromoto" />
        </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Diocesan Seminary Our Lady of Coromoto</title>
                <meta name="description" content="Diocesan Seminary Our Lady of Coromoto" />
                <meta name="keywords" content="diocesan, seminary, coromoto" />
            </Helmet>;
        }

        let img_big = "img/seminary/seminario-santuario.jpg";
        let img_medium = "img/seminary/seminario-santuario-medium.jpg";
        let img_small = "img/seminary/seminario-santuario-small.jpg";
        
        return (<div>
             {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />          
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>DiocesanSeminaryLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-xl-12 d-lg-none d-md-none d-sm-none d-none d-lg-none d-xl-block" align="center">
                                        <img src={img_big} className="img-thumbnail"/>
                                    </div> 

                                    <div className="col-lg-12 d-xl-none d-md-none d-sm-none d-none d-lg-block" align="center">
                                        <img src={img_medium} className="img-thumbnail"/>
                                    </div>    

                                    <div className="col-md-12 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                                        <img src={img_medium} className="img-thumbnail"/>
                                    </div> 

                                     <div className="col-sm-12 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                                        <img src={img_small} className="img-thumbnail"/>
                                    </div>  

                                     <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block" align="center">
                                        <img src={img_small} className="img-thumbnail"/>
                                    </div>                                   
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{"text-align": "justify"}}><Trans>DiocesanSeminaryP1</Trans></p>
                                            <p style={{"text-align": "justify"}}><Trans>DiocesanSeminaryP2</Trans></p>
                                            <p style={{"text-align": "justify"}}><Trans>DiocesanSeminaryP3</Trans></p>
                                            <p style={{"text-align": "justify"}}><Trans>DiocesanSeminaryP4</Trans></p>                                            
                                        </article>
                                    </div>
                                </div>
                              
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
            </div>
            <Footer />
        </div>);
    }

}