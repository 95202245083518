/**
 * Pastoral Letter Finding Holy Relic 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 18/04/2021
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import logo from '../img/SANTA_RELIQUIA.png';
import logo2 from '../img/SANTA_RELIQUIA_280.png';
import {Helmet} from "react-helmet";
import PastoralLetterSlider from "./PastoralLetterSlider";
import holyRelic1 from '../img/gallery-holy-relic/santa-reliquia.jpg';
import holyRelic2 from '../img/gallery-holy-relic/santa-reliquia-800.jpg';
import holyRelic3 from '../img/santa-reliquia-virgen-coromoto.jpg';
import holyRelic4 from '../img/santa-reliquia-restaurada-virgen-coromoto.jpg';
import { Trans } from '@lingui/macro';


export default class PastoralLetter extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("pastoral-letter-holy-relic-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Carta Pastoral Hallazgos Santa Reliquia Virgen de Coromoto</title>
                <meta name="description" content="Carta Pastoral Hallazgos Santa Reliquia Virgen de Coromoto" />
                <meta name="keywords" content="carta, pastoral, santa, reliquia, coromoto" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Pastoral Letter Findings Holy Relic Our Lady of Coromoto</title>
                <meta name="description" content="Pastoral Letter Findings Holy Relic Our Lady of Coromoto" />
                <meta name="keywords" content="pastoral, letter, holy, relic, coromoto" />
            </Helmet>;
        }

        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PastoralLetterSlider />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><strong><Trans>PastoralLetterLb</Trans></strong></div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={logo2} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP1</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP2</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP3</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP4</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP5</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP6</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP7</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP8</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP9</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP10</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP11</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP12</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP13</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP14</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP15</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP16</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP17</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP18</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP19</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP20</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>PastoralLetterP21</Trans></p>

                                            <p style={{ "text-align": "center" }}><span style={{"font-size":"14px"}}><strong><Trans>PastoralLetterP22</Trans></strong></span></p>

                                            <p style={{ "text-align": "center" }}><span style={{"font-size":"14px"}}><strong><Trans>PastoralLetterP23</Trans></strong></span></p>

                                            <p>Pbro. Dennis Ter&aacute;n</p>

                                            <p>Vice-Canciller</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="col-md-12 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                            <img src={holyRelic1} className="img-thumbnail"
                                                title="Santa Reliquia Virgen de Coromoto" alt="Santa Reliquia Virgen de Coromoto" />
                                        </div>

                                        <div className="col-md-12 d-none d-sm-none d-xl-none d-lg-none d-md-block" align="center">
                                            <img src={holyRelic2}  className="img-thumbnail"
                                                title="Santa Reliquia Virgen de Coromoto" alt="Santa Reliquia Virgen de Coromoto" />
                                        </div>                                        

                                    </div>
                                </div>

                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                        <img src={holyRelic3}
                                            title="Santa Reliquia Virgen de Coromoto" alt="Santa Reliquia Virgen de Coromoto" width="350" height="500"/>
                                    </div>

                                    <div className="col-md-6 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                        <img src={holyRelic4}
                                            title="Santa Reliquia Virgen de Coromoto" alt="Santa Reliquia Virgen de Coromoto" width="350" height="500"/>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}