/**
 * SantuaryLink 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 02/04/2020
 */



export default class RosaryElement {

    constructor(url_image, url_sound, elementText, prayer) {
        this.url_image = url_image;
        this.url_sound = url_sound;
        this.elementText = elementText;      
        this.prayer = prayer;
    }

    getURL_Image() {
        return this.url_image;
    }


    getURL_Sound() {
        return this.url_sound;
    }

    getElementText() {
        return this.elementText;
    }

    getPrayer() {
        return this.prayer;
    }

}