/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 28/03/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import {Helmet} from "react-helmet";
import holyRelic1 from '../img/20151105_122411_2_small_xs.jpg';
import holyRelic2 from '../img/20151105_122411_850.jpg';
import logo from '../img/logoSN_ConociendoSantaReliquia.png';
import logo_small from '../img/logoSN_ConociendoSantaReliquia_300.png';
import jQuery from 'jquery';
import $ from 'jquery';
import '../css/tiksluslens.css';
import '../js/tiksluslens.js';

export default class KnowingHolyRelicIntro extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        $(document).ready(function () {
            $("#example1").tiksluslens({ lensWidth: 80, lensHeight: 80 });
        });

        if (sessionStorage.getItem('language') !== null) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.setState({ lang: sessionStorage.getItem('language') });
                sessionStorage.setItem('language', sessionStorage.getItem('language'));
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));
            }
        }
    }


    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Santa Reliquia de Nuestra Se&ntilde;ora de Coromoto</title>
                    <meta name="description" content="Santa Reliquia Virgen de Coromoto" />
                    <meta name="keywords" content="reliquia, santa reliquia, virgen, coromoto" />
                </Helmet>
                <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" style={{'marginTop': '173px'}} align="center"><img src={logo} /></div>
                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" style={{'marginTop': '80px'}} align="center"><img src={logo_small} /></div>
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                <div id="example1" align="center">
                    <img src={holyRelic1} data-big-image={holyRelic2} className="tiksluslens" />
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-12" align="center"><h2><a href="ver-santa-reliquia-virgen-coromoto.html" className="transition">Iniciar</a></h2></div>
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                <Footer />
            </div>
        );
    }

}