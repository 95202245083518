import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nProvider } from '@lingui/react';
import catalogES from './locales/es/messages.js'
import catalogEN from './locales/en/messages.js'



function LocalizedApp() {
    const [setCatalogs] = useState({});
    const catalogs = { es: catalogES, en: catalogEN };
    const [language, setLanguage] = useState('es');
        
    async function handleLanguageChange(language) {        
      setLanguage(language);
    }
     
    return (
      <I18nProvider language={language} catalogs={catalogs}>
        <App
          language={language}
          onLanguageChange={handleLanguageChange}
        />
      </I18nProvider>
    );
}
  

ReactDOM.render(<LocalizedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
