/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 11/10/2021
 */

 import React, { Component } from 'react';
 import Header from './header';
 import PrayersSanctuarySliders from './prayersSanctuarySliders';
 import Footer from './footer';
 import {Helmet} from "react-helmet";
 import { Trans } from '@lingui/macro';
 import saint_joseph from '../img/san-jose.jpg';
 import { Link } from 'react-router-dom';


 export default class SaintJoseph extends Component {
 
     constructor(props) {
         super(props);
         let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("prayers-saint-joseph.html") ? "en" : "es");
         let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
         if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
             this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
         }
         else {
             this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
         }
         this.refreshLanguage = this.refreshLanguage.bind(this);    
         this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
     }
 
     componentDidMount() {
         if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
             if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
             }
         }        
     }
 
     invokeChangeLanguage(language) {
         if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
             this.setState({ lang: language });         
             sessionStorage.setItem('language', language);        
             this.state.eventChangeLanguage(language);            
         }
     }
 
     refreshLanguage(value) {            
         if (value !== null && value !== '') {
             this.setState({ lang: value });                    
         }
     }

 
     render() {
 
         let helmetComp;
         if (this.state.lang === 'es') {
             //seo 
             helmetComp = <Helmet>
                 <title>Oraciones a San Jos&eacute;</title>
                 <meta name="description" content="Oraciones a San José" />
                 <meta name="keywords" content="oraciones, josé, san, jose" />
             </Helmet>;
         }
         else {
             //seo 
             helmetComp = <Helmet>
                 <title>Prayers to Saint Joseph</title>
                 <meta name="description" content="Prayers to Saint Joseph" />
                 <meta name="keywords" content="saint, joseph" />
             </Helmet>;
         }
 
 
         return (<div>
             {helmetComp}
             <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
             <PrayersSanctuarySliders />
             <div className="container">
                 <div className="row">
                     <div className="col-12">&nbsp;</div>
                 </div>
 
                 <div className="row">
                     <div className="col-12">
                         <div className="card">
                             <div className="card-header"><strong><Trans>SaintJosephTitle</Trans></strong></div>
                             <div className="card-body">
                                 <div className="row">
                                     <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                         <img src={saint_joseph} className="img-thumbnail" />
                                     </div>
                                     <div className="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                         <img src={saint_joseph} className="img-thumbnail" />
                                     </div>
                                 </div>
                                 <div className="row">
                                     <div className="col-12">&nbsp;</div>
                                 </div>
                                 <div className="row">
                                     <div className="col-12">
                                         <article>
                                             <p style={{ "text-align": "justify" }}><Trans>SaintJosephP1</Trans></p>

                                             <p style={{ "text-align": "justify" }}><b><Trans>SaintJosephP2Title</Trans></b></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>SaintJosephP2</Trans></p>

                                             <p style={{ "text-align": "justify" }}><b><Trans>SaintJosephP3Title</Trans></b></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>SaintJosephP3</Trans></p>

                                             <p style={{ "text-align": "justify" }}><b><Trans>SaintJosephP4Title</Trans></b></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>SaintJosephP4</Trans></p>

                                             <p style={{ "text-align": "justify" }}><b><Trans>SaintJosephP5Title</Trans></b></p>
 
                                             <p style={{ "text-align": "justify" }}><Trans>SaintJosephP5</Trans></p>
                                                                                                                                                                                                    
                                             <p></p>                                             
                                         </article>
                                     </div>
                                 </div>
                                 <div className="row">
                                    <div className="col-12">
                                        <Link to="prayersFiles/Oraciones-San-Jose.pdf"  target="_blank" onClick={this.trackPDF}><Trans>SaintJosephTitle</Trans> (descargar PDF)</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center">&nbsp;</div>
                                </div>
 
                                 <div className="row">
                                     <div className="col-12">Bibliograf&iacute;a: http://www.vatican.va/roman_curia/tribunals/apost_penit/documents/rc_trib_appen_pro_20201208_decreto-indulgenze-sangiuseppe_sp.html</div>
                                 </div>
 
                                 <div className="row">
                                    <div className="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                                </div>
                               
                             </div>
                         </div>                        
                     </div>
                 </div>
 
                 <div className="row">
                     <div className="col-12">&nbsp;</div>
                 </div>
 
                 
 
 
             </div>
             <Footer />
         </div>);
     }
 
 }