/**
 * NectarioMariaBiography
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 12/10/2021
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import { Trans } from '@lingui/macro';
import hnoNectarioMaria1 from '../img/nectariomaria/hn.jpg';
import hnoNectarioMaria2 from '../img/nectariomaria/hermanonectariomaria.jpg';
import hnoNectarioMaria3 from '../img/nectariomaria/nectario-3009collage1.jpg';

 
 export default class NectarioMariaBiography extends Component {
 
     constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("brother-nectario-maria.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
     }
 
     componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }


    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Hermano Nectario Mar&iacute;a (1888-1986)</title>
                <meta name="description" content="Hermano Nectario Mar&iacute;a (1888-1986)" />
                <meta name="keywords" content="hermano, nectario, maria" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Brother Nectario Mar&iacute;a (1888-1986)</title>
                <meta name="description" content="Brother Nectario Mar&iacute;a (1888-1986)" />
                <meta name="keywords" content="brother, nectario, maria" />
            </Helmet>;
        }

        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            
            <div className="container">

                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><strong><Trans>NectarioMariaBiographyLb</Trans></strong></div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={hnoNectarioMaria1} className="img-thumbnail"/>
                                    </div>
                                    <div className="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={hnoNectarioMaria2} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP1</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP2</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP3</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP4</Trans></p>                                         

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP5</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP6</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP7</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP8</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP9</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP10</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP11</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP12</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>NectarioMariaBiographyP13</Trans></p>

                                            <p style={{ "text-align": "justify" }}><b><Trans>NectarioMariaBiographyP14</Trans></b></p>  

                                            <p style={{ "text-align": "justify" }}>https://basilicacatedralguanare.wordpress.com/el-hno-nectario-maria/</p>   
                                            <p style={{ "text-align": "justify" }}>https://bibliofep.fundacionempresaspolar.org/dhv/entradas/p/prationg-bonicel-luis-alfredo/</p>
                                            <p style={{ "text-align": "justify" }}>https://issuu.com/simongil/docs/rese__a_bibliografica_del_hermano_n</p>                              


                                        </article>
                                    </div>
                                </div>
                                                               
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }
 
 }