/**
 * BishopGuanare 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 18/04/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import rectorImg from '../img/padreRectorAllender.jpg';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';

export default class RectorSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("rector-sanctuary-our-lady-of-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp =  <Helmet>
            <title>Presb&Iacute;tero Allender Hern&aacute;ndez Rector Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</title>
            <meta name="description" content="Presbitero Allender Hern&aacute;ndez Rector Santuario Nacional Nuestra Se&ntilde;ora de Coromoto" />
            <meta name="keywords" content="rector, santuario, nacional, basilica, coromoto" />
        </Helmet>;
        }
        else {
            //seo 
            helmetComp =  <Helmet>
            <title>Presbyter Allender Hernández Rector Our Lady of Coromoto National Sanctuary</title>
            <meta name="description" content="Presbyter Allender Hernández Rector Our Lady of Coromoto National Sanctuary" />
            <meta name="keywords" content="rector, Presbyter, sanctuary, national, basilica, coromoto" />
        </Helmet>;
        }

        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />          
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>RectorSanctuaryPageLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={rectorImg} className="img-thumbnail" alt="Allender Hernandez"/>
                                    </div>               
                                    <div className="col-12">
                                        <article>
                                            <p style={{"text-align": "justify"}}><Trans>RectorSanctuaryP1Lb</Trans></p>
                                            <p style={{"text-align": "justify"}}><Trans>RectorSanctuaryP2Lb</Trans></p>
                                            <p style={{"text-align": "justify"}}><Trans>RectorSanctuaryP3Lb</Trans></p>
                                        </article>
                                    </div>                                   
                                </div>                                
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
            </div>
            <Footer />
        </div>);
    }

}