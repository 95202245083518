/**
 * TimtableSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import DownloadMultimediaSliders from './downloadMultimediaSliders';
import Footer from './footer';
import SantuaryLink from './santuaryLink';
import MainRowBodyContentXL from './mainRowBodyContentXL';
import logo from '../img/Title_Multimedia_Download_es.jpg';
import {Helmet} from "react-helmet";
import MainRowBodyContentLG from './mainRowBodyContentLG';
import MainRowBodyContentSM from './mainRowBodyContentSM';
import MainRowBodyContentEXSM from './mainRowBodyContentEXSM';

/**import images */

import row0col1Img from '../img/download_multimedia/virgen-coromoto-coronacion-small.jpg';
import row0col2Img from '../img/download_multimedia/VirgenCoromotoRelicario-Hallazgos-small.jpg';
import row0col3Img from '../img/download_multimedia/virgen-coromoto-small.jpg';
import row0col4Img from '../img/download_multimedia/afiche-coromoto-369-anos-small.jpg';
import row0col5Img from '../img/download_multimedia/VirgenCoromotoRelicario-small.jpg';
import row0Img1Obj from '../img/download_multimedia/virgen-coromoto-coronacion.jpg';
import row0Img2Obj from '../img/download_multimedia/VirgenCoromotoRelicario-Hallazgos.jpg';
import row0Img3Obj from '../img/download_multimedia/virgen-coromoto.jpg';
import row0Img4Obj from '../img/download_multimedia/afiche-coromoto-369-anos-791x1024.jpg';
import row0Img5Obj from '../img/download_multimedia/VirgenCoromotoRelicario.jpg';

import row1col1Img from '../img/download_multimedia/santa-reliquia-virgen-coromoto-altar-small.jpg';
import row1col2Img from '../img/download_multimedia/santa-reliquia-coromoto-small.jpg';
import row1col3Img from '../img/download_multimedia/santa-reliquia-virgen-coromoto-small.jpg';
import row1col4Img from '../img/download_multimedia/santa-reliquia-virgen-coromoto-custodia-small.jpg';
import row1Img1Obj from '../img/download_multimedia/santa-reliquia-virgen-coromoto-altar.jpg';
import row1Img2Obj from '../img/download_multimedia/santa-reliquia-coromoto.jpg';
import row1Img3Obj from '../img/download_multimedia/santa-reliquia-virgen-coromoto.jpg';
import row1Img4Obj from '../img/download_multimedia/santa-reliquia-virgen-coromoto-custodia.jpg';

import row2col1Img from '../img/download_multimedia/Virgen_Coromoto_small.jpg';
import row2col2Img from '../img/download_multimedia/Pendon_Hallazgos _small.jpg';
import row2col3Img from '../img/download_multimedia/Estampa_VirgenCoromoto_small.jpg';
import row2col4Img from '../img/download_multimedia/SANTA_RELIQUIA_ORIGINAL_small.jpg';
import row2Img1Obj from '../img/download_multimedia/Virgen_Coromoto.jpg';
import row2Img2Obj from '../img/download_multimedia/Pendon_Hallazgos.jpg';
import row2Img3Obj from '../img/download_multimedia/Estampa_VirgenCoromoto.jpg';
import row2Img4Obj from '../img/download_multimedia/SANTA_RELIQUIA_ORIGINAL.jpg';

import row3col1Img from '../img/download_multimedia/PortalWeb_small.jpg';
import row3col2Img from '../img/download_multimedia/Santísimo_Sacramento_small.jpg';
import row3col3Img from '../img/download_multimedia/Vigen_Coromoto_Altar_Mayor_small.jpg';
import row3col4Img from '../img/download_multimedia/Plaza_Coronación_small.jpg';
import row3Img1Obj from '../img/download_multimedia/PortalWeb.jpg';
import row3Img2Obj from '../img/download_multimedia/Santísimo_Sacramento.jpg';
import row3Img3Obj from '../img/download_multimedia/Vigen_Coromoto_Altar_Mayor.jpg';
import row3Img4Obj from '../img/download_multimedia/Plaza_Coronación.jpg';

export default class MultimediaSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("download-multimedia-our-lady-of-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Descargas Multimedia Bas&iacute;lica Menor Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Descargas Multimedia de la Virgen de Coromoto, afiches, imágenes, fondos de escritorio" />
                <meta name="keywords" content="galería, imagenes, coromoto, santuario, multimedia, descargas" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Download Multimedia Sanctuary Our Lady of Coromoto</title>
                <meta name="description" content="Download Multimedia Sanctuary Our Lady of Coromoto" />
                <meta name="keywords" content="multimedia, download, sanctuary, lady, coromoto" />
            </Helmet>;
        }

        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <DownloadMultimediaSliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12 d-md-none d-sm-none d-none d-lg-block d-xl-block"><img src={logo}/></div>
                </div>

                <MainRowBodyContentXL
                    img1={row0col1Img}
                    img2={row0col2Img}
                    img3={row0col3Img}
                    img4={row0col4Img}
                    link1={new SantuaryLink(null, 'Virgen Santísima María de Coromoto Campo Coronación (11/09/2021)')}
                    link2={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto (tabloide)')}
                    link3={new SantuaryLink(null, 'Virgen Santísima María de Coromoto')}
                    link4={new SantuaryLink(null, 'Afiche 369 años de la Aparición (08/09/2021)')} 
                    img1obj={row0Img1Obj}
                    img2obj={row0Img2Obj}
                    img3obj={row0Img3Obj}
                    img4obj={row0Img4Obj} />

                <div className="row"><div className="col-12 d-md-none d-sm-none d-none d-lg-block d-xl-block">&nbsp;</div></div>

                <MainRowBodyContentXL
                    img1={row1col1Img}
                    img2={row1col2Img}
                    img3={row1col3Img}
                    img4={row1col4Img}
                    link1={new SantuaryLink(null, 'Santa Reliquia Virgen Coromoto Altar Mayor')}
                    link2={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto')}
                    link3={new SantuaryLink(null, 'Trono de la Virgen de Coromoto')}
                    link4={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto Custodia')} 
                    img1obj={row1Img1Obj}
                    img2obj={row1Img2Obj}
                    img3obj={row1Img3Obj}
                    img4obj={row1Img4Obj} />

                <div className="row"><div className="col-12 d-md-none d-sm-none d-none d-lg-block d-xl-block">&nbsp;</div></div>

                <MainRowBodyContentXL
                    img1={row2col1Img}
                    img2={row2col2Img}
                    img3={row2col3Img}
                    img4={row2col4Img}
                    link1={new SantuaryLink(null, 'Virgen de Coromoto Hallazgos Santa Reliquia (Resolución 3217x4941 pixels, 300 ppp, 27cm ancho x 42cm alto)')}
                    link2={new SantuaryLink(null, 'Pendon Hallazgos Santa Reliquia Virgen de Coromoto (Resolución 4724x7913 pixels, 300 ppp, 40cm ancho x 67cm alto)')}
                    link3={new SantuaryLink(null, 'Virgen de Coromoto Patrona de Venezuela (Resolución 1020x1625, 96ppp, 26cm ancho x 43cm alto)')}
                    link4={new SantuaryLink(null, 'Estampas de la Santa Reliquia Virgen Coromoto (Resolución 3217x5001 pixels, 300 ppp, 27cm ancho x 43cm alto)')} 
                    img1obj={row2Img1Obj}
                    img2obj={row2Img2Obj}
                    img3obj={row2Img3Obj}
                    img4obj={row2Img4Obj} />

                <div className="row"><div className="col-12 d-md-none d-sm-none d-none d-lg-block d-xl-block">&nbsp;</div></div>

                <MainRowBodyContentXL
                    img1={row3col1Img}
                    img2={row3col2Img}
                    img3={row3col3Img}
                    img4={row3col4Img}
                    link1={new SantuaryLink(null, 'Portal Web de la Basílica Menor Santuario Nacional de Nuestra  Se&ntilde;ora de Coromoto Patrona de Venezuela')}
                    link2={new SantuaryLink(null, 'Santísimo Sacramento')}
                    link3={new SantuaryLink(null, 'Nuestra Se&ntilde;ora de Coromoto Altar Mayor')}
                    link4={new SantuaryLink(null, 'Virgen de Coromoto Plaza la Coronación')} 
                    img1obj={row3Img1Obj}
                    img2obj={row3Img2Obj}
                    img3obj={row3Img3Obj}
                    img4obj={row3Img4Obj} />
                

                <MainRowBodyContentLG
                    img1={row0col1Img}
                    img2={row0col2Img}
                    img3={row0col3Img}
                    link1={new SantuaryLink(null, 'Virgen Santísima María de Coromoto Campo Coronación (11/09/2021)')}
                    link2={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto (tabloide)')}
                    link3={new SantuaryLink(null, 'Virgen Santísima María de Coromoto')}
                    img1obj={row0Img1Obj}
                    img2obj={row0Img2Obj}
                    img3obj={row0Img3Obj}
                />


                <MainRowBodyContentLG
                    img1={row1col1Img}
                    img2={row1col2Img}
                    img3={row1col3Img}
                    link1={new SantuaryLink(null, 'Santa Reliquia Virgen Coromoto Altar Mayor')}
                    link2={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto')}
                    link3={new SantuaryLink(null, 'Trono de la Virgen de Coromoto')}
                    img1obj={row1Img1Obj}
                    img2obj={row1Img2Obj}
                    img3obj={row1Img3Obj}
                />

                <div className="row"><div className="col-12 d-md-none d-sm-none d-none d-lg-block d-xl-block">&nbsp;</div></div>

                <MainRowBodyContentLG
                    img1={row1col4Img}
                    img2={row2col1Img}
                    img3={row2col2Img}
                    link1={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto Custodia')}
                    link2={new SantuaryLink(null, 'Virgen de Coromoto Hallazgos Santa Reliquia (Resolución 3217x4941 pixels, 300 ppp, 27cm ancho x 42cm alto)')}
                    link3={new SantuaryLink(null, 'Pendon Hallazgos Santa Reliquia Virgen de Coromoto (Resolución 4724x7913 pixels, 300 ppp, 40cm ancho x 67cm alto)')}
                    img1obj={row1Img4Obj}
                    img2obj={row2Img1Obj}
                    img3obj={row2Img2Obj}
                />

                <div className="row"><div className="col-12 d-md-none d-sm-none d-none d-lg-block d-xl-block">&nbsp;</div></div>

                <MainRowBodyContentLG
                    img1={row2col3Img}
                    img2={row2col4Img}
                    img3={row3col1Img}
                    link1={new SantuaryLink(null, 'Virgen de Coromoto Patrona de Venezuela (Resolución 1020x1625, 96ppp, 26cm ancho x 43cm alto)')}
                    link2={new SantuaryLink(null, 'Estampas de la Santa Reliquia Virgen Coromoto (Resolución 3217x5001 pixels, 300 ppp, 27cm ancho x 43cm alto)')}
                    link3={new SantuaryLink(null, 'Portal Web de la Basílica Menor Santuario Nacional de Nuestra Señora de Coromoto')}
                    img1obj={row2Img3Obj}
                    img2obj={row2Img4Obj}
                    img3obj={row3Img1Obj}
                />

                <MainRowBodyContentSM
                    img1={row0col1Img}
                    img2={row0col2Img}
                    link1={new SantuaryLink(null, 'Virgen Santísima María de Coromoto Campo Coronación (11/09/2021)')}
                    link2={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto (tabloide)')}
                    img1obj={row0Img1Obj}
                    img2obj={row0Img2Obj}
                />

                <MainRowBodyContentSM
                    img1={row0col3Img}
                    img2={row0col4Img}
                    link1={new SantuaryLink(null, 'Virgen Santísima María de Coromoto')}
                    link2={new SantuaryLink(null, 'Afiche 369 años de la Aparición (08/09/2021)')}
                    img1obj={row0Img3Obj}
                    img2obj={row0Img4Obj}
                />

                <MainRowBodyContentSM
                    img1={row1col1Img}
                    img2={row1col2Img}
                    link1={new SantuaryLink(null, 'Santa Reliquia Virgen Coromoto Altar Mayor')}
                    link2={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto')}
                    img1obj={row1Img1Obj}
                    img2obj={row1Img2Obj}
                />

                <MainRowBodyContentSM
                    img1={row1col3Img}
                    img2={row1col4Img}
                    link1={new SantuaryLink(null, 'Trono de la Virgen')}
                    link2={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto Custodia')} 
                    img1obj={row1Img3Obj}
                    img2obj={row1Img4Obj}
                />

                <MainRowBodyContentSM
                    img1={row2col1Img}
                    img2={row2col2Img}
                    link1={new SantuaryLink(null, 'Virgen de Coromoto Hallazgos Santa Reliquia (Resolución 3217x4941 pixels, 300 ppp, 27cm ancho x 42cm alto)')}
                    link2={new SantuaryLink(null, 'Pendon Hallazgos Santa Reliquia Virgen de Coromoto (Resolución 4724x7913 pixels, 300 ppp, 40cm ancho x 67cm alto)')}
                    img1obj={row2Img1Obj}
                    img2obj={row2Img2Obj}
                />

                <MainRowBodyContentSM
                    img1={row2col3Img}
                    img2={row2col4Img}
                    link1={new SantuaryLink(null, 'Virgen de Coromoto Patrona de Venezuela (Resolución 1020x1625, 96ppp, 26cm ancho x 43cm alto)')}
                    link2={new SantuaryLink(null, 'Estampas de la Santa Reliquia Virgen Coromoto (Resolución 3217x5001 pixels, 300 ppp, 27cm ancho x 43cm alto)')} 
                    img1obj={row2Img3Obj}
                    img2obj={row2Img4Obj}
                />

                <MainRowBodyContentEXSM
                    img1={row0col5Img}
                    img1obj={row0Img5Obj}
                    link1={new SantuaryLink(null, 'Santa Reliquia Virgen Coromoto')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row0col1Img}
                    img1obj={row0Img1Obj}
                    link1={new SantuaryLink(null, 'Virgen Santísima María de Coromoto Campo Coronación (11/09/2021)')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row0col2Img}
                    img1obj={row0Img2Obj}
                    link1={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto (tabloide)')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row0col3Img}
                    img1obj={row0Img3Obj}
                    link1={new SantuaryLink(null, 'Virgen Santísima María de Coromoto')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row0col4Img}
                    img1obj={row0Img4Obj}
                    link1={new SantuaryLink(null, 'Afiche 369 años de la Aparición (08/09/2021)')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row1col1Img}
                    img1obj={row1Img1Obj}
                    link1={new SantuaryLink(null, 'Santa Reliquia Virgen Coromoto Altar Mayor')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row1col2Img}
                    img1obj={row1Img2Obj}
                    link1={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row1col3Img}
                    img1obj={row1Img3Obj}
                    link1={new SantuaryLink(null, 'Trono de la Virgen de Coromoto')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row1col4Img}
                    img1obj={row1Img4Obj}
                    link1={new SantuaryLink(null, 'Santa Reliquia Nuestra Se&ntilde;ora de Coromoto Custodia')} 
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row2col1Img}
                    img1obj={row2Img1Obj}
                    link1={new SantuaryLink(null, 'Virgen de Coromoto Hallazgos Santa Reliquia (Resolución 3217x4941 pixels, 300 ppp, 27cm ancho x 42cm alto)')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row2col2Img}
                    img1obj={row2Img2Obj}
                    link1={new SantuaryLink(null, 'Pendon Hallazgos Santa Reliquia Virgen de Coromoto (Resolución 4724x7913 pixels, 300 ppp, 40cm ancho x 67cm alto)')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

               <MainRowBodyContentEXSM
                    img1={row2col3Img}
                    img1obj={row2Img3Obj}
                    link1={new SantuaryLink(null, 'Virgen de Coromoto Patrona de Venezuela (Resolución 1020x1625, 96ppp, 26cm ancho x 43cm alto)')}
                />

                <div className="row">
                        <div className="col-12">&nbsp;</div>
                </div>

                <MainRowBodyContentEXSM
                    img1={row2col4Img}
                    img1obj={row2Img4Obj}
                    link1={new SantuaryLink(null, 'Estampas de la Santa Reliquia Virgen Coromoto replica tamaño original (Resolución (tabloide) 3217px ancho, 5001px alto, 300 ppp, 27cm ancho x 43cm alto)')}
                />

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

            </div>
            <Footer />
        </div>);
    }

}