/**
 * DeclareOurLadyCoromoto 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 06/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import SanctuaryHistorySlider from './sanctuaryHistorySliders';
import Footer from './footer';
import reliquia from '../img/SANTA_RELIQUIA.png';
import {Helmet} from "react-helmet";


export default class DeclareOurLadyCoromoto extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };   
        this.refreshLanguage = this.refreshLanguage.bind(this);  
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Breve Pontificio del Patronato Coromotano</title>
                <meta name="description" content="Breve Pontificio del Patronato Coromotano" />
                <meta name="keywords" content="octubre, 1944, coromoto, virgen" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <SanctuaryHistorySlider />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Breve Pontificio del Patronato Coromotano</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={reliquia} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{"text-align":"justify"}}>Breve Pontificio del Patronato Coromotano, donde el Papa P&iacute;o XII declara a la Sant&iacute;sima Virgen Mar&iacute;a Nuestra Se&ntilde;ora de Coromoto, como CELESTE Y PRINCIPAL PATRONA de Venezuela, en Octubre de 1944.</p>
                                            <p style={{"text-align":"justify"}}>&ldquo;PIO PAPA XII &ndash;Para perpetua memoria- Todos los pueblos que desde los m&aacute;s apartados rincones de la tierra llegaron a la luz de la verdad cristiana, han experimentado la tutela maternal de la Sant&iacute;sima Virgen Mar&iacute;a y, en acci&oacute;n de gracias por los beneficios de Ella recibidos, le levantan f&eacute;rvido y solemne himno entonado &ldquo;por todas las tribus, lenguas, pueblos y naciones&rdquo;. No de otra suerte acaece entre los fieles cristianos, nacionales o europeos, que, de todas partes de Venezuela, numerosos acuden con piedad al Santuario dedicado a Dios en honor de la Divina Madre &ldquo;de Coromoto&rdquo;, en el lugar llamado &ldquo;Guanare de los Cospes&rdquo;. Este amor de la Virgen en realidad esclareci&oacute; las inteligencias de los nacionales, elev&oacute; sus corazones hacia el cielo y los alivio en sus adversidades tanto p&uacute;blicas como privadas, desde el momento en que, seg&uacute;n&nbsp; la tradici&oacute;n, la misma Madre de Dios apareci&oacute; en el propio lugar en donde luego se levant&oacute; un Templo, que pronto fue como el Trono de la misericordia y gracia Divinas.</p>
                                            <p style={{"text-align":"justify"}}>Por tanto, la Fe de los Venezolanos en Cristo Se&ntilde;or, que los Espa&ntilde;oles, sobre todo los Padre Predicadores de la Orden de Santo Domingo, llevaron all&aacute; el siglo XVI y que los Romanos Pont&iacute;fices, atentos a la dilataci&oacute;n del Reino de Cristo, pr&oacute;vidamente fomentaron por medio de la instituci&oacute;n can&oacute;nica en aquel tiempo de la Sagrada Jerarqu&iacute;a y de la primera C&aacute;tedra Episcopal, esa Fe, gracias principalmente a la fuerza y auxilio de una encendida devoci&oacute;n a la Sant&iacute;sima Virgen Mar&iacute;a &ldquo;de Coromoto&rdquo;, fue creciendo cada d&iacute;a m&aacute;s y permaneci&oacute; incontaminada. Por esto, todos los Obispos de Venezuela y a la cabeza el Venerable Hermano Lucas Guillermo Castillo, Arzobispo titular de Rizeo y Coadjutor de Caracas, a nombre tambi&eacute;n de los sacerdotes y de todos los fieles, el a&ntilde;o de 1942, declararon a la misma Divina Madre &ldquo;de Coromoto&rdquo;, Patrona de toda la Republica y luego nos pidieron encarecidamente que ratific&aacute;ramos y confirm&aacute;ramos su declaraci&oacute;n. Y como quiera que, a ejemplo de Nuestros Predecesores, es de trascendental importancia para nuestro paternal coraz&oacute;n el que, a mayor gloria de Dios y para provecho espiritual, se aumente cada vez la piedad mariana, hemos gustosamente resuelto acceder benignamente a estos ruegos y votos de los Obispos, de uno y otro Clero, de la sociedad y de todo el pueblo de Venezuela.</p>
                                            <p style={{"text-align":"justify"}}>Por consiguiente, secundando los deseos del mencionado Arzobispo, despu&eacute;s de haber o&iacute;do tambi&eacute;n a Nuestro Venerable Hermano Carlos Salotti, Obispo de Palestrina, Cardenal de la Santa Iglesia Romana, Prefecto de la Sagrada Congregaci&oacute;n de los Ritos,&nbsp; con pleno conocimiento Nuestro y madura deliberaci&oacute;n, por la plenitud de Nuestra Apost&oacute;lica potestad, en virtud de las presentes Letras y a perpetuidad: confirmamos, constituimos y declaramos a la Sant&iacute;sima Virgen Mar&iacute;a de Coromoto&rdquo; en &ldquo;Guanare de los Cospes&rdquo; CELESTE Y PRINCIPAL PATRONA de toda la Rep&uacute;blica de Venezuela, con todos y cada unos de los privilegios lit&uacute;rgicos que competen a tales principales Patronas. No debiendo en ninguna manera obstar nada en contrario. Esto declaramos y estatuimos, decretando que las presentes Letras sean y permanezcan firmes, v&aacute;lidas y eficaces para siempre; y que ellas surten y obtienen todos sus plenos e &iacute;ntegros efectos; y que ellas dan fe ahora y siempre plenamente a quienes competa o competir pudiere; y que as&iacute; se ha de juzgar y definir; y que ser&aacute; &iacute;rrito y vacuo cuanto desde ahora alguien, con cualquier autoridad, consciente o ignorantemente, atentare hacer distinto acerca de esto. Dado en Roma, en San Pedro, bajo el anillo del Pescador, el d&iacute;a del mes de octubre, el a&ntilde;o de 1944, nono de Nuestro Pontificado. &ndash;Por mandato especial del Sant&iacute;simo-.</p>
                                            <p style={{"text-align":"justify"}}>Por el Se&ntilde;or Cardenal Secretario de Estado (firmado) DOMINGO SPADA, Canciller de los Breves Apost&oacute;licos&rdquo;.</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}