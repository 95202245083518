/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import DonationsSanctuarySliders from './donationsSanctuarySliders';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import {Helmet} from "react-helmet";


export default class DonationsSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("donations-sanctuary-our-lady-of-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Donaciones Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Donaciones Santuario Nacional Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="donaciones, santuario, virgen, coromoto, patrona, Venezuela" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Donations Sanctuary National Our Lady of Coromoto</title>
                <meta name="description" content="Donations Sanctuary National Our Lady of Coromoto" />
                <meta name="keywords" content="donations, sanctuary, coromoto" />
            </Helmet>;
        }


        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <DonationsSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Donaciones</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12" align="center">
                                        <img src={logo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>El Santuario Nacional de Nuestra Se&ntilde;ora de Coromoto se mantiene con las donaciones que usted realiza. Agradecemos su&nbsp;colaboraci&oacute;n&nbsp;Que Dios le&nbsp;Bendiga.&nbsp;</span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>En caso de ofrecer un donativo o ayuda a la Bas&iacute;lica Menor Santuario Nacional Nuestra Se&ntilde;ora de Coromoto, favor entregarlo al Rector, en las Oficinas Administrativas o realizar sus dep&oacute;sitos en:</span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"18px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong>Banco Banesco </strong></span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>Cuenta Corriente N. 0134-0408-93-4081044283 </span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>A&nbsp;nombre de: Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>RIF: J-30641199-2</span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"18px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong>Banco de Venezuela</strong></span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>Cuenta: 0102-0346-56-0000499051 </span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>A&nbsp;nombre de: Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}>RIF: J-30641199-2</span></span></p>
                                            <p style={{"text-align": "justify"}}><span style={{"font-size":"16px"}}><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong>Para mayor informaci&oacute;n puede comunicarse con nosotros por los t&eacute;lefonos: 0426-1731521</strong></span></span></p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}