/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import DonationsSanctuarySliders from './donationsSanctuarySliders';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router-dom';

export default class NotFoundPage extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        let redirectProp = this.props.location.pathname.includes("/en/") || this.props.location.pathname.includes("/index.html") ? true : false;
        let redirectToProp = this.props.location.pathname.includes("/en/") ? "/"+this.props.location.pathname.replace("/en/", "") : this.props.location.pathname.replace("/index.html", "");    
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange, redirect:redirectProp, redirectTo:redirectToProp };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange,redirect:redirectProp, redirectTo:redirectToProp };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        if(this.state.redirect) {
            return <Redirect to={this.state.redirectTo} />
        }
        else {
            return (<div>
                <Helmet>
                     <title>P&aacute;gina no encontrada</title>
                     <meta name="description" content="P&aacute;gina no encontrada" />                
                     <meta name="robots" content="noindex" />
                 </Helmet>
                 <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
                 <DonationsSanctuarySliders />
                 <div className="container">
                     <div className="row">
                         <div className="col-12">&nbsp;</div>
                     </div>
                     <div className="row">
                         <div className="col-12" align="center">
                             <img src={logo} />
                         </div>
                     </div>
                     <div className="row">
                         <div className="col-12"  align="center"><h1>P&aacute;gina no encontrada</h1></div>
                     </div>
                     <div className="row">
                         <div className="col-12">&nbsp;</div>
                     </div>
                 </div>
                 <Footer />
             </div>);
        }        
    }

}