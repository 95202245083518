/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 28/03/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";
import holyRelic1 from '../img/SantaReliquiaOriginalDetalle.jpg';

import logo from '../img/logoSN_ConociendoSantaReliquia.png';
import logo_small from '../img/logoSN_ConociendoSantaReliquia_300.png';
import finding1 from '../img/KnowingHolyRelic1.jpg';
import finding2 from '../img/KnowingHolyRelic2.jpg';
import finding3 from '../img/KnowingHolyRelic3.jpg';
import finding4 from '../img/KnowingHolyRelic4.jpg';


export default class KnowingHolyRelicDetail extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }


    render() {
        return (
            <div>
                <Helmet>
                    <title>Santa Reliquia de Nuestra Se&ntilde;ora de Coromoto</title>
                    <meta name="description" content="Santa Reliquia Virgen de Coromoto" />
                    <meta name="keywords" content="reliquia, santa reliquia, virgen, coromoto" />
                </Helmet>
                <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />

                <div className="container">
                    <div className="row">
                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" style={{'marginTop': '173px'}} align="center">&nbsp;</div>
                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" style={{'marginTop': '80px'}} align="center">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center"><img src={logo} /></div>
                        <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center"><img src={logo_small} /></div>
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row" align="center">                    
                        <div className="col-md-6 d-none d-sm-none d-md-block d-xl-block d-lg-block">
                            <img src={finding3} />
                        </div>
                        <div className="col-md-6 d-none d-sm-none d-md-block d-xl-block d-lg-block">
                            <img src={finding1} />
                        </div>                    
                    </div>
                    <div id="example1" align="center">
                        <img src={holyRelic1} />
                    </div>
                    <div className="row" align="center">
                    
                        <div className="col-md-6 d-none d-sm-none d-md-block d-xl-block d-lg-block">
                            <img src={finding2} />
                        </div>
                        <div className="col-md-6 d-none d-sm-none d-md-block d-xl-block d-lg-block">
                            <img src={finding4} />
                        </div>
                    
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-12">            
                            <article>
                                <p style={{ "textAlign": "justify" }}><strong>OBSERVACIONES Y HALLAZGOS</strong></p>

                                <li>La Santa Reliquia de la Sant&iacute;sima Virgen de Coromoto, que mide 2,5 cm de alto por 2 cm de ancho, fue entregada por la misma Santísima Virgen María al Cacique Coromoto el 08 de Septiembre de 1652, en Guanare Estado Portuguesa.</li>

                                <li>Los ojos de la Virgen que miden menos de 1 mil&iacute;metro, tienen presencia de iris y al observar en profundidad el ojo izquierdo, tiene caracter&iacute;sticas de un ojo humano, se diferencia con claridad el orbe ocular, el conducto lacrimal, el iris y un peque&ntilde;o punto de luz donde se pudo observar una figura humana con caracter&iacute;sticas especiales.</li>

                                <li>La tinta que conforma la imagen de la Virgen, est&aacute; firme, nitida y muestra suaves relieves; una vez m&aacute;s se conform&oacute; que no se aprecian evidencias de que la tinta
                            fuera absorvida por la celulosa del papel, no se observan desplazamientos de la misma en &eacute;ste.</li>

                                <li>Igualmente fue detectada la presencia de varios s&iacute;mbolos, los cuales dadas sus caracter&iacute;sticas, y según indagaciones realizadas por el Antropólogo Nemesio Montiel,
                             son de origen ind&iacute;gena, y que se divisan con claridad en lo que compone la trama de ca&ntilde;as que sirven de contexto a la imagen de la Virgen.</li>


                            </article>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-12">            
                            <article>
                                <p style={{ "text-align": "justify" }}><strong>DESCRIPCIÓN DE LA RELIQUIA</strong></p>

                                <li>Existe un tramado de aparentes ca&ntilde;as en la parte posterior de la imagen de la Virgen, dentro de las cuales están plasmados los símbolos ind&iacute;genas, el cual est&aacute; dividido
                                    por un arco que en la parte superior tiene un delicado tramado de l&iacute;neas en forma diagonal de manera compuesta.
                                </li>

                                <li>La corona de la Virgen y el Ni&ntilde;o son t&iacute;picamente ind&iacute;genas.</li>

                                <li>El Ni&ntilde;o Jesús se observa sentado en el regazo de la Virgen, con un vestido ajustado con un ceñidor a la cintura, que se abre en la parte inferior en &aacute;ngulo abierto de 40&deg;, a trav&eacute;s del cual se observan 
                                    sus piernas cubiertas por unos pantaloncillos que llegan a la altura de las piernas, y el resto de las piernas se observan descubiertas.</li>

                                <li>La imagen del Ni&ntilde;o Jesús presenta notables ausencias de informaci&oacute;n, la cual se debe a la ausencia de fragmentos. Sin embargo puede apreciarse parcialmente la parte izquierda de su rostro,
                                    junto al cabello y parte de la corona.
                                </li>

                                <li>El rostro de la Virgen es sumamente hermoso, muy bien definido, observándose de manera especial una muy delicada línea en la parte derecha del mismo que casi es imperceptible, pero que enmarca de
                                    manera perfecta en su cara. Tambi&eacute;n puede verse sobre la cabeza de Ella que la corona presenta un velo que cae a sus lados de manera sim&eacute;trica, a trav&eacute;s del cual puede dividirse tambi&eacute;n parte de su cabello.
                                </li>

                                <li>En la parte inferior de la Reliquia pueden verse con total claridad y perfecci&oacute;n las manos de la Virgen que circundan al Niño Jesús, las cuales reposan sobre las orlas del vestido de &eacute;ste.
                                </li>


                            </article>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>
                  
                </div>

                <Footer />
            </div>
        );
    }

}