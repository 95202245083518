/**
 * VenerableMarcelinaSanJose 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";


export default class VenerableMarcelinaSanJose extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };    
        this.refreshLanguage = this.refreshLanguage.bind(this); 
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        let logo = "img/blessed/Marcelina_San_Jose.jpg";
        let logoSmall = "img/blessed/Marcelina_San_Jose-small.jpg";

        return (<div>
            <Helmet>
                <title>Venerable Marcelina de San Jos&eacute; (1874-1959)</title>
                <meta name="description" content="Venerable Marcelina de San Jos&eacute; (1874-1959)" />
                <meta name="keywords" content="venerable, marcelina, san, jose, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />

            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Venerable Marcelina de San Jos&eacute;</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center">
                                        <img src={logoSmall} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>                                         

                                            <p style={{"text-align":"justify"}}>La Sierva de Dios Marcelina de San José nació en Caracas, el 18 de Junio de 1874. Por parte de su familia tuvo una recta formación humana y cristiana, especialmente por la madre que fue miembro de la Tercera Orden Franciscana; en cuanto a su padre, murió al poco tiempo de su nacimiento. En el año 1899, a sus 25 años, la Sierva de Dios ingresó en la localidad de Maiquetía a la Congregación de las Hermanitas de los Pobres, cuya dedicación y oficio era la adoración de la Eucaristía y el auxilio y cuidado de los enfermos en los hospitales. El 25 de Marzo de 1900 vistió el hábito religioso, recibiendo el nombre de Marcelina de San José. En el año 1902 emitió su primera profesión, y la perpetua en el año 1908. Enviaron a la Sierva de Dios al hospital venezolano de Los Teques para dirigirlo y allí con generosidad se dedicó al cuidado de los enfermos y de los militares heridos.</p>

                                            <p style={{"text-align":"justify"}}>En el año 1909 fue nombrada superiora de Barranquilla en Colombia. Allí las Hermanas dirigían un hospicio en el que recibían a casi cien ancianos pobres y atendían gratuitamente a muchas niñas pobres en una escuela. En aquel tiempo, en razón de la diversidad de pensamiento y opiniones sobre la razón de la vida religiosa en el Instituto, comenzaron los disentimientos. Esto generó una gran perturbación en la vida de la reciente Congregación de tal manera que muchas Hermanas, entre las cuales la Sierva de Dios, se separaron del Instituto.</p>

                                            <p style={{"text-align":"justify"}}>En esta condición la Sierva de Dios, juntamente con las Hermanas de Barranquilla, quiso perseverar en su oficio, atendiendo generosamente a los ancianos en el hospicio, y se confió a la protección del Obispo de Cartagena en Colombia, quien determinó la separación de ambos Institutos, disponiendo cambiar al de Barranquilla el hábito y el nombre, a saber  el de Hermanitas de los Pobres de San Pedro Claver, señalando de este modo a las Hermanas la absoluta consagración a la vida de caridad conforme al ejemplo de aquel Santo de la Compañía de Jesús, que fue el Apóstol de los esclavos.</p>

                                            <p style={{"text-align":"justify"}}>En el año de 1917 la Sierva de Dios fue elegida superiora general, confirmada de nuevo en este oficio en el año 1935. Así la nueva Congregación se inició y se expandió. Asumieron obras de caridad principalmente para los mendigos, los necesitados y los ancianos. Concluido su oficio de superiora general, la Sierva de Dios fue elegida responsable del consejo general, oficio en el cual ella se dedicó a la formación de las Hermanas, y se hizo ejemplo vivo para ellas de la generosidad, la paciencia, la benevolencia y la suavidad en el servicio a los necesitados, impregnando su espíritu con el compromiso por el servicio a los pobres. Para las jóvenes que ingresaban al Instituto, la Sierva de Dios elaboró y dispuso algunas notas sobre los principios de la espiritualidad de la Congregación, a saber: el amor a la Cruz, el recogimiento interior, el silencio, el amor a los pobres en quienes se sirve al mismo Cristo, la renuncia a uno mismo. Meditaba principalmente sobre la Divina Providencia, la Pasión, la Eucaristía, la Inmaculada y San Pedro Claver. La Sierva de Dios fue una mujer jovial y modesta, dedicada a la contemplación y preparada simultáneamente para la acción. Mantenía una disposición muy equitativa para con los amigos y los adversarios, correcta en su proceder y modesta en la penitencia. Vivió su consagración religiosa con gran ardor y siempre deseó vivir en la presencia de Dios.</p>

                                            <p style={{"text-align":"justify"}}>Dedicó un cuidado maternal a los sacerdotes a quienes sostenía con su oración y su apoyo. La Sierva de Dios nunca pronunció palabras de indignación en las abundantes adversidades de su vida. Siguió el ejemplo del Buen Samaritano, ayudando sin demora a los necesitados y principalmente a los más pobres y abandonados. También cuando enfrentó situaciones adversas al instituto, la Sierva de Dios logró un coherente testimonio de fe, apoyándose en la oración constante y el amor a la Eucaristía. La virtud de la esperanza acompañó toda su vida, haciéndola una mujer fuerte pero amable y dócil. Ejerció la virtud de la caridad en estrecha unión con Dios y en un amor permanente por el prójimo.</p>

                                            <p style={{"text-align":"justify"}}>En los últimos meses de su vida, debido a una grave y dolorosa enfermedad que ella soportó con fortaleza en comunión con el Señor crucificado, murió en Barranquilla el 16 de Noviembre de 1959.</p>

                                            <p style={{"text-align":"justify"}}>Debido a su fama de santidad, desde el 18 de Junio de 1986 hasta el 11 de Febrero de 1988 se celebró en la Curia de Barranquilla la inquisición diocesana, cuya validez jurídica por decreto del 4 de Octubre de 1991 fue aprobada por esta Congregación de las Causas de los Santos. Preparada la Posición, se discutió según las normas si la Sierva de Dios habría cultivado las virtudes de manera heroica.</p>

                                            <p style={{"text-align":"justify"}}>El 20 de Diciembre del 2012 el Papa Benedicto XVI y la Congregación de las Causas de los Santos, reconocieron que las virtudes teologales de Fe, Esperanza y Caridad tanto hacia Dios como hacia el prójimo, y también de las cardinales de Prudencia, Justicia, Templanza y Fortaleza, y de sus anexas, en grado heroico, en la Sierva de Dios Marcelina de San José.</p>

                                            <p><b>Bibliografía</b></p>
                                            <p>https://www.celam.org/decreto-sobre-las-virtudes-de-la-sierva-de-dios-marcelina-de-san-jose-820.html</p>                                            

                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12" align="center"><a href="venerables-venezuela.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

            </div>
            <Footer />
        </div>);
    }

}