/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import history1 from '../img/cathedral/20150815_064808.jpg';
import history2 from '../img/cathedral/DSCF6484.JPG';
import history3 from '../img/cathedral/DSCF6485_2.JPG';
import history4 from '../img/cathedral/DSCF6533.JPG';


export default class CatedralGuanareHistorySlider extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        return(
            <div className="row">
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{ 'marginTop': '173px' }}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={history1} />
                        </div>
                        <div>
                            <img src={history4} />
                        </div>
                        <div>
                            <img src={history2} />
                        </div>
                        <div>
                            <img src={history3} />
                        </div>
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>
                   <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={history1} />
                        </div>
                        <div>
                            <img src={history4} />
                        </div>
                        <div>
                            <img src={history2} />
                        </div>
                        <div>
                            <img src={history3} />
                        </div>
                    </Carousel>
                </div>
            </div>
       );
    }
    
}