/**
 * Header 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, { Component } from 'react';
import logoBasilica from '../img/logo_basilica4.png';
import { Trans } from '@lingui/macro';


export default class Footer extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12 d-none d-sm-block d-md-block d-xl-block d-lg-block ">
                    <footer className="footer">
                        <div className="footer-copyright text-center py-3" style={{ "backgroundColor": "#f3d593" }}> <h4 style={{ "fontSize": "18px", "fontWeight": "bold", "color": "#927f26" }}>
                            <img src={logoBasilica} />&nbsp;<Trans>FooterLb</Trans></h4>
                        </div>
                    </footer>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block">
                    <footer className="footer">
                        <div className="footer-copyright text-center py-3" style={{ "backgroundColor": "#f3d593" }}> <h4 style={{ "fontSize": "14px", "fontWeight": "bold", "color": "#927f26" }}> &nbsp;
                        <Trans>FooterLb</Trans></h4>
                        </div>
                    </footer>
                </div>
            </div>
        );
    }
        
}