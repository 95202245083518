/**
 * DeclareOurLadyCoromoto 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 06/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import triptico1 from '../img/holy_rosary/tripticovirgencoromoto_11.jpg';
import triptico2 from '../img/holy_rosary/tripticovirgencoromoto_2.jpg';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

export default class HolyRosaryOurLadyCoromoto extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };   
        this.refreshLanguage = this.refreshLanguage.bind(this);  
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }


    render() {
        return (<div>
            <Helmet>
                <title>Santo Rosario a Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Santo Rosario a Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="coromoto, virgen, maria" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Santo Rosario a Nuestra Se&ntilde;ora de Coromoto</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={triptico1} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={triptico2} className="img-thumbnail"/>
                                    </div>
                                </div>                                
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" align="center">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-12"><Link to="prayersFiles/RosarioVirgenCoromoto.pdf" target="_blank" onClick={this.trackPDF}>Santo Rosario a Nuestra Se&ntilde;ora de Coromoto (descargar PDF)</Link></div>
                </div>
                <div className="row">
                    <div className="col-12" align="center">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-12">Bibliografía:https://basilicacatedralguanare.wordpress.com/santo-rosario-de-la-virgen-de-coromoto</div>
                </div>
                <div className="row">
                    <div className="col-12" align="center">&nbsp;</div>
                </div>
                <div className="row">
                    <div class="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>



            </div>
            <Footer />
        </div>);
    }

}