/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 09/10/2021
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import slider1 from '../img/gallery-holy-relic/2400_960/pastoraLetterSlider-2.jpg';
import hallazgos1 from '../img/gallery-holy-relic/2176_850/santa-reliquia-coromoto-1450.jpg';
import OurLadyCoromoto463 from '../img/gallery-holy-relic/480/OurLadyCoromoto463px.jpg';


export default class PastoralLetterSlider extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        return(
            <div className="row">
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{ 'marginTop': '173px' }}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={slider1} />
                        </div>
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-none" style={{'marginTop': '80px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={hallazgos1} />
                        </div>
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block" style={{'marginTop': '80px'}} align="center">
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={OurLadyCoromoto463} />
                        </div>                           
                    </Carousel>
                </div>
            </div>
        );
    }
    
}