/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';

import Footer from './footer';
import logo from '../img/beata-candelaria-san-jose.JPG';
import logoSmall from '../img/beata-candelaria-san-jose-small.jpg';
import { Helmet } from "react-helmet";


export default class BlessedCandelariaSanJose extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };    
        this.refreshLanguage = this.refreshLanguage.bind(this); 
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Beata Candelaria de San Jos&eacute; (1863-1940)</title>
                <meta name="description" content="Beata Candelaria de San Jos&eacute; (1863-1940)" />
                <meta name="keywords" content="beata, candelaria, jose, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />

            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Beata Candelaria de San Jos&eacute;</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center">
                                        <img src={logoSmall} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>                                         

                                            <p style={{"text-align":"justify"}}>Susana Paz Castillo Ram&iacute;rez, tercera hija del matrimonio de Francisco de Paula Paz Castillo y Mar&iacute;a del Rosario Ram&iacute;rez, naci&oacute; en Altagracia de Orituco (Estado Gu&aacute;rico, Venezuela), el 11 de agosto de 1863.</p>

                                            <p style={{"text-align":"justify"}}>Su padre era un hombre recto y honrado, de gran coraz&oacute;n y profundamente cristiano; gozaba del aprecio y estima de todos los habitantes; pose&iacute;a conocimientos de medicina naturista y los empleaba para ayudar a mucha gente que solicitaba sus servicios. Su madre era una persona piadosa, trabajadora y honrada.</p>

                                            <p style={{"text-align":"justify"}}>Tanto ella como don Francisco brindaron a sus hijos una educaci&oacute;n tan esmerada como lo permit&iacute;an las circunstancias de su tiempo. En el aspecto cristiano fue &oacute;ptima: les infundieron el ejemplo y la palabra, la solidaridad y la responsabilidad en las pr&aacute;cticas de la fe cristiana y valores humanos.</p>

                                            <p style={{"text-align":"justify"}}>Su instrucci&oacute;n acad&eacute;mica, aunque escasa y deficiente, propia de la &eacute;poca que le toc&oacute; vivir, no fue un impedimento para su formaci&oacute;n integral: frecuent&oacute; una escuela particular donde dio sus primeros pasos en la escritura y el cultivo de su apasionamiento por la lectura. Adem&aacute;s, aprendi&oacute; corte y confecci&oacute;n y toda clase de labores, especialmente bordados. Este aprendizaje fue un valioso recurso para su posterior servicio a los m&aacute;s necesitados.</p>

                                            <p style={{"text-align":"justify"}}>Su padre muri&oacute; el 23 de noviembre de 1870, cuando Susana contaba con 7 a&ntilde;os de edad. Cuando muri&oacute; su madre, el 24 de diciembre de 1887, Susana, que ten&iacute;a 24 a&ntilde;os, asumi&oacute; las responsabilidades de diligente ama de casa. A la vez, se encargaba de practicar la caridad con los enfermos y heridos que recog&iacute;a y cuidaba en una casa semi-abandonada, adjunta a la iglesia parroquial.</p>

                                            <p style={{"text-align":"justify"}}>Junto con otras j&oacute;venes de su pueblo y con el apoyo de un grupo de m&eacute;dicos y del padre Sixto Sosa, p&aacute;rroco de Altagracia de Orituco, fund&oacute; un hospital para atender a todos los necesitados. All&iacute;, en hamacas y catres de lona, que ella misma confeccionaba, los atend&iacute;a.</p>

                                            <p style={{"text-align":"justify"}}>Con la fundaci&oacute;n de este centro de salud, en 1903, se dio inicio a la familia religiosa de las Hermanitas de los Pobres de Altagracia, actualmente denominada Hermanas Carmelitas de la Madre Candelaria. El 13 de septiembre de 1906, con autorizaci&oacute;n del obispo diocesano, la madre Susana hizo su profesi&oacute;n religiosa tomando el nombre de Candelaria de San Jos&eacute;.</p>

                                            <p style={{"text-align":"justify"}}>El 31 de diciembre de 1910 naci&oacute; oficialmente la congregaci&oacute;n de las Hermanitas de los Pobres de Altagracia con la profesi&oacute;n de las primeras seis hermanas, en manos de mons. Felipe Neri Sendrea, quien confirm&oacute; a la madre Candelaria como superiora general. En diciembre de 1916 emiti&oacute; sus votos perpetuos en Ciudad Bol&iacute;var.</p>

                                            <p style={{"text-align":"justify"}}>Su vida transcurri&oacute; entre los pobres; se distingui&oacute; por una profunda humildad, una inagotable caridad con ellos, y una profunda vida de fe, oraci&oacute;n y amor a la Iglesia. Adem&aacute;s de su esmerada atenci&oacute;n por los enfermos, se preocup&oacute; por la educaci&oacute;n de los ni&ntilde;os, tarea que dej&oacute; como legado a sus hijas carmelitas.</p>

                                            <p style={{"text-align":"justify"}}>La madre Candelaria era una religiosa de car&aacute;cter afable, recogida, de baja y modesta mirada; siempre dejaba suavidad en cuantos la escuchaban cuando depart&iacute;a su cordial y amena conversaci&oacute;n.</p>

                                            <p style={{"text-align":"justify"}}>Dos cosas llamaban poderosamente la atenci&oacute;n en ella: su profunda humildad y su inagotable caridad. Ten&iacute;a una gran sensibilidad ante las desgracias ajenas; nunca dec&iacute;a &quot;no&quot; a nadie, sobre todo cuando se trataba de enfermos pobres y abandonados.</p>

                                            <p style={{"text-align":"justify"}}>Otra caracter&iacute;stica de su entrega era la alegr&iacute;a; todo lo hac&iacute;a con amor y una confianza sin l&iacute;mites en la divina Providencia. Sus grandes amores fueron Jes&uacute;s crucificado y la sant&iacute;sima Virgen. Recorri&oacute; muchos kil&oacute;metros en busca de recursos para el sostenimiento de sus obras y fundando nuevas comunidades que respondieran a las necesidades del momento.</p>

                                            <p style={{"text-align":"justify"}}>Gobern&oacute; la congregaci&oacute;n durante 35 a&ntilde;os, desde su fundaci&oacute;n hasta el cap&iacute;tulo general de 1937, en el que le sucedi&oacute; en el cargo la madre Luisa Teresa Morao.</p>

                                            <p style={{"text-align":"justify"}}>Los &uacute;ltimos a&ntilde;os de la madre Candelaria estuvieron marcados por el dolor y la enfermedad. No obstante, despu&eacute;s de dejar el cargo de superiora general, acept&oacute; seguir prestando sus servicios a la congregaci&oacute;n como maestra de novicias.</p>

                                            <p style={{"text-align":"justify"}}>Ten&iacute;a plena conciencia de su enfermedad, pero con incre&iacute;ble paciencia soportaba los dolores y daba pruebas de conformidad con la voluntad de Dios. Ped&iacute;a al Se&ntilde;or poder morir con el nombre de Jes&uacute;s en los labios, y as&iacute; fue.</p>

                                            <p style={{"text-align":"justify"}}>En la madrugada del 31 de enero de 1940 tuvo un v&oacute;mito de sangre. Tras pronunciar tres veces el nombre de Jes&uacute;s, entreg&oacute; su alma al Creador.</p>

                                            <p style={{"text-align":"justify"}}>El 22 de marzo de 1969 se inici&oacute; en la ciudad de Caracas su proceso de beatificaci&oacute;n y canonizaci&oacute;n. Benedicto XVI firm&oacute; el decreto de beatificaci&oacute;n el 6 de julio de 2007.</p>

                                            <p>Fuente:&nbsp;http://www.vatican.va/news_services/liturgy/saints/2008/ns_lit_doc_20080427_candelaria-de-san-jose_sp.html</p>


                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>




            </div>
            <Footer />
        </div>);
    }

}