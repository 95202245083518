/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import DonationsSanctuarySliders from './donationsSanctuarySliders';
import Footer from './footer';

import { Helmet } from "react-helmet";
import image1 from '../img/442px-Emblem_of_Vatican_City.png';
import image2 from '../img/conferenciaEpiscopal_VE.png';
import image3 from '../img/basilica.jpg';
import image4 from '../img/aparecida_Logo.png';
import image5 from '../img/santuarioFatima_Logo.png';
import image6 from '../img/basilica_Krakovia.png';



export default class Links extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }


    render() {
        return (<div>
            <Helmet>
                <title>Enlaces Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Enlaces Santuario Nacional Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="link, coromoto, sanctuary" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <DonationsSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Enlaces</h3>
                            </div>
                            <div className="card-body">
                               
                               
                                <div class="row" align="center">
                                    <div class="col-md-6"><a href="http://www.vatican.va" target="_blank" rel="noopener noreferrer">
                                        <img src={image1} /></a></div>
                                    <div class="col-md-6" ><a href="http://www.vatican.va" target="_blank" rel="noopener noreferrer">La Santa Sede</a></div>
                                </div>


                                <div class="row" align="center">
                                    <div class="col-md-6"><a href="http://www.cev.org.ve/" target="_blank" rel="noopener noreferrer"><img src={image2} /></a></div>
                                    <div class="col-md-6"><a href="http://www.cev.org.ve/" target="_blank" rel="noopener noreferrer">Conferencia Episcopal Venezolana</a></div>
                                </div>


                                <div class="row" align="center">
                                    <div class="col-md-6"><a href="http://www.virgendeguadalupe.mx" target="_blank" rel="noopener noreferrer"><img src={image3} /></a></div>
                                    <div class="col-md-6"><a href="http://www.virgendeguadalupe.mx" target="_blank" rel="noopener noreferrer">Insigne y Nacional Bas&iacute;lica de Santa Mar&iacute;a de Guadalupe</a></div>
                                </div>

                                <div class="row" align="center">
                                    <div class="col-md-6"><a href="http://www.a12.com/santuario-nacional" target="_blank" rel="noopener noreferrer"><img src={image4} /></a></div>
                                    <div class="col-md-6"><a href="http://www.a12.com/santuario-nacional" target="_blank" rel="noopener noreferrer">Santu&aacute;rio Nacional de Aparecida</a></div>
                                </div>


                                <div class="row" align="center">
                                    <div class="col-md-6"><a href="http://www.fatima.pt/portal/" target="_blank" rel="noopener noreferrer"><img src={image5} /></a></div>
                                    <div class="col-md-6"><a href="http://www.fatima.pt/portal/" target="_blank" rel="noopener noreferrer">Santuário de F&aacute;tima</a></div>
                                </div>

                                <div class="row" align="center">
                                    <div class="col-md-6"><a href="http://www.mariacki.com/index.php/pl/" target="_blank" rel="noopener noreferrer"><img src={image6} /></a></div>
                                    <div class="col-md-6"><a href="http://www.mariacki.com/index.php/pl/" target="_blank" rel="noopener noreferrer">Bas&iacute;lica de Santa Mar&iacute;a Cracovia</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>




            </div>
            <Footer />
        </div>);
    }

}