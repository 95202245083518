/**
 * VenerableJoseGregorioHernandez 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 27/04/2021
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";


export default class BlessedJoseGregorioHernandez extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };    
        this.refreshLanguage = this.refreshLanguage.bind(this); 
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        let logo = "img/blessed/Jose_Gregorio_Hernandez.png";
        let logoSmall = "img/blessed/Jose_Gregorio_Hernandez-small.png";

        return (<div>
            <Helmet>
                <title>Beato Jos&eacute; Gregorio Hern&aacute;ndez (1864-1919)</title>
                <meta name="description" content="Venerable Jos&eacute; Gregorio Hern&aacute;ndez (1864-1919)" />
                <meta name="keywords" content="venerable, jose, gregorio, hernandez, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />

            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Beato Jos&eacute; Gregorio Hern&aacute;ndez</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center">
                                        <img src={logoSmall} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>                                         

                                            <p style={{"text-align":"justify"}}>En Isnotú, pequeño pueblo del estado Trujillo, Venezuela, dedicado a la agricultura y a cortar madera, Dios se hizo presente de una forma especial en el seno de la familia de Benigno Hernández y Josefa Cisneros de Hernández, con el providencial nacimiento de su primogénito José Gregorio el 26 de octubre de 1864. Ni sus padres y menos sus vecinos sospechaban el destino que Dios tenía reservado para este niño.</p>

                                            <p style={{"text-align":"justify"}}>José Gregorio fue bautizado en la Iglesia Católica del Santísimo Nombre de Jesús de Escuque, el 30 de enero de 1865 por el Presbítero Sinforiano Briceño y sus padrinos personas muy cristianas, Don Tomás Lobo y Doña Perpetua Enriquez.  El Sacramento de la Confirmación lo recibió de manos del Obispo de Mérida Monseñor Juan Hilario Boset, en diciembre de 1867. Su padrino fue el Presbítero Francisco de Paula Moreno.</p>

                                            <p style={{"text-align":"justify"}}>Su padre y su madre se esforzaron en enseñarles las tres cosas básicas que toda persona que desea triunfar en la vida debe vivir: 1.-evitar el mal, 2.- practicar el bien y 3.-buscar perfeccionarse cada vez más.  Es decir, su hogar fue su escuela básica de formación.</p>                                            

                                            <p style={{"text-align":"justify"}}>Estudió Medicina en Caracas y tuvo tanto éxito que el presidente venezolano lo envió a estudiar microscopía, histología normal, patología y fisiología experimental en París.</p>

                                            <p style={{"text-align":"justify"}}>José Gregorio se dejó guiar por el Espíritu Santo, atento siempre a lo que Dios le pedía. Fue un cristiano consciente de su proceder tanto en la Iglesia como fuera de ella. Todos los que lo conocieron dan testimonio de ello.</p>

                                            <p style={{"text-align":"justify"}}>Tenía y vivía una intensa vida interior.  Buscaba tiempo y lugar propicio para hablar con Dios de forma constante. En sus oraciones se encontraba con Cristo, meditaba, profundizaba, hacía suyo y estudiaba para luego salir a trabajar con responsabilidad en coloquio constante con Dios que era su guía y mejor Maestro.   Con afán de perfeccionamiento personal, en sus idas diarias a Misa en la Iglesia de las Mercedes, conoció lo que era la Orden Tercera de San Francisco, una orden seglar para personas que desean vivir la espiritualidad de San Francisco de Asís, pero no en conventos sino en sus casas.  Se unió a ellos y compartía la lectura del Evangelio y las oraciones tanto con personas muy selectas de la ciudad como con gente muy humilde. José Gregorio encontró en la Virgen María el modelo, el icono de la esperanza profética por su capacidad de meditar prolongadamente la Palabra en su corazón, leer la historia según el proyecto de Dios, contemplar a Dios presente y operante en el tiempo. Encontró en la vida de la Virgen María la sabiduría transparente que une armónicamente el éxtasis del encuentro con Dios y el mayor realismo crítico ante el mundo.  Por ello, su devoción a la Santísima Virgen era algo sustancial para su vida espiritual.</p>

                                            <p style={{"text-align":"justify"}}>José Gregorio  con permiso de Monseñor Juan Bautista Castro, viajó al pueblo de Lucca en Italia donde solicitó su entrada en la Congregación religiosas de la Cartuja de Farneta.  El prior de la Orden lo recibió gustoso, se realizó la ceremonia de su recibimiento y José Gregorio comenzó el noviciado.  En menos de un año tuvo que abandonarlo y regresar a Venezuela, pues la fragilidad de su cuerpo le impedía cumplir las tareas diarias de la Congregación.   Con profunda tristeza regresó al país y pidió su ingreso en el Seminario de Caracas.  Tampoco duró mucho tiempo como seminarista por la cantidad de personas que lo iban a buscar para solicitarle su ayuda profesional y personal. Un tiempo después viajó a Italia para ingresar al Seminario Pío Latino. Su estado de salud se debilitaba cada vez más y no tuvo otra opción sino regresar a Venezuela.</p>

                                            <p style={{"text-align":"justify"}}>Comprendió que Dios lo quería laico y ya no intentó regresar a la vida religiosa. Decidió convertirse en médico católico ejemplar, sirviendo al Señor en los enfermos.</p>

                                            <p style={{"text-align":"justify"}}>Dedicaba dos horas diarias para servir a los más pobres.</p>

                                            <p style={{"text-align":"justify"}}>Su profesión médica la convirtió en un rito sacerdotal pues pensaba y actuaba como si detrás de todas las cosas viera un camino abierto a sus ansias de santidad. No perdía oportunidad para confesar y defender sus creencias, lo mismo en la cátedra que en la amigable conversación. En sus escritos, se siente rezumar aquella fe y aquella tensión para que todas las personas conocieran la verdad y se adhirieran a ella. Para José Gregorio todos sus actos eran apostólicos. Se había impuesto la misión de buscar su perfeccionamiento en los distintos órdenes, pero, por encima de todo eso, quería caminar hacia la santidad.</p>

                                            <p style={{"text-align":"justify"}}>La virtud que más le caracterizaba era la caridad. Cumplía especialmente el mandamiento del amor a Dios  y al prójimo. Consideraba el pecado como el mayor mal que le podía sobrevenir al hombre y cuidaba que nadie oyera salir una palabra deshonesta de sus labios. Huyó siempre de las murmuraciones y críticas y de toda conversación menos digna.</p>

                                            <p style={{"text-align":"justify"}}>En Roma se nombró como Postulador General de la Causa, al Reverendo Padre Carlos Miccinelli, quien designó a Monseñor José Rincón Bonilla, Vice postulador para Venezuela de la Causa de Beatificación del Siervo de Dios, Dr. José Gregorio Hernández. Más adelante se nombró como Postulador de la causa a Monseñor Dr. Marcelo Venturi y el Vice-postulador, Monseñor Dr. Jorge Urosa Savino. Este último, remitió a la Santa Sede numerosas cartas postulatorias de personalidades y entidades civiles y eclesiásticas, en las que se solicitaba que esta causa fuera introducida ante el pleno de la Sagrada Congregación para la Causa de los Santos. Se hizo también el proceso informativo en La Habana (1957) y un proceso complementario en Caracas (1961).</p>

                                            <p style={{"text-align":"justify"}}>Al iniciarse la causa de Beatificación, el 23 de octubre de 1975 sus restos fueron exhumados y trasladados a la Iglesia de Nuestra Señora de la Candelaria, en Caracas.</p>

                                            <p style={{"text-align":"justify"}}>Luego de varios decretos, el 1º de agosto de 1980 se publicó el decreto de la autenticidad del proceso. Finalmente, el 16 de enero de 1986, por sus virtudes, la Iglesia lo elevó de Siervo de Dios a Venerable a través de un Decreto donde se describen las virtudes heroicas de José Gregorio Hernández médico y profesor de la Universidad de Caracas.</p>

                                            <p style={{"text-align":"justify"}}>El 27 de abril de 2020, el Arzobispo de Mérida y Administrador Apostólico de la Arquidiócesis de Caracas, Cardenal Baltazar Porras, dio a conocer que la comisión teológica aprobó, por unanimidad, el milagro concedido por intercesión del Venerable Dr. José Gregorio Hernández, con lo cual solo faltaría la Plenaria de Cardenales y Obispos y la aprobación del Papa Francisco para su beatificación.</p>

                                            <p style={{"text-align":"justify"}}>El 19 de junio de 2020, la Congregación para la Causa de los Santos promulgó el decreto con la autorización del Papa Francisco para la Beatificación del Venerable Dr. José Gregorio Hernández, lo que hará del médico de los pobres el 4to Beato Venezolano, y el primero trujillano.</p>

                                            <p style={{"text-align":"justify"}}>El 26 de Abril del 2021 el Santo Papa Francisco declaró al venerable José Gregorio Hernández, Copatrono del Ciclo de Estudios en Ciencias de la Paz. En el Quirógrafo se menciona que, después de haber considerado la solicitud del Cardenal Baltazar E. Porras Cardozo, Administrador Apostólico de la Arquidiócesis de Caracas, Venezuela; y aceptada la petición del Rector de la Pontificia Universidad Lateranense; el Papa Francisco dispone que, “el Venerable Siervo de Dios José Gregorio Hernández Cisneros, fiel laico, nacido el 26 de octubre de 1864 en Isnotú, Venezuela y fallecido el 29 de junio de 1919 en Caracas, Venezuela, cuya beatificación está establecida en la Archidiócesis de Caracas para el 30 de abril de 2021, sea nombrado Copatrono del Ciclo de Estudios en Ciencias de la Paz, instituido por mí en la Pontificia Universidad Lateranense con la Carta el Deseo de Paz del 12 de noviembre de 2018, colocándolo junto al Beato Juan de la Paz</p>
                                                                                      
                                            <p style={{"text-align":"justify"}}>El Beato José Gregorio Hernandez fue beatificado el 30 de Abril del 2021 en la Ciudad de Caracas, en el iglesia del colegio La Salle, en una Santa Misa presidida por el Monseñor Aldo Giordano, nuncio apostólico en Venezuela, junto a los cardenales venezolanos Baltazar Porras y el Cardenal Emérito de Caracas Jorge Urosa Savino.</p>

                                            <p style={{"text-align":"justify"}}>Ciento sesenta reliquias con pequeñísimos fragmentos  de la osamenta del Beato José Gregorio Hernández  fueron confeccionadas con devoción y delicadeza por las Hermanas Siervas de Jesús, una congregación fundada por la Beata, también venezolana, Madre Carmen Rendiles. En palabras de la Hna. Rosa María Ríos, Superiora de las Siervas de Jesús, durante la preparación de las reliquias las religiosas se mantuvieron orantes por el fin de la pandemia, por el personal médico y por la paz de Venezuela y del mundo. Las reliquias fueron entregadas a Mons. Tulio Ramírez, Obispo de Guarenas y Vicepostulador de la Causa de Beatificación y Canonización del Dr. José Gregorio Hernández, quien en un acto ceremonial llevado a cabo de forma austera pero con gran fervor, expresó a las Siervas de Jesús: "que Venezuela reciba este gesto de amor de cada una de ustedes y premie la laboriosidad religiosa con la cuales ustedes realizaron esta confección de las reliquias"</p>

                                            <p><b>Bibliografía</b></p>
                                            <p>UN VENEZOLANO EJEMPLAR, JOSÉ GREGORIO HERNÁNDEZ - María García de Fleury</p>
                                            <p>https://conferenciaepiscopalvenezolana.com/comision-teologica-aprobo-por-unanimidad-el-milagro-del-venerable-dr-jose-gregorio-hernandez</p>
                                            <p>https://www.aciprensa.com/noticias/anuncian-la-ruta-del-venerable-jose-gregorio-hernandez-ejemplo-de-fe-en-venezuela-74965</p>
                                            <p>https://www.vaticannews.va/es/iglesia/news/2021-04/venezuela-beatificacion-jose-gregorio-hernandez-relicarios-listo.html</p>

                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12" align="center"><a href="beatos-venezuela.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

            </div>
            <Footer />
        </div>);
    }

}