/**
 * SantuaryLink 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 02/04/2020
 */



export default class HolyRosaryMistery {

    constructor(rosaryCounts, url_sound, title, url_image, prayer) {
        this.rosaryCounts = rosaryCounts;
        this.url_sound = url_sound;
        this.title = title;      
        this.url_image = url_image;      
        this.prayer = prayer;
    }

    getRosaryCounts() {
        return this.rosaryCounts;
    }

    getURL_Sound() {
        return this.url_sound;
    }

    getTitle() {
        return this.title;
    }

    getURL_Image() {
        return this.url_image;
    }

    getPrayer() {
        return this.prayer;
    }

}