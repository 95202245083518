/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import slider1 from '../img/gallery-holy-relic/2400_960/slidersHolyRelic1.jpg';
import slider2 from '../img/gallery-holy-relic/2400_960/slidersHolyRelic2.jpg';
import slider3 from '../img/gallery-holy-relic/2400_960/slidersHolyRelic3.jpg';
import slider4 from '../img/gallery-holy-relic/2400_960/slidersHolyRelic4.jpg';
import slider5 from '../img/gallery-holy-relic/2400_960/slidersHolyRelic5.jpg';
import slider6 from '../img/gallery-holy-relic/2400_960/slidersHolyRelic6.jpg';
import slider7 from '../img/gallery-holy-relic/2400_960/santaReliquiaSlider.jpg';
import slider8 from '../img/gallery-holy-relic/2400_960/santaReliquiaSlider2.jpg';
import slider9 from '../img/gallery-holy-relic/2400_960/slidersHolyRelic7.jpg';
import slider1Medium from '../img/gallery-holy-relic/1450/findings1.jpg';
import slider2Medium from '../img/gallery-holy-relic/1450/findings2.jpg';
import slider3Medium from '../img/gallery-holy-relic/1450/findings3.jpg';
import slider1Small from '../img/gallery-holy-relic/480/SantoRostroSantisimaVirgenCoromoto300px.jpg';

export default class FindingsHolyRelicSlider extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        return(
            <div className="row">
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{ 'marginTop': '173px' }}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={slider7} />
                        </div>
                        <div>
                            <img src={slider8} />
                        </div>
                        <div>
                            <img src={slider1} />
                        </div>
                        <div>
                            <img src={slider2} />
                        </div>
                        <div>
                            <img src={slider3} />
                        </div>
                        <div>
                            <img src={slider4} />
                        </div>
                        <div>
                            <img src={slider5} />
                        </div>
                        <div>
                            <img src={slider6} />
                        </div>
                        <div>
                            <img src={slider9} />
                        </div>
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-none" style={{'marginTop': '80px'}}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={slider1Medium} />
                        </div>   
                        <div>
                            <img src={slider2Medium} />
                        </div>   
                        <div>
                            <img src={slider3Medium} />
                        </div>                     
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block" style={{'marginTop': '80px'}}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={slider1Small} />
                        </div>                                        
                    </Carousel>
                </div>
            </div>
        );
    }
    
}