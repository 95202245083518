/**
 * TourVirtualSanctuaryRedirect 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/03/2020
 */

import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'


export default class TourVirtualSanctuaryRedirect extends Component {


    componentDidMount() {
        window.open('http://www.santuariobasilicacoromoto.com/tour-virtual-santuario-nacional-virgen-coromoto.html');
    }

    render() {
        return <Redirect to='/'  />;
    }

}