/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import image1 from '../img/sanctuary/donationsSliders.jpg';
import image2 from '../img/gallery-holy-relic/480/donationsSliders.jpg';


export default class DonationsSanctuarySliders extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={image1} />
                        </div>
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={image2} />
                        </div>                      
                    </Carousel>
                </div>
            </div>);
    }
    
}