/**
 * DeclareOurLadyCoromoto 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 11/10/2021
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import reliquia from '../img/SANTA_RELIQUIA.png';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';


export default class ConsecrateVenezuelaVirginMary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange }; 
        this.refreshLanguage = this.refreshLanguage.bind(this);    
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }


    render() {
        return (<div>
            <Helmet>
                <title>Acto de Consagraci&oacute;n de Venezuela a la Sant&iacute;sima Virgen Mar&iacute;a</title>
                <meta name="description" content="Acto de Consagraci&oacute;n de Venezuela a la Sant&iacute;sima Virgen Mar&iacute;a" />
                <meta name="keywords" content="venezuela, consagrar, coromoto, virgen, maria" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Acto de Consagraci&oacute;n de Venezuela a la Sant&iacute;sima Virgen Mar&iacute;a</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={reliquia} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{"text-align":"justify"}}>Acto de&nbsp;Consagraci&oacute;n de Venezuela a la Sant&iacute;sima Virgen, con que el Episcopado Venezolano clausur&oacute; el Segundo Congreso Mariano Nacional el 12 de Diciembre de 1928 en la ciudad de Coro.</p>
                                            <p style={{"text-align":"justify"}}>&ldquo;Ante el trono de Vuestra omnipotente intercesi&oacute;n, venimos a postrarnos &iexcl;oh Madre august&iacute;sima de Dios!, para consagrarnos totalmente a Vos y poner en vuestras manos la suerte de nuestra Rep&uacute;blica. En presencia de vuestro Divino Hijo, a quien ya est&aacute; esta Patria dedicada en el Sant&iacute;simo Sacramento del Altar, hacemos ahora este acto de entregamiento a Vos, para que El se digne prestar su filial benepl&aacute;cito a nuestro homenaje, a la misma Madre que El nos leg&oacute; por tal desde la Cruz.</p>
                                            <p style={{"text-align":"justify"}}>&nbsp; &nbsp;&iexcl;Oh Inmaculada Madre nuestra! &iexcl;Oh benign&iacute;sima Madre nuestra! &iexcl;Oh dulc&iacute;sima y clement&iacute;sima Reina nuestra!, con &aacute;nimo agradecido entonamos loores a vuestra misericordia.</p>
                                            <p style={{"text-align":"justify"}}>&nbsp; &nbsp;Bajo vuestro amparo nos acogemos, &iexcl;oh Se&ntilde;ora! que ten&eacute;is cautivados para siempre nuestros corazones, Vos que hab&eacute;is arrebatado desde el d&iacute;a mismo en que comenz&oacute; para nosotros la vida civilizada, y con vuestra suav&iacute;sima protecci&oacute;n y benign&iacute;sima presencia, ya en la selva de Coromoto, ya en los santuarios que la piedad Venezolana ha erigido bajo t&iacute;tulos de Nuestra Se&ntilde;ora del Valle, del Socorro, de Chiquinquir&aacute;, de Bel&eacute;n, de la Caridad y otros tantos de que se ufana la Naci&oacute;n Venezolana: hab&eacute;is afirmado, robustecido y multiplicado las ra&iacute;ces del &aacute;rbol de nuestra Fe. &iexcl;Oh Se&ntilde;ora Nuestra!, que con lustra planta virginal quebrantasteis la cabeza de la serpiente, librad a Venezuela de los empozo&ntilde;ados dados de la impiedad y de la herej&iacute;a. Y ya que iniciasteis y hab&eacute;is mantenido a sus pueblos en la Fe de vuestro querid&iacute;simo Hijo, servidles tambi&eacute;n de escudo, sost&eacute;n y fortaleza.</p>
                                            <p style={{"text-align":"justify"}}>&nbsp; &nbsp;Vuestros somos, vuestros queremos ser. Mostrad que sois nuestra Madre y Nuestra Patrona. Guardadnos, Se&ntilde;ora, y salvadnos con vuestra todopoderosa protecci&oacute;n.</p>
                                            <p style={{"text-align":"justify"}}>&nbsp; &nbsp;&iexcl;Oh Mar&iacute;a Inmaculada! acoged este acto de nuestra consagraci&oacute;n a Vos, y sed siempre la gran defensora de Venezuela. Protegedla, salvadla. Unid a todos sus hijos en el amor del suelo nativo, en el esp&iacute;ritu de concordia dentro de la justicia y de la libertad, en el leg&iacute;timo goce de todos los bienes, a que en su calidad de venezolano le es a cada uno l&iacute;cito aspirar, y en la firme e inalterable profesi&oacute;n de la Fe Cat&oacute;lica. As&iacute; sea&rdquo;.</p>
                                            <p>&nbsp;<strong>Bibliograf&iacute;a</strong></p>
                                            <p>Instrucci&oacute;n Pastoral, Editorial Venezuela, Caracas, 1933, p. 256.</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><Link to="prayersFiles/Acto-Consagración-Venezuela-Santísima-Virgen-María.pdf" target="_blank" onClick={this.trackPDF}>Acto de Consagraci&oacute;n de Venezuela a la Sant&iacute;sima Virgen Mar&iacute;a (descargar PDF)</Link></div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div class="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}