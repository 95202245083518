/**
 * PrayersSanctuarySliders 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 09/1/2020
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import image1 from '../img/sanctuary/sliderSantuario.jpg';
import image2 from '../img/gallery-holy-relic/2176_850/santa-reliquia-slider-1450.jpg';
import slider2 from '../img/gallery-holy-relic/2400_960/santaReliquiaSlider2-2.jpg';
import slider1 from '../img/gallery-holy-relic/2400_960/slider2400960_holyrelic-2.jpg';
import slider1Small from '../img/gallery-holy-relic/480/SantoRostroSantisimaVirgenCoromoto300px.jpg';

export default class PrayersSanctuarySliders extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-12 d-md-none d-sm-none d-none d-lg-none d-xl-block" style={{'marginTop': '173px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={image1} />
                        </div>
                    </Carousel>
                </div>
                <div className="col-lg-12 d-md-none d-sm-none d-none d-xl-none d-lg-block" style={{'marginTop': '173px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={slider2} />
                        </div>
                        <div>
                            <img src={slider1} />
                        </div>
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-none d-sm-block" style={{'marginTop': '80px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={image2} />
                        </div>                       
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block" style={{'marginTop': '80px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={slider1Small} />
                        </div>                                   
                    </Carousel>
                </div>
            </div>);
    }
    
}