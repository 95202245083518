/**
 * SantuaryLink 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 01/01/2020
 */



export default class SantuaryLink {

    constructor(url, linkText) {
        this.url = url;
        this.linkText = linkText;
    }

    getURL() {
        return this.url;
    }

    
    getLinkedText() {
        return this.linkText;
    }

}