/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import image1 from '../img/gallery-holy-relic/santa-reliquia.jpg';
import image2 from '../img/gallery-holy-relic/virgen-coromoto-tinta.jpg';
import image3 from '../img/gallery-holy-relic/virgen-coromoto-corona.jpg';
import image4 from '../img/gallery-holy-relic/virgen-coromoto-ojo-detalles.jpg';
import image5 from '../img/gallery-holy-relic/reliquia-virgen-coromoto-medidas.jpg';
import image6 from '../img/gallery-holy-relic/virgen-coromoto-santa-reliquia-relicario.jpg';
import image7 from '../img/gallery-holy-relic/reliquia-virgen-coromoto-antes.jpg';
import image8 from '../img/gallery-holy-relic/hallazgos-reliquia-nuevo-estuche.jpg';
import imageSJP1 from '../img/saint-john-paul-II/20170825_194309.jpg';
import imageSJP2 from '../img/saint-john-paul-II/DSCF9011.JPG';
import imageSJP3 from '../img/saint-john-paul-II/DSCF9012.JPG';
import imageSJP4 from '../img/saint-john-paul-II/DSCF9013.JPG';
import imageSJP5 from '../img/saint-john-paul-II/DSCF9015.JPG';
import imageSJP6 from '../img/saint-john-paul-II/DSCF9016.JPG';
import imageSJP7 from '../img/saint-john-paul-II/DSCF9017.JPG';
import imageSJP8 from '../img/saint-john-paul-II/DSCF9019.JPG';
import Header from './header';
import {Helmet} from "react-helmet";
import Footer from './footer';


export default class GalleryPageSliders extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("images-findings-our-lady-of-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }


    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Im&aacute;genes Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Imágenes Santuario Nacional Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="virgen, coromoto, galería, imágenes, santuario" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Gallery Images of Our Lady of Coromoto</title>
                <meta name="description" content="Gallery Images of Our Lady of Coromoto" />
                <meta name="keywords" content="Coromoto, Mary, gallery" />
            </Helmet>;
        }

        return(<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
           
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12"><h2>Galería Imágenes Hallazgos Santa Reliquia Virgen de Coromoto</h2></div>
                </div>

                <div className="row">
                    <div className="col-12"><Carousel showThumbs={true} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={image1} alt=""/>
                        </div>
                        <div>
                            <img src={image2} alt=""/>
                        </div>
                        <div>
                            <img src={image3} alt=""/>
                        </div>
                        <div>
                            <img src={image4} alt=""/>
                        </div>
                        <div>
                            <img src={image5} alt=""/>
                        </div>
                        <div>
                            <img src={image6} alt=""/>
                        </div>
                        <div>
                            <img src={image7} alt=""/>
                        </div>
                        <div>
                            <img src={image8} alt=""/>
                        </div>
                    </Carousel></div>
                </div>

                <div className="row">
                    <div className="col-12"><h2>Galería Imágenes San Juan Pablo II</h2></div>
                </div>

                <div className="row">
                    <div className="col-12"><Carousel showThumbs={true} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={imageSJP1} alt=""/>
                        </div>
                        <div>
                            <img src={imageSJP2} alt=""/>
                        </div>
                        <div>
                            <img src={imageSJP3} alt=""/>
                        </div>
                        <div>
                            <img src={imageSJP4} alt=""/>
                        </div>
                        <div>
                            <img src={imageSJP5} alt=""/>
                        </div>
                        <div>
                            <img src={imageSJP6} alt=""/>
                        </div>
                        <div>
                            <img src={imageSJP7} alt=""/>
                        </div>
                        <div>
                            <img src={imageSJP8} alt=""/>
                        </div>
                    </Carousel></div>
                </div>
            </div>
            <Footer />
        </div>);
    }
    
}