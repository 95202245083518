/**
 * PrayersSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 19/04/2020
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import logo from '../img/gallery-holy-relic/992/SantaReliquiaCuadro.jpg';
import logo2 from '../img/gallery-holy-relic/992/SantaReliquiaCuadro-small.jpg';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';

export default class PrayersSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("prayers-to-our-lady-of-coromoto.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        let helmetComp;
        let url1, url2, url3, url4, url5, url6, url7, url8, url9;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Oraciones a la Virgen de Coromoto</title>
                <meta name="description" content="Oraciones a Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="oraciones, novena, virgen, coromoto, patrona, venezuela" />
            </Helmet>;
            url1 = "oraciones-san-jose.html";
            url2 = "mayo-mes-virgen-maria.html";
            url3 = "santo-rosario.html";
            url4 = "virgen-coromoto-rosario.html";
            url5 = "novena-virgen-coromoto.html";
            url6 = "breve-pontificio-patronato-coromotano.html";
            url7 = "acto-consagracion-venezuela-santisima-virgen.html";
            url8 = "oracion-consagracion-venezuela-virgen-coromoto.html";
            url9 = "novena-confianza-nuestra-senora-coromoto.html";
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Prayers Our Lady of Coromoto</title>
                <meta name="description" content="Prayers Our Lady of Coromoto" />
                <meta name="keywords" content="prayers, our, lady, coromoto" />
            </Helmet>;
              url1 = "prayers-saint-joseph.html";
              url2 = "may-month-holy-virgin-mary.html";
              url3 = "santo-rosario.html";
              url4 = "virgen-coromoto-rosario.html";
              url5 = "novena-virgen-coromoto.html";
              url6 = "breve-pontificio-patronato-coromotano.html";
              url7 = "acto-consagracion-venezuela-santisima-virgen.html";
              url8 = "oracion-consagracion-venezuela-virgen-coromoto.html";
              url9 = "novena-confianza-nuestra-senora-coromoto.html";
        }


        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3><Trans>PrayersLb</Trans></h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={logo2} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">     
                                            <li><a href={url8}><Trans>Prayers8Lb</Trans></a></li>
                                            <li><a href={url9}><Trans>Prayers9Lb</Trans></a></li>
                                            <li><a href={url5}><Trans>Prayers5Lb</Trans></a></li>
                                            <li><a href={url4}><Trans>Prayers4Lb</Trans></a></li>
                                            <li><a href={url7}><Trans>Prayers7Lb</Trans></a></li>                                               
                                            <li><a href={url1}><Trans>Prayers1Lb</Trans></a></li>                                                                                                                       
                                            <li><a href={url3}><Trans>Prayers3Lb</Trans></a></li>                                                                                                    
                                                                                  
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}