/**
 * VenerableTomasMoralesPerez 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";


export default class VenerableTomasMoralesPerez extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };    
        this.refreshLanguage = this.refreshLanguage.bind(this); 
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        let logo = "img/blessed/tomas-morales-perez.jpg";
        let logoSmall = "img/blessed/tomas-morales-perez-small.jpg";

        return (<div>
            <Helmet>
                <title>Venerable Tom&aacute;s Morales P&eacute;rez (1908-1994)</title>
                <meta name="description" content="Venerable Tom&aacute;s Morales P&eacute;rez (1908-1994)" />
                <meta name="keywords" content="venerable, tomas, morales, perez, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />

            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Venerable Tom&aacute;s Morales P&eacute;rez</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center">
                                        <img src={logoSmall} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>                                         

                                            <p style={{"text-align":"justify"}}>Tomás Morales Pérez (n. Macuto, Venezuela, 1908 - m. Alcalá de Henares, 1994) fue un sacerdote católico venezolano. Se destacó por su obra apostólica, dejando como principales legados el Instituto Secular Cruzados de Santa María y el Instituto Secular Cruzadas de Santa María, el movimiento apostólico de jóvenes Milicia de Santa María y la asociación pública de fieles Hogares de Santa María.</p>

                                            <p style={{"text-align":"justify"}}>Nació en Macuto (Venezuela) el 30 de octubre de 1908. Recibió una esmerada educación en el seno de su familia. En Madrid, estudió en el Colegio de los Jesuitas y posteriormente Derecho en la Universidad Central en la que obtuvo el Premio extraordinario. Realizó el doctorado en Bolonia.</p>

                                            <p style={{"text-align":"justify"}}>A los 23 años abandonó su prometedor futuro e ingresó en la Compañía de Jesús en Bélgica. Fue ordenado sacerdote el 13 de mayo de 1942 en Granada. Consagró su sacerdocio con visión profética a la animación de los laicos, convencido del precioso don que adquieren en su bautismo y de sus enormes posibilidades como corresponsables de la misión de la Iglesia. Miles de horas dedicadas a la dirección espiritual y más de 500 tandas de Ejercicios Espirituales dan testimonio de la radicalidad de su entrega a Dios y a los hombres.</p>

                                            <p style={{"text-align":"justify"}}>De su espiritualidad sacerdotal, que tuvo como centro una profunda vida eucarística, la intimidad e identificación con Cristo, el amor apasionado por la Virgen –lema de su consagración sacerdotal- una caridad heroica para con el prójimo, derivó una intensa actividad apostólica.</p>

                                            <p style={{"text-align":"justify"}}>En 1946 regresó a Madrid, donde comenzó su apostolado de predicación de Ejercicios Espirituales entre los trabajadores y empresarios. Con ellos funda el Hogar del Empleado, un movimiento apostólico de gran vitalidad que incrementó múltiples obras sociales y asistenciales.</p>

                                            <p style={{"text-align":"justify"}}>Su vocación, distinguida por una radical capacidad de donación, fue, de hecho, la de trabajar para promover un pleno y responsable inserción de los laicos en la misión evangelizadora de la Iglesia. En este ámbito, el fruto más importante de su trabajo ha sido la fundación de dos institutos seculares, Cruzados de santa María y Cruzadas de Santa María, de la asociación pública de familias Hogares de Santa María, de la Milicia de Santa María, hoy constituida, en su rama femenina, en asociación internacional privada de fieles laicos (8 diciembre 2008).</p>

                                            <p style={{"text-align":"justify"}}>La apertura de su Causa de Canonización tuvo lugar el 24 de junio de 2000 en la archidiócesis de Madrid. El 18 de marzo 2007 tuvo lugar la clausura del proceso diocesano de canonización en Madrid. El 28 de marzo 2007 se entregó el proceso y el 6 de junio 2007 se realizó la apertura en la Congregación de las Causas de los Santos en Roma. El 26 de junio 2013 se entregó la Positio en la Congregación para las Causas de los Santos en Roma.</p>

                                            <p style={{"text-align":"justify"}}>El 8 de noviembre de 2017, el Papa Francisco autorizó a la Congregación para las Causas de los Santos a proclamar el decreto con el que se reconoce las virtudes heroicas del Siervo de Dios Tomas Morales Pérez, S.J.</p>

                                            <p><b>Bibliografía</b></p>
                                            <p>https://es.aleteia.org/2018/01/04/conoce-la-extraordinaria-vida-de-tomas-morales-el-evangelizador-de-los-laicos/</p>                                            
                                            <p>https://www.cruzadasdesantamaria.org/fundador</p>                                            
                                            <p>https://es.m.wikipedia.org/wiki/Tom%C3%A1s_Morales_(sacerdote)</p> 
                                        </article>
                                    </div>
                                </div>

                                <div className="row">
                                    <div class="col-12" align="center"><a href="venerables-venezuela.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

            </div>
            <Footer />
        </div>);
    }

}