/**
 * TourVirtuaCathedralRedirect 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 09/12/2023
 */

import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom'


export default class TourVirtuaCathedralRedirect extends Component {


    componentDidMount() {
        window.open('http:/tour-catedral.html');
    }

    render() {
        return <Redirect to='/'  />;
    }

}