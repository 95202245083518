/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import DonationsSanctuarySliders from './donationsSanctuarySliders';
import Footer from './footer';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';

export default class MayMonthHolyVirginMary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("may-month-holy-virgin-mary.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;      
        if (sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);    
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        let helmetComp;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
                <title>Mayo Mes de la Sant&iacute;sima Virgen Mar&iacute;a</title>
                <meta name="description" content="Mayo Mes de la Sant&iacute;sima Virgen Mar&iacute;a" />
                <meta name="keywords" content="mayo, mes, virgen, maria, mariano" />
            </Helmet>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>May Month of the Holy Virgin Mary</title>
                <meta name="description" content="May Month of the Holy Virgin Mary" />
                <meta name="keywords" content="may, month, holy, virgin, mary" />
            </Helmet>;
        }

        let img_medium = "img/may-month-holy-virgin-mary/visitacion-santisima-virgen-maria.jpg";
        let img_small = "img/may-month-holy-virgin-mary/visitacion-santisima-virgen-maria-small.jpg";

        return (<div>
            {helmetComp}
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <DonationsSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><strong><Trans>MayMonthVirginMaryLb</Trans></strong></div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={img_medium} className="img-thumbnail" />
                                    </div>
                                    <div className="col-md-12 d-md-none d-xl-none d-lg-none d-block d-sm-block" align="center">
                                        <img src={img_small} className="img-thumbnail" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{ "text-align": "justify" }}><Trans>MayMonthVirginMaryP1</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>MayMonthVirginMaryP2</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>MayMonthVirginMaryP3</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>MayMonthVirginMaryP4</Trans></p>

                                            <p style={{ "text-align": "justify" }}><Trans>MayMonthVirginMaryP5</Trans></p>

                                            <p style={{ "text-align": "center" }}><b><Trans>MayMonthVirginMaryP6</Trans></b></p>

                                            <p style={{ "text-align": "center" }}><Trans>MayMonthVirginMaryP7</Trans></p>

                                            <p style={{ "text-align": "center" }}><Trans>MayMonthVirginMaryP8</Trans></p>

                                            <p style={{ "text-align": "center" }}><Trans>MayMonthVirginMaryP9</Trans></p>

                                            <p style={{ "text-align": "center" }}><Trans>MayMonthVirginMaryP10</Trans></p>
                                          
                                            <p><b>Bibliografía</b></p>

                                            <p>http://www.vatican.va/content/francesco/es/letters/2020/documents/papa-francesco_20200425_lettera-mesedimaggio.html</p>
                                            <p>https://www.ewtn.com/spanish/prayers/magnificat.htm</p>
                                            <p>http://w2.vatican.va/content/john-paul-ii/es/speeches/1997/may/documents/hf_jp-ii_spe_19970528_mese-mariano.html</p>
                                            <p>http://w2.vatican.va/content/paul-vi/es/encyclicals/documents/hf_p-vi_enc_29041965_mense-maio.pdf</p>
                                        </article>
                                    </div>
                                </div>
                               

                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>

                              
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}