/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';

import Footer from './footer';
import logo from '../img/beata-carmen-rendiles.jpg';
import logoSmall from '../img/beata-carmen-rendiles_small.jpg';
import { Helmet } from "react-helmet";


export default class BlessedCarmenRendiles extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Beata Carmen Rendiles (1903-1977)</title>
                <meta name="description" content="Beata Carmen Rendiles (1903-1977)" />
                <meta name="keywords" content="beata, carmen, rendiles, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />

            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Beata Carmen Rendiles</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center">
                                        <img src={logoSmall} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>

                                            <p style={{"text-align":"justify"}}>&nbsp;</p>

                                            <p style={{"text-align":"justify"}}>
                                                La religiosa fue elevada a los altares el 16/06/2018 luego de aprobarse un milagro ocurrido en la doctora Trinette Durán de Branger, el 18 de julio de 2003.</p>

                                            <p style={{"text-align":"justify"}}>
                                                El estadio de la Universidad Central de Venezuela se encuentra lleno con cientos de peregrinos que asistieron a la beatificación de la Madre Carmen Rendiles por parte del enviado del papa Francisco, el cardenal Ángelo Amato, quien recibió la solicitud por parte del cardenal Jorge Urosa Savino.
                                            
                                            La madre Carmen Rendiles se convertió en la tercera venezolana beatificada y la primera caraqueña en serlo.</p>
                                            <p style={{"text-align":"justify"}}>
                                                "Es una inmensa alegría para toda la iglesia católica de Venezuela, y muy especialmente, para la Arquidiócesis de Caracas, anunciar que el Papa Francisco ha firmado el decreto por el cual se aprueba la próxima beatificación de la Madre Carmen Rendiles Martínez, fundadora de la congregación religiosa Siervas de Jesús. En efecto, el pasado 21 de noviembre fue aprobado un milagro presentado ante la Congregación para las Causas de los Santos, por lo cual será beatificada en el curso del próximo año".</p>

                                            <p style={{"text-align":"justify"}}>
                                                Así se expresó el Cardenal Jorge Urosa Savino, tras conocerse este 19 de diciembre que la religiosa caraqueña se ha convertido en la tercera beata venezolana, luego que la Santa Sede estudiara y aprobara, una curación milagrosa "instantánea, perfecta, estable y duradera", ocurrida en la doctora Trinette Durán de Branger, el 18 de julio de 2003.                                                                                                
                                                El arzobispo de Caracas extendió su alegría y felicitaciones a la Congregación Siervas de Jesús, fundada por Madre Carmen, por el incansable y laborioso trabajo realizado a través de la comisión que lleva el proceso de beatificación y canonización.                                                                                        
                                                "Ha sido un arduo trabajo realizado por la doctora Silvia Correale, postuladora de la causa, la Hermana Rosa María Ríos, vice postuladora, y las superioras generales de la Congregación, entre ellas la actual, Madre María Eugenia Noreña", apuntó el Purpurado.                                                                                        
                                                "Más adelante brindaremos detalles del proceso, así como la fecha de la ceremonia de beatificación, la cual se dará a conocer muy pronto a todos los venezolanos. Es un momento de mucha alegría para la Iglesia y para los venezolanos", destacó Urosa.</p>

                                            <p style={{"text-align":"justify"}}>
                                                <b>¿Quién fue la Madre Carmen Rendiles?</b>
                                            </p>

                                            <p style={{"text-align":"justify"}}>
                                                Carmen Rendiles Martínez fue una monja venezolana que nació en Caracas el 11 de agosto 1903, y falleció el 9 de mayo de 1977. Llegó al mundo sin uno de sus brazos, el izquierdo; sin embargo, ello no le impidió actuar con normalidad desde pequeña y luego sentirse atraída por la vida religiosa. El 25 de febrero de 1927, entró a una comunidad de origen francés llamada Congregación Siervas de Jesús en el Santísimo Sacramento.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Tenía 33 años de edad cuando asumió como maestra de novicias en Caracas. En 1947, fue nombrada Superiora de la misma casa; y en 1951, es designada Superiora Provincial. En 1950 fundó una casa en San Cristóbal para que funcionara como colegio, y en 1951 sus hermanas comienzan a trabajar en el Seminario Diocesano de San Cristóbal. En 1952 inician labores en Cúcuta; y en 1954, se ocuparon del Palacio Arzobispal y de la Catedral de Caracas, a petición del Arzobispo Rafael Arias Blanco.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                En 1955, se pone en funcionamiento el Colegio Belén de Caracas en un terreno donado por un cuñado a Madre Carmen cuatro años antes; y en ese mismo año, en La Punta, Estado Mérida, comienzan las actividades del Colegio Nuestra Señora del Rosario. En 1959, la religiosa donará a la Congregación su casa paterna ubicada en El Paraíso, Caracas, para atender la educación de niñas de escasos recursos.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                La nueva congregación venezolana. En tiempos en que estaba la Madre Carmen Rendiles al frente de la Congregación en América, se suscitaron en Francia importantes cambios que no fueron compartidos por las hermanas de Venezuela y Colombia.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Luego de consultar a las religiosas y autoridades eclesiásticas del momento, procedió a guiar el proceso de separación de la comunidad francesa hasta su culminación. Con ello nació la nueva comunidad religiosa de origen venezolano apoyada por el Cardenal José Humberto Quintero, Arzobispo de Caracas: la Congregación de Siervas de Jesús, en el año 1966. Carmen Rendiles fue nombrada Superiora General, cargo que desempeñó con dedicación hasta su fallecimiento, el 9 de mayo de 1977.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Cronología del Proceso de Beatificación
</p>
                                            <p style={{"text-align":"justify"}}>
                                                La causa de santidad de la Madre Carmen fue abierta en marzo de 1995, durante el papado de Juan Pablo II. El 5 de julio de 2012, el papa Francisco reconoció las "virtudes cristianas en grado heroico", declarándola "Venerable" de la Iglesia, hecho que el cardenal Urosa Savino consideró como "un paso muy importante en el camino hacia la beatificación".
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Luego, del 1 de febrero al 18 de septiembre de 2014 se realizó en Caracas el estudio del presunto milagro atribuido a la intercesión de la Madre Carmen Rendiles. Ahora el milagro ha sido aprobado por la Santa Sede.
                                                ¡El milagro! La sanación del brazo
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Trinette Durán de Branger experimentó en carne propia el milagro que lleva a los altares a la Madre Carmen Rendiles. Es médica cirujana y durante muchos años ejerció su profesión en el hospital Miguel Pérez Carreño de Caracas.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                El 20 de mayo de 2003 mientras realizaba una operación a un paciente de tumor de colon como cirujana principal, recibió una fuerte descarga eléctrica en el brazo derecho que quemó su guante y los dedos índice y pulgar a causa de un cable sin protección. Afortunadamente, Trinette y el paciente quedaron con vida. Sin embargo, la doctora Durán de Branger quedó sufriendo en su brazo derecho, a tal punto que se consultó con más de veinte médicos que no lograban calmar los insoportables dolores.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Para el 18 de julio de 2003, tenía programado operarse el brazo, incluso, con el riesgo de perderlo. Ese día pasó temprano por el Colegio Belén de las Siervas de Jesús, en Caracas. Allí habló con Sor María San Luis, hermana de sangre de la Madre Carmen Rendiles. La religiosa le dijo que "Madre Carmen la curaría de su dolencia".
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Ambas mujeres ingresaron a un oratorio donde solía descansar la nueva beata venezolana. Allí estaba un cuadro de Madre Carmen que la hermana San Luis quería que Trinette pintara. "Al ver el cuadro e implorar su curación a la madre Carmen, salió del borde del cuadro un hermoso rayo de luz que alumbró toda la habitación y llegó hasta su hombro", cuenta en su testimonio la doctora Trinette Durán de Branger.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                La mujer sintió un intenso calor entrar por su cabeza y penetrar en su brazo, y fue tan impactante el momento que perdió por un momento el conocimiento. En ese mismo instante, su brazo, inexplicablemente, quedó restablecido totalmente. No usó más férula, ni fue realizada la operación porque había sido sanada.
</p>
                                            <p style={{"text-align":"justify"}}>

                                                El domingo siguiente en una Misa del Colegio Belén se reunió toda su familia para dar gracias a Dios por tan gran favor por intercesión de Madre Carmen. En la homilía el sacerdote que oficiaba se refirió a la falta de un brazo de la Carmen Cabriles y fue cuando la familia de Trinette se enteró que a la religiosa siempre le había faltado un brazo. La doctora Trinette da testimonio de su curación súbita, también los médicos que la trataron, su familia y demás conocidos. Hoy está completamente curada.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                <b>Venezolanos en proceso de beatificación</b>
                                            </p>

                                            <p style={{"text-align":"justify"}}>
                                                El cardenal Urosa informó que actualmente hay en estudio, en diversas etapas, 16 procesos de beatificación de insignes venezolanos. Entre ellos, el más famoso y popular, el Venerable doctor José Gregorio Hernández, laico. También están otros laicos, como los esposos Arístides y Adelita Calvani, Lucio León, Amanda Ruiz, y Medarda Piñero.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Igualmente se promueve la causa de los sacerdotes: monseñor Luis Rafael Tinoco, de Los Teques, y el Padre Zapico, de Barlovento; y varias religiosas: Madre Emilia de San José, la Madre Georgina, la Madre Isabel Lagrange. En este grupo hay cinco Obispos: monseñor Eduardo Boza Masvidal, monseñor Tomás San Miguel, monseñor Arturo Celestino Álvarez, monseñor Miguel Ángel Salas y monseñor Salvador Montes de Oca.
</p>
                                            <p style={{"text-align":"justify"}}>
                                                Los restos de la nueva beata venezolana, Madre Carmen Rendiles, se encuentran en una tumba ubicada en el lateral izquierdo a la entrada de la Capilla "Jesús Hostia" del Colegio Belén, en Los Palos Grandes, Caracas, lugar al que acuden los devotos desde las 7 de la mañana hasta las 12 del mediodía. También ha visitado por Trinette Durán de Branger.
</p>

                                            <p style={{"text-align":"justify"}}>Prensa CEV                                                
                                                Con información de prensa Arquidiócesis de Caracas                                                
                                                19 de diciembre de 2017</p>

                                            <p>Fuente: http://www.cev.org.ve/index.php/noticias/270-vaticano-aprueba-milagro-y-beatificacion-de-la-madre-carmen-rendiles
                                                http://www.el-nacional.com/noticias/iglesia/madre-carmen-rendiles-fue-beatificada-caracas_240248</p>


                                        </article>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">&nbsp;</div>
                    </div>




                </div>
                <Footer />
            </div>);
        }
    
}