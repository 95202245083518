/**
 * Slider Sanctuary
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 21/04/2024
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import image1 from '../img/sanctuary/sliderTimetable.jpg';
import lg_slider1 from '../img/sanctuary/sliderTimetable-lg.jpg';
import hallazgos1 from '../img/gallery-holy-relic/2176_850/holy-relic-coromoto-370.jpg';
import small_image from '../img/sanctuary/santuarioVirgenCoromoto-small.jpg';

export default class TimetableSanctuarySliders extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        return (
            <div className="row">
                <div className="col-xl-12 d-md-none d-sm-none d-none d-lg-none d-xl-block" style={{'marginTop': '173px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={image1} alt="santuario nacional virgen coromoto"/>
                        </div>
                    </Carousel>
                </div>
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-none d-lg-block" style={{'marginTop': '173px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>                           
                        <div>
                            <img src={lg_slider1} alt="santuario nacional virgen coromoto"/>
                        </div>                                                                              
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-none" style={{'marginTop': '80px'}}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={hallazgos1} alt="Virgen de Coromoto"/>
                        </div>                        
                    </Carousel>
                </div>    
                <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block" style={{'marginTop': '80px'}} align="center">
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false} interval={5000}> 
                        <div>
                            <img src={small_image} alt="santuario nacional virgen coromotoo"/>
                        </div>                                                                       
                    </Carousel>
                </div>            
            </div>);
    }
    
}