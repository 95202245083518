/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 30/03/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import {Helmet} from "react-helmet";
import SanctuaryHistorySlider from './sanctuaryHistorySliders';
import logo from '../img/logo_basilica2.png';

export default class TourVirtualMusseumCoromoto extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Museo Bas&iacute;lica Menor de Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Museo Bas&iacute;lica Menor de Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="museo, santuario, nacional, patrona, venezuela, virgen, coromoto" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <SanctuaryHistorySlider />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Museo Bas&iacute;lica Menor de Nuestra Se&ntilde;ora de Coromoto</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={logo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{ "text-align": "justify" }}>En el Museo de la Bas&iacute;lica Menor de Nuestra Se&ntilde;ora de Coromoto se puede apreciar la exposici&oacute;n de todos los objetos que las personas traen en acci&oacute;n de gracia a la Sant&iacute;sima Virgen de Coromoto por todos los milagros que les ha concedido y las promesas. Entre estos objetos podemos encontrar trofeos, medallas, placas de acci&oacute;n de gracias e im&aacute;genes religiosas.</p>
                                            <p style={{ "text-align": "justify" }}>Tambi&eacute;n vamos a encontrar un espacio de recuerdo llamada la Sala Papal donde permanecen intactos todos los accesorios que us&oacute; el Papa San Juan Pablo II en su &uacute;ltima visita a Venezuela. Desde cepillo de dientes, protector solar, botellas de agua. As&iacute;&shy; como tambi&eacute;n la vestimenta del Sumo Pont&iacute;&shy;fice, la Mitra Papal, adem&aacute;s del cop&oacute;n, c&aacute;liz y solideo que utiliz&oacute; en la Santa Misa cuando se inaugur&oacute; la Bas&iacute;lica de Nuestra Se&ntilde;ora de Coromoto.</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-none d-xl-block d-lg-block" align="center">
                                        <div className="card">
                                            <div className="card-header"><h3>Tour Virtual 360&deg; Museo Bas&iacute;lica Menor Nuestra Se&ntilde;ora de Coromoto</h3>
                                            </div>
                                            <div className="card-body">
                                                <iframe title="pannellum panorama viewer" width="850" height="600" webkitAllowFullScreen mozallowfullscreen allowFullScreen style={{ "border-style": "none;" }} src="pannellum.htm?tour=json/MuseoBasilicaVirgenCoromoto.json"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
               

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12"><audio src="audio/HimnoVirgenCoromoto.mp3" controls autoplay loop>Your browser does not support the audio element </audio></div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                


            </div>
            <Footer />
        </div>);
    }

}