/**
 * LocationSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import LocationSanctuarySliders from './locationSanctuarySliders';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import {Helmet} from "react-helmet";
import GoogleMapReact from 'google-map-react';

export default class LocationSanctuary extends Component {

    static defaultProps = {
        center: {
          lat: 8.915249,
          lng: -69.773327
        },
        zoom: 11
      };
     

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Ubicaci&#243;n Santuario Nacional Virgen de Coromoto</title>
                <meta name="description" content="Ubicaci&#243;n Santuario Nacional Virgen de Coromoto" />
                <meta name="keywords" content="ubicación, como llegar, basílica menor, coromoto" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <LocationSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Ubicaci&#243;n de la Bas&iacute;lica Menor Santuario Nacional Nuestra Se&ntilde;ora de Coromoto</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={logo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div class="col-12">
                                        <article>
                                            <p style={{"text-align": "justify"}}>El Santuario Nacional Bas&iacute;lica Menor de Nuestra Se&ntilde;ora de Coromoto est&aacute; ubicado a 25 kilometros de la Ciudad de Guanare, para llegar debe tomar la autopista Jos&eacute; Antonio Paez&nbsp;y salir en el distribuidor que los llevara al pueblo Quebrada de la Virgen y de all&iacute; a escasos 15 minutos se encuentra el Santuario nacional Virgen de Coromoto.</p>
                                            <p><strong>Coordenadas:</strong></p>
                                            <p style={{"text-align": "justify"}}>Latitud: 8&deg;55&#39;24.7&quot;N</p>
                                            <p style={{"text-align": "justify"}}>Longitud: 69&deg;46&#39;23.3&quot;W</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ height: '100vh', width: '80%' }} align="center">
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyAhXQLQT1mKLQMfsFwOMHyWc35O2CyUS7w' }}
                                            defaultCenter={this.props.center}
                                            defaultZoom={this.props.zoom}
                                        >
                                        </GoogleMapReact>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}