/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import { Helmet } from "react-helmet";


export default class VenerableEmiliaSanJose extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };    
        this.refreshLanguage = this.refreshLanguage.bind(this); 
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        let logo = "img/blessed/Madre_Emilia_de_San_Jose.jpg";
        let logoSmall = "img/blessed/Madre_Emilia_de_San_Jose-small.jpg";

        return (<div>
            <Helmet>
                <title>Venerable Emilia de San Jos&eacute; (1858-1893)</title>
                <meta name="description" content="Venerable Emilia de San Jos&eacute; (1858-1893)" />
                <meta name="keywords" content="venerable, emilia, jose, Venezuela" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />

            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{'marginTop': '173px'}}>&nbsp;</div>
                    <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-block" style={{'marginTop': '80px'}}>&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Venerable Emilia de San Jos&eacute;</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-md-12 d-none d-sm-none d-md-block d-xl-block d-lg-block" align="center">
                                        <img src={logo} />
                                    </div>
                                    <div className="col-md-12 d-block d-sm-block d-md-none d-xl-none d-lg-none" align="center">
                                        <img src={logoSmall} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>                                         

                                            <p style={{"text-align":"justify"}}>Nacida en Caracas de los acomodados y píos padres Ramón Chapellín Ochoteco y Trinidad Istúriz Pérez el 7 de diciembre de 1858, María Emilia fue educada cristianamente junto a sus numerosos hermanos y dos hermanas (las tres serán religiosas).</p>

                                            <p style={{"text-align":"justify"}}>Fue bautizada el 12 de enero de 1859 en la Catedral, siendo sus padrinos Don Miguel Chapellín y Doña Manuela Istúriz. El matrimonio Chapellín gozaba de una situación económica desahogada y practicaba los valores cristianos, sobre todo la solidaridad con los necesitados. Emilia, desde pequeña, se distinguió entre sus hermanos por su carácter apacible y cariñoso, abierto y generoso, así como en las obras de caridad más comprometedoras, entre ellas la asistencia a enfermos repugnantes y asistiendo incluso a moribundos.</p>

                                            <p style={{"text-align":"justify"}}>Amaba tiernamente a sus padres y hermanos de quienes recibía continuas muestras de afecto. Su infancia transcurrió tranquila entre el asombro y la alegría que caracteriza a los niños; se preparó con ilusión a su Primera Comunión y recibió a Jesús Sacramentado con la mayor alegría. Jesús la encontró hermosa y fijó en ella sus ojos, llenos de cariño, para no apartarlos más. Desde entonces, Emilia crecerá en el amor de Quien la amó primero y no perderá ocasión de recibirlo de nuevo. Se mostraba dichosa cuando podía ayudar a los pobres, hasta el punto de que daba la impresión que el ser pobre, necesitado y afligido, el ser desecho de los hombres por sus enfermedades y harapos, eran títulos para tener derecho a sus más delicadas atenciones (cf. Hna. Herminia de San José, Caridad en acción, pág. 33).</p>

                                            <p style={{"text-align":"justify"}}>Después de la muerte de la mamá (1885), en presencia del cadáver todavía caliente, decidió abrazar la vida religiosa a la cual se sentía inclinada: "Lejos, Señor, de retractarme de mi resolución, la ratifico en presencia de su cadáver y desde este instante quiero ser para siempre toda tuya en la vida religiosa" (Informatio, pág. 15).</p>

                                            <p style={{"text-align":"justify"}}>Habiendo ingresado en 1.887 como aspirante en las Terciarias Franciscanas, después de apenas siete meses tiene que salir por graves razones de salud. El año siguiente, permaneciendo en el seno de su familia, se inscribe en la Pía Asociación de San José, intensificando la ayuda a los enfermos más necesitados en la ciudad de Maiquetía a orillas del Mar Caribe.</p>

                                            <p style={{"text-align":"justify"}}>El 25 de septiembre de 1889 deja la familia y va a vivir en el nuevo Hospital, donde a tiempo completo se dedica al servicio de los enfermos y a las prácticas de piedad. Así, el capellán del Hospital, Don Santiago Machado, encontró en la Sierva de Dios la mejor colaboradora para la fundación estable de un grupo de voluntarias deseosas de hacer el bien, llamado: "Congregación de Hermanitas de los Pobres de Maiquetía". Ese día queda fundada la Congregación Hermanitas de los Pobres de Maiquetía, primera Congregación religiosa venezolana, quedando, así, restaurada la vida religiosa en Venezuela, después del decreto de expulsión ordenado por Guzmán Blanco en 1874.</p>

                                            <p style={{"text-align":"justify"}}>Viste el hábito religioso el 25 de octubre de 1889 y hace la primera Profesión el 19 de abril de 1890. Ya de constitución débil y agravándose cada día más por la tisis pulmonar, el 11 de diciembre de 1892 pronuncia los Votos Perpetuos in artículo mortis. El 18 de enero de 1893, la Sierva de Dios muere pocos instantes después de haber recibido la Santa Comunión.</p>

                                            <p style={{"text-align":"justify"}}>Después de estudiar detenidamente las virtudes que practicó en su vida, los nueve teólogos dieron el voto favorable para que fuese declarada la heroicidad de las mismas. Así, el Beato Papa Juan Pablo II la declaró VENERABLE el 23 de diciembre de 1993, en los cien años de su santa muerte.</p>

                                            <p>Fuente: https://web.archive.org/web/20150214213424/http://madreemiliadesanjose.org/bio.php</p>


                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12" align="center"><a href="venerables-venezuela.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

            </div>
            <Footer />
        </div>);
    }

}