/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import aparicionImagen from '../img/historyApparition/20151114_142541.jpg';
import virgenCoromotoAltar from '../img/historyApparition/20151114_145744.jpg';
import plazaCoromoto from '../img/historyApparition/20151114_163101.jpg';
import historyApparition2 from '../img/historyApparition/sliderHistoryApparition.jpg';
import hallazgos1 from '../img/gallery-holy-relic/2176_850/santa-reliquia-coromoto-1450.jpg';
import hallazgos3 from '../img/gallery-holy-relic/2176_850/santa-reliquia-1450.jpg';
import OurLadyCoromoto463 from '../img/gallery-holy-relic/480/OurLadyCoromoto463px.jpg';

export default class ApparitionHistorySlider extends Component {

    constructor(props) {
        super(props);       
    }

    render() {
        return(
            <div className="row">
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-block d-lg-block" style={{ 'marginTop': '173px' }}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                    <div>
                        <img src={virgenCoromotoAltar} />
                    </div>
                    <div>
                        <img src={historyApparition2} />
                    </div>
                    <div>
                        <img src={aparicionImagen} />
                    </div>
                    <div>
                        <img src={plazaCoromoto} />
                    </div>
                </Carousel>
            </div>
            <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-none" style={{'marginTop': '80px'}}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={hallazgos1} />
                        </div>    
                        <div>
                            <img src={hallazgos3} />
                        </div>  
                    </Carousel>
            </div>
            <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block" style={{'marginTop': '80px'}} align="center">
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={OurLadyCoromoto463} />
                        </div>    
                        
                    </Carousel>
            </div>
        </div>);
    }
    
}