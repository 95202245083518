/**
 * SanctuaryHistory 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 29/12/2019
 */

import React, { Component } from 'react';
import Header from './header';
import MisionSanctuarySliders from './misionSanctuarySliders';
import Footer from './footer';
import logo from '../img/logo_basilica2.png';
import {Helmet} from "react-helmet";


export default class MisionSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this); 
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
                this.setState({ lang: sessionStorage.getItem('language') });         
                sessionStorage.setItem('language', sessionStorage.getItem('language'));        
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));            
            }
        }        
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Misi&oacute;n Bas&iacute;lica Menor Santuario Nacional de Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Misi&oacute;n Bas&iacute;lica Menor Santuario Nacional de Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="mision, santuario, basilica, coromoto" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <MisionSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Misi&oacute;n, Visi&oacute;n y Valores</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={logo} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header"><h3>Misi&oacute;n</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div class="row">
                                                            <div className="col-12">
                                                                <article>
                                                                    <p><span style={{"font-size":"16px"}}><strong>&ldquo;Servir de medio espiritual, pastoral y social para la promoci&oacute;n y renovaci&oacute;n de la Fe Cristiana y Mariana, posicionando a la Bas&iacute;lica Menor Santuario Nacional Nuestra Se&ntilde;ora de Coromoto como coraz&oacute;n espiritual de Venezuela&rdquo;.</strong></span></p>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header"><h3>Visi&oacute;n</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div class="row">
                                                            <div className="col-12">
                                                                <article>
                                                                    <p><span style={{"font-size":"16px"}}><strong>&ldquo;Ser el coraz&oacute;n espiritual de Venezuela ofreciendo servicios pastorales y sociales fundamentados en los principios del Evangelio, la devoci&oacute;n a Nuestra Se&ntilde;ora de Coromoto y el Magisterio de la Iglesia Cat&oacute;lica&rdquo;.</strong></span></p>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-header"><h3>Valores</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div class="row">
                                                            <div className="col-12">
                                                                <article>
                                                                    <ul>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Caridad Cristiana</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Fe Mariana</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Sentido de Pertenencia</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Emprendimiento</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Cooperaci&oacute;n</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Responsabilidad</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Respeto</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Confianza</span></strong></span></li>
                                                                        <li><span style={{"font-family":"arial,helvetica,sans-serif"}}><strong><span style={{"font-size":"16px"}}>Excelencia</span></strong></span></li>
                                                                    </ul>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}