/**
 * MainRowBodyContentEXSM 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 01/01/2020
 */

import React, { Component } from 'react';
import imageNotFound from '../img/imageNotFound.png';
import SantuaryLink from './santuaryLink';
import he from 'he';


export default class MainRowBodyContentEXSM extends Component {

    constructor(props) {
        super(props);
        //images
        let img1 = this.props.img1 != null ? this.props.img1 : imageNotFound;

        //image objects
        let img1obj = this.props.img1obj;        

        this.defaultLink = new SantuaryLink('', 'Not defined');

        //links
        let link1 = this.props.link1 != null ? this.props.link1 : this.defaultLink;

        this.state = {
            imageBox1: img1,
            linkBox1: link1,
            imageObject1:img1obj,
        }
    }

    componentWillReceiveProps(props) {     

        const { link1 } = this.props;     

        if (link1 !== props.link1) {
            this.setState({ linkBox1: props.link1 });
        }

    }

    render() {

        return (<div className="row">
            <div className="col-12" align="center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block"><img src={this.state.imageBox1} className="img-thumbnail" /></div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block"><a href={this.state.imageObject1!=null ? this.state.imageObject1 : this.state.linkBox1.getURL()}>{he.decode(this.state.linkBox1.getLinkedText())}</a></div>
                    </div>
                </div>
            </div>
        </div>

        );
    }

}